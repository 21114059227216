import React from 'react';
import PropTypes from 'prop-types';
import { ErrorCard } from './../../uiComponents/ErrorCard';
import { DealUndoToolbar } from 'components/uiComponents/DealUndoToolbar.jsx';

export const FormLayout = ({ children, model, hideToolbar }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      <ErrorCard model={model} />
      {!hideToolbar && <DealUndoToolbar model={model} />}
      {children}
    </div>
  );
};

FormLayout.propTypes = {
  children: PropTypes.any,
  model: PropTypes.string,
  hideToolbar: PropTypes.bool,
};
