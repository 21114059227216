/**
 * Data transformers to/from RedPanda models.
 * Remove once we no longer use any of their models.
 */

import type {
  ApiCategoryBreadcrumb,
  ApiProduct,
  CreateProductInput,
  UpdateProductInput,
} from '@odo/types/api';
import { isCategory } from '@odo/types/guards';
import type { RpsDeal } from '@odo/types/rps';
import { dateObjectTimeOnly, dateObjectToIso } from '@odo/utils/date';

export const apiProductToRpsDeal = ({
  product,
  breadcrumbs,
  deal,
}: {
  product: ApiProduct;
  breadcrumbs?: ApiCategoryBreadcrumb[];
  deal: RpsDeal;
}): RpsDeal => {
  deal.id = product.id;
  deal.meta.id = product.id;

  // when loading up a deal using fromGraphQLDeal, these are all set to the below values
  deal.meta.isDirty = true;
  deal.meta.isValid = undefined;
  deal.meta.status = undefined;
  deal.productType = undefined;

  // buyerAndSupplier
  deal.buyerAndSupplier.buyer = product.buyer;
  deal.buyerAndSupplier.salesAssistant = product.salesAssistant;
  deal.buyerAndSupplier.priority = product.priority.toString();
  deal.buyerAndSupplier.status = product.status;
  deal.buyerAndSupplier.isLunchtimeProduct = product.isLunchtimeProduct;
  deal.buyerAndSupplier.isMainDeal = product.isMainDeal;
  deal.buyerAndSupplier.campaign = product.campaign;
  deal.buyerAndSupplier.isBestSeller = product.isBestSeller;
  deal.buyerAndSupplier.platform = product.platform;
  deal.buyerAndSupplier.isSupplierNew = product.isSupplierNew;
  deal.buyerAndSupplier.supplier = product.supplier;
  deal.buyerAndSupplier.campaignMailer = product.campaignMailer;
  deal.buyerAndSupplier.dealType = product.dealType;

  // product
  deal.product.brand = product.brand;
  deal.product.name = product.name;
  deal.product.shortName = product.shortName;
  deal.product.sku = product.sku;
  deal.product.condition = product.condition;
  deal.product.features = product.features ? product.features.split('|') : null;
  deal.product.moreDetails = product.moreDetails;
  deal.product.taxClass = product.taxClass;
  deal.product.status = product.status;
  deal.product.isPreviewOnly = product.isPreviewOnly;
  deal.product.area = product.area;
  deal.product.pillOne = product.pillOne || '';
  deal.product.pillTwo = product.pillTwo || '';
  deal.product.url = product.url;
  deal.product.buyInStockType = product.buyInStockType;
  deal.product.preview = product.preview;
  deal.product.activeFromDate = dateObjectToIso(
    new Date(product.activeFromDate),
    false
  );
  deal.product.activeToDate = dateObjectToIso(
    new Date(product.activeToDate),
    false
  );
  deal.product.activeFromTime = dateObjectTimeOnly(
    new Date(product.activeFromDate),
    false
  );
  deal.product.activeToTime = dateObjectTimeOnly(
    new Date(product.activeToDate),
    false
  );
  deal.product.isTimedDeal =
    deal.product.activeFromTime !== '00:00' ||
    deal.product.activeToTime !== '23:59';

  //sizeChart
  deal.sizeChart.id = product.sizeChart?.id || '';
  deal.sizeChart.measurement = toNumber(product.sizeChart?.measurement) || 0;
  deal.sizeChart.productId = product.sizeChart?.productId;
  deal.sizeChart.recommendation = product.sizeChart?.recommendation;
  deal.sizeChart.desktop = product.sizeChart?.desktop || {
    url: '',
    filePath: '',
  };
  deal.sizeChart.tablet = product.sizeChart?.tablet || {
    url: '',
    filePath: '',
  };
  deal.sizeChart.mobile = product.sizeChart?.mobile || {
    url: '',
    filePath: '',
  };
  deal.sizeChart.desktopDelete = false;
  deal.sizeChart.tabletDelete = false;
  deal.sizeChart.mobileDelete = false;
  deal.sizeChart.delete = false;

  // conditionsAndCategory
  deal.conditionsAndCategory.isAlcoholic = product.isAlcoholic;
  deal.conditionsAndCategory.isFragile = product.isFragile;
  deal.conditionsAndCategory.isHygienic = product.isHygienic;
  deal.conditionsAndCategory.isParallelImport = product.isParallelImport;
  deal.conditionsAndCategory.isReferable = product.isReferable;
  deal.conditionsAndCategory.isSampleReceived = product.isSampleReceived;
  deal.conditionsAndCategory.warrantyPeriod = product.warrantyPeriod;
  deal.conditionsAndCategory.additionalInfo = product.additionalInfo;
  deal.conditionsAndCategory.warranty = product.warranty;
  deal.conditionsAndCategory.isReturnableToSupplier =
    product.isReturnableToSupplier;
  deal.conditionsAndCategory.categories = (product.categories || []).map(
    category => {
      const breadcrumb = breadcrumbs
        ? breadcrumbs.find(b => b.id.toString() === category.categoryId)
        : undefined;
      return { ...category, ...(breadcrumb ? { breadcrumb } : {}) };
    }
  );
  deal.conditionsAndCategory.category = (product.categories || []).map(
    ({ categoryName }) => categoryName
  );
  deal.conditionsAndCategory.shop = '';
  deal.conditionsAndCategory.hasShop = false;
  deal.conditionsAndCategory.clearanceSale = 'none';

  // priceAndCustomOptions
  deal.priceAndCustomOptions.hasCustomOptions = 'no';
  deal.priceAndCustomOptions.cost = product.cost;
  deal.priceAndCustomOptions.retail = product.retail;
  deal.priceAndCustomOptions.price = product.price;
  deal.priceAndCustomOptions.rebateDiscount = product.rebateDiscount;
  deal.priceAndCustomOptions.surcharge = product.surcharge;
  deal.priceAndCustomOptions.surcharges = product.surcharges;
  deal.priceAndCustomOptions.adminCost = product.adminCost;
  deal.priceAndCustomOptions.isDisplayRetail = product.isDisplayRetail;
  deal.priceAndCustomOptions.isSavingsInRands = product.isSavingsInRands;
  deal.priceAndCustomOptions.soh = 0;
  deal.priceAndCustomOptions.vat = true;
  deal.priceAndCustomOptions.insurance = false;
  deal.priceAndCustomOptions.insuranceValue = 0.0;
  deal.priceAndCustomOptions.insuranceOverride = false;

  // shipping
  deal.shipping.isShippingApplied = product.isShippingApplied;
  deal.shipping.length = product.length;
  deal.shipping.width = product.width;
  deal.shipping.height = product.height;
  deal.shipping.weight = product.weight;
  deal.shipping.isShippedIndividually = product.isShippedIndividually;
  deal.shipping.shippingCost = product.shippingCost;
  deal.shipping.isDeliveredBySupplier = product.isDeliveredBySupplier;
  deal.shipping.supplierRepacks = product.supplierRepacks;
  deal.shipping.originalStock = product.originalStock;
  deal.shipping.customerDeliveryTime = product.customerDeliveryTime;
  // shipping.inventory
  deal.shipping.qty = product.inventory.qty;
  deal.shipping.minSaleQuantity = product.inventory.minSaleQuantity;
  deal.shipping.maximumSaleQuantity = product.inventory.maximumSaleQuantity;
  deal.shipping.useConfigMinSaleQty = product.inventory.useConfigMinSaleQty;
  deal.shipping.useConfigMaxSaleQty = product.inventory.useConfigMaxSaleQty;
  deal.shipping.isInStock = product.inventory.isInStock;
  deal.shipping.isApplyMaxSaleQtyToProductOptions =
    product.inventory.isApplyMaxSaleQtyToProductOptions;
  deal.shipping.isApplyMaxSaleQtyCustomerProfile =
    product.inventory.isApplyMaxSaleQtyCustomerProfile;
  deal.shipping.manage = false;
  deal.shipping.qtyOutOfStockThreshold = 0;
  deal.shipping.qtyUsesDecimals = false;
  deal.shipping.backorders = [];
  deal.shipping.notifyForQuantity = '';
  deal.shipping.qtyIncrements = false;

  // imagesAndVideos
  deal.imagesAndVideos.images = (product.images || []).map(img => ({
    ...img,
    enabled: !img.excludeImageTypes,
  }));
  deal.imagesAndVideos.videos = product.videos
    ? product.videos.match(/<iframe.+?><\/.+?>/g) || []
    : [];
  deal.imagesAndVideos.isPhotographedByStudio = product.isPhotographedByStudio;

  // clear all dirty flags
  deal.buyerAndSupplier.clearDirtyFlag();
  deal.product.clearDirtyFlag();
  deal.conditionsAndCategory.clearDirtyFlag();
  deal.priceAndCustomOptions.clearDirtyFlag();
  deal.shipping.clearDirtyFlag();
  deal.imagesAndVideos.clearDirtyFlag();
  deal.sizeChart.clearDirtyFlag();

  return deal;
};

/**
 * NOTE: RP has an applyChanges function.
 * But it doesn't do anything for the images changes,
 * as they aren't tracked in the changes array.
 */
export const rpsDealApplyImageChanges = ({
  fromDeal,
  toDeal,
}: {
  fromDeal: RpsDeal;
  toDeal: RpsDeal;
}) =>
  fromDeal.imagesAndVideos.images.forEach(fromImage => {
    if (fromImage.delete || fromImage.changed) {
      const toImage = toDeal.imagesAndVideos.images.find(
        image => image.id === fromImage.id
      );
      if (toImage) {
        toImage.filePath = fromImage.filePath;
        toImage.url = fromImage.url;
        toImage.position = fromImage.position;
        toImage.label = fromImage.label;
        toImage.excludeImageTypes = fromImage.excludeImageTypes;
        toImage.enabled = fromImage.enabled;
        toImage.imageTypes = fromImage.imageTypes;
        toImage.delete = fromImage.delete;
        toImage.changed = fromImage.changed;
      } else if (fromImage.changed) {
        toDeal.imagesAndVideos.images.push(fromImage);
      }
    }
  });

const toNumber = (value: string | number): number =>
  typeof value === 'string' ? parseFloat(value) : value;

export const rpsDealToUpdateProductInput = ({
  deal,
}: {
  deal: RpsDeal;
}): UpdateProductInput => {
  return {
    // buyerAndSupplier
    buyer: deal.buyerAndSupplier.buyer,
    salesAssistant: deal.buyerAndSupplier.salesAssistant,
    priority: parseInt(deal.buyerAndSupplier.priority),
    isLunchtimeProduct: deal.buyerAndSupplier.isLunchtimeProduct,
    isMainDeal: deal.buyerAndSupplier.isMainDeal,
    campaign: deal.buyerAndSupplier.campaign || undefined,
    isBestSeller: deal.buyerAndSupplier.isBestSeller,
    platform: deal.buyerAndSupplier.platform,
    isSupplierNew: deal.buyerAndSupplier.isSupplierNew,
    supplier: deal.buyerAndSupplier.supplier || undefined,
    campaignMailer: deal.buyerAndSupplier.campaignMailer,
    dealType: deal.buyerAndSupplier.dealType,

    // product
    status: deal.product.status,
    brand: deal.product.brand,
    name: deal.product.name,
    shortName: deal.product.shortName,
    sku: deal.product.sku,
    condition: deal.product.condition || undefined,
    features: deal.product.features
      ? typeof deal.product.features === 'string'
        ? deal.product.features
        : deal.product.features.join('|')
      : null,
    moreDetails: deal.product.moreDetails,
    taxClass: deal.product.taxClass || undefined,
    isPreviewOnly: deal.product.isPreviewOnly,
    area: deal.product.area,
    pillOne: deal.product.pillOne,
    pillTwo: deal.product.pillTwo,
    url: deal.product.url,
    buyInStockType: deal.product.buyInStockType || undefined,
    activeFromDate: deal.product.isTimedDeal
      ? dateObjectToIso(
          new Date(
            `${deal.product.activeFromDate} ${deal.product.activeFromTime}`
          ),
          true
        )
      : deal.product.activeFromDate,
    activeToDate: deal.product.isTimedDeal
      ? dateObjectToIso(
          new Date(`${deal.product.activeToDate} ${deal.product.activeToTime}`),
          true
        )
      : deal.product.activeToDate,

    // sizeChart
    sizeChart: {
      id: deal.sizeChart.id,
      measurement: toNumber(deal.sizeChart.measurement),
      recommendation: deal.sizeChart.recommendation,
      delete: !!deal.sizeChart.delete,
      desktop: {
        fileContents: deal.sizeChart.desktopDelete
          ? ''
          : deal.sizeChart.desktop?.fileContents,
        fileMimeType: deal.sizeChart.desktop?.fileMimeType,
        fileName: deal.sizeChart.desktop?.fileName,
        fileLabel: deal.sizeChart.desktop?.fileLabel,
        filePath: deal.sizeChart.desktop?.filePath,
        url: deal.sizeChart.desktop?.url,
        delete: deal.sizeChart.desktopDelete,
      },
      tablet: {
        fileContents: deal.sizeChart.tabletDelete
          ? ''
          : deal.sizeChart.tablet?.fileContents,
        fileMimeType: deal.sizeChart.tablet?.fileMimeType,
        fileName: deal.sizeChart.tablet?.fileName,
        fileLabel: deal.sizeChart.tablet?.fileLabel,
        filePath: deal.sizeChart.tablet?.filePath,
        url: deal.sizeChart.tablet?.url,
        delete: deal.sizeChart.tabletDelete,
      },
      mobile: {
        fileContents: deal.sizeChart.mobileDelete
          ? ''
          : deal.sizeChart.mobile?.fileContents,
        fileMimeType: deal.sizeChart.mobile?.fileMimeType,
        fileName: deal.sizeChart.mobile?.fileName,
        fileLabel: deal.sizeChart.mobile?.fileLabel,
        filePath: deal.sizeChart.mobile?.filePath,
        url: deal.sizeChart.mobile?.url,
        delete: deal.sizeChart.mobileDelete,
      },
    },

    // conditionsAndCategory
    isAlcoholic: deal.conditionsAndCategory.isAlcoholic,
    isFragile: deal.conditionsAndCategory.isFragile,
    isHygienic: deal.conditionsAndCategory.isHygienic,
    isParallelImport: deal.conditionsAndCategory.isParallelImport,
    isReferable: deal.conditionsAndCategory.isReferable,
    isSampleReceived: deal.conditionsAndCategory.isSampleReceived,
    warrantyPeriod: deal.conditionsAndCategory.warrantyPeriod || undefined,
    additionalInfo: deal.conditionsAndCategory.additionalInfo,
    warranty: deal.conditionsAndCategory.warranty,
    isReturnableToSupplier: deal.conditionsAndCategory.isReturnableToSupplier,
    categories: (deal.conditionsAndCategory.categories || []).map(category =>
      isCategory(category)
        ? parseInt(category.categoryId)
        : parseInt(category.id)
    ),

    // priceAndCustomOptions
    cost: toNumber(deal.priceAndCustomOptions.cost),
    retail: toNumber(deal.priceAndCustomOptions.retail),
    price: toNumber(deal.priceAndCustomOptions.price),
    rebateDiscount: toNumber(deal.priceAndCustomOptions.rebateDiscount),
    surcharge: toNumber(deal.priceAndCustomOptions.surcharge),
    surcharges: (deal.priceAndCustomOptions.surcharges || []).map(
      ({ key, value }) => ({ key, value })
    ),
    adminCost: deal.priceAndCustomOptions.adminCost,
    isDisplayRetail: deal.priceAndCustomOptions.isDisplayRetail,
    isSavingsInRands: deal.priceAndCustomOptions.isSavingsInRands,

    // shipping
    isShippingApplied: deal.shipping.isShippingApplied,
    length: toNumber(deal.shipping.length),
    width: toNumber(deal.shipping.width),
    height: toNumber(deal.shipping.height),
    weight: toNumber(deal.shipping.weight),
    isShippedIndividually: deal.shipping.isShippedIndividually,
    shippingCost: toNumber(deal.shipping.shippingCost),
    isDeliveredBySupplier: deal.shipping.isDeliveredBySupplier,
    supplierRepacks: deal.shipping.supplierRepacks,
    originalStock: toNumber(deal.shipping.originalStock),
    customerDeliveryTime: deal.shipping.customerDeliveryTime || undefined,
    // shipping.inventory
    inventory: {
      qty: toNumber(deal.shipping.qty),
      minSaleQuantity: toNumber(deal.shipping.minSaleQuantity),
      maximumSaleQuantity: toNumber(deal.shipping.maximumSaleQuantity),
      useConfigMinSaleQty: deal.shipping.useConfigMinSaleQty,
      useConfigMaxSaleQty: deal.shipping.useConfigMaxSaleQty,
      isInStock: deal.shipping.isInStock,
      isApplyMaxSaleQtyToProductOptions:
        deal.shipping.isApplyMaxSaleQtyToProductOptions,
      isApplyMaxSaleQtyCustomerProfile:
        deal.shipping.isApplyMaxSaleQtyCustomerProfile,
    },

    // imagesAndVideos
    images: (deal.imagesAndVideos.images || []).map(image => ({
      id: image.id,
      filePath: image.filePath,
      position: image.position,
      imageTypes: image.imageTypes,
      label: image.label,
      excludeImageTypes: image.excludeImageTypes,
      ...(!!image.delete ? { isDelete: true } : {}),
    })),
    videos: deal.imagesAndVideos.videos.join(' '),
    isPhotographedByStudio: deal.imagesAndVideos.isPhotographedByStudio,
  };
};

export const rpsDealToCreateProductInput = ({
  deal,
}: {
  deal: RpsDeal;
}): CreateProductInput => {
  return {
    // extras
    attributeSet: 'ONEDAYONLY',
    type: 'SIMPLE',
    visibility: 4,
    shortDescription: '', // NOTE: not 100% sure what we use this for but it's a required field

    // buyerAndSupplier
    buyer: deal.buyerAndSupplier.buyer,
    salesAssistant: deal.buyerAndSupplier.salesAssistant,
    priority: parseInt(deal.buyerAndSupplier.priority),
    isLunchtimeProduct: deal.buyerAndSupplier.isLunchtimeProduct,
    isMainDeal: deal.buyerAndSupplier.isMainDeal,
    campaign: deal.buyerAndSupplier.campaign || undefined,
    isBestSeller: deal.buyerAndSupplier.isBestSeller,
    platform: deal.buyerAndSupplier.platform,
    isSupplierNew: deal.buyerAndSupplier.isSupplierNew,
    supplier: deal.buyerAndSupplier.supplier || undefined,
    campaignMailer: deal.buyerAndSupplier.campaignMailer,
    dealType: deal.buyerAndSupplier.dealType,

    // product
    status: deal.product.status,
    brand: deal.product.brand,
    name: deal.product.name,
    shortName: deal.product.shortName,
    sku: deal.product.sku,
    condition: deal.product.condition || undefined,
    features: deal.product.features
      ? typeof deal.product.features === 'string'
        ? deal.product.features
        : deal.product.features.join('|')
      : null,
    moreDetails: deal.product.moreDetails,
    taxClass: deal.product.taxClass || undefined,
    isPreviewOnly: deal.product.isPreviewOnly,
    area: deal.product.area,
    pillOne: deal.product.pillOne,
    pillTwo: deal.product.pillTwo,
    url: deal.product.url,
    buyInStockType: deal.product.buyInStockType || undefined,
    activeFromDate: deal.product.isTimedDeal
      ? dateObjectToIso(
          new Date(
            `${deal.product.activeFromDate} ${deal.product.activeFromTime}`
          ),
          true
        )
      : deal.product.activeFromDate,
    activeToDate: deal.product.isTimedDeal
      ? dateObjectToIso(
          new Date(`${deal.product.activeToDate} ${deal.product.activeToTime}`),
          true
        )
      : deal.product.activeToDate,

    // sizeChart
    sizeChart: {
      id: deal.sizeChart.id,
      measurement: toNumber(deal.sizeChart.measurement),
      recommendation: deal.sizeChart.recommendation,
      delete: false,
      desktop: {
        fileContents: deal.sizeChart.desktopDelete
          ? ''
          : deal.sizeChart.desktop.fileContents || '',
        fileMimeType: deal.sizeChart.desktop.fileMimeType,
        fileName: deal.sizeChart.desktop.fileName,
        fileLabel: deal.sizeChart.desktop.fileLabel,
        filePath: deal.sizeChart.desktopDelete
          ? ''
          : deal.sizeChart.desktop.filePath,
        url: deal.sizeChart.desktop.url,
      },
      tablet: {
        fileContents: deal.sizeChart.tabletDelete
          ? ''
          : deal.sizeChart.tablet.fileContents,
        fileMimeType: deal.sizeChart.tablet.fileMimeType,
        fileName: deal.sizeChart.tablet.fileName,
        fileLabel: deal.sizeChart.tablet.fileLabel,
        filePath: deal.sizeChart.tabletDelete
          ? ''
          : deal.sizeChart.tablet.filePath,
        url: deal.sizeChart.tablet.url,
      },
      mobile: {
        fileContents: deal.sizeChart.mobileDelete
          ? ''
          : deal.sizeChart.mobile.fileContents,
        fileMimeType: deal.sizeChart.mobile.fileMimeType,
        fileName: deal.sizeChart.mobile.fileName,
        fileLabel: deal.sizeChart.mobile.fileLabel,
        filePath: deal.sizeChart.mobileDelete
          ? ''
          : deal.sizeChart.mobile.filePath,
        url: deal.sizeChart.mobile.url,
      },
    },

    // conditionsAndCategory
    isAlcoholic: deal.conditionsAndCategory.isAlcoholic,
    isFragile: deal.conditionsAndCategory.isFragile,
    isHygienic: deal.conditionsAndCategory.isHygienic,
    isParallelImport: deal.conditionsAndCategory.isParallelImport,
    isReferable: deal.conditionsAndCategory.isReferable,
    isSampleReceived: deal.conditionsAndCategory.isSampleReceived,
    warrantyPeriod: deal.conditionsAndCategory.warrantyPeriod || undefined,
    additionalInfo: deal.conditionsAndCategory.additionalInfo,
    warranty: deal.conditionsAndCategory.warranty,
    isReturnableToSupplier: deal.conditionsAndCategory.isReturnableToSupplier,
    categories: (deal.conditionsAndCategory.categories || []).map(category =>
      isCategory(category)
        ? parseInt(category.categoryId)
        : parseInt(category.id)
    ),

    // priceAndCustomOptions
    cost: toNumber(deal.priceAndCustomOptions.cost),
    retail: toNumber(deal.priceAndCustomOptions.retail),
    price: toNumber(deal.priceAndCustomOptions.price),
    rebateDiscount: toNumber(deal.priceAndCustomOptions.rebateDiscount),
    surcharge: toNumber(deal.priceAndCustomOptions.surcharge),
    surcharges: (deal.priceAndCustomOptions.surcharges || []).map(
      ({ key, value }) => ({ key, value })
    ),
    adminCost: deal.priceAndCustomOptions.adminCost,
    isDisplayRetail: deal.priceAndCustomOptions.isDisplayRetail,
    isSavingsInRands: deal.priceAndCustomOptions.isSavingsInRands,

    // shipping
    isShippingApplied: deal.shipping.isShippingApplied,
    length: toNumber(deal.shipping.length),
    width: toNumber(deal.shipping.width),
    height: toNumber(deal.shipping.height),
    weight: toNumber(deal.shipping.weight),
    isShippedIndividually: deal.shipping.isShippedIndividually,
    shippingCost: toNumber(deal.shipping.shippingCost),
    isDeliveredBySupplier: deal.shipping.isDeliveredBySupplier,
    supplierRepacks: deal.shipping.supplierRepacks,
    originalStock: toNumber(deal.shipping.originalStock),
    customerDeliveryTime: deal.shipping.customerDeliveryTime || undefined,
    // shipping.inventory
    inventory: {
      qty: toNumber(deal.shipping.qty),
      minSaleQuantity: toNumber(deal.shipping.minSaleQuantity),
      maximumSaleQuantity: toNumber(deal.shipping.maximumSaleQuantity),
      useConfigMinSaleQty: deal.shipping.useConfigMinSaleQty,
      useConfigMaxSaleQty: deal.shipping.useConfigMaxSaleQty,
      isInStock: deal.shipping.isInStock,
      isApplyMaxSaleQtyToProductOptions:
        deal.shipping.isApplyMaxSaleQtyToProductOptions,
      isApplyMaxSaleQtyCustomerProfile:
        deal.shipping.isApplyMaxSaleQtyCustomerProfile,
    },

    // imagesAndVideos
    images: (deal.imagesAndVideos.images || [])
      .filter(
        image =>
          !(
            image.label.toUpperCase().trim() === 'SIZING GUIDE' &&
            image.url === deal.sizeChart.desktop.url &&
            deal.sizeChart.desktopDelete
          )
      )
      .map(image => ({
        id: image.id,
        filePath: image.filePath,
        position: image.position,
        imageTypes: image.imageTypes,
        label: image.label,
        excludeImageTypes: image.excludeImageTypes,
        isDuplicate: !image.delete,
      })),
    videos: deal.imagesAndVideos.videos.join(' '),
    isPhotographedByStudio: deal.imagesAndVideos.isPhotographedByStudio,
  };
};
