import {
  useChangeProduct,
  useCurrentProduct,
} from '@odo/contexts/product-editor';
import { calcWeightAreaSurcharge } from '@odo/helpers/calculations/shipping';
import { SURCHARGE_WEIGHT_KEY } from '@odo/screens/deal/editor/constants';
import { dismiss, timer } from '@odo/utils/toast';
import { memo, useEffect, useRef, useState } from 'react';

const TOAST_ID = 'auto-weight-area-surcharge';
const AREA_MAP = {
  CAPE_TOWN: 'cpt',
  JOHANNESBURG: 'jhb',
};

const SurchargeWeight = memo(
  ({ initialValue }: { initialValue: number | undefined | null }) => {
    const prevRef = useRef<number | undefined | null>(initialValue);

    const change = useChangeProduct();

    const {
      area,
      isDeliveredBySupplier: supplierDelivers,
      weight,
      length,
      width,
      height,
    } = useCurrentProduct();

    /**
     * Listen for surcharge weight dependencies, recalculate, and update if necessary
     */
    useEffect(() => {
      const applySurcharge = () => {
        // calculate
        const nextSurcharge = calcWeightAreaSurcharge({
          area: area && area.id in AREA_MAP ? AREA_MAP[area.id] : '',
          supplierDelivers,
          weight: weight?.number || undefined,
          length: length?.number || undefined,
          width: width?.number || undefined,
          depth: height?.number || undefined,
        });

        // exit if value is same as prev
        if (nextSurcharge === prevRef.current) return;

        // update prev value
        prevRef.current = nextSurcharge;

        // apply change
        const { undo } = change({
          fieldId: 'surcharges.weight',
          label: 'Surcharge (Weight)',
          apply: to => {
            to.surcharges = [
              ...(to.surcharges || []).map(surcharge =>
                surcharge.id !== SURCHARGE_WEIGHT_KEY
                  ? surcharge
                  : {
                      ...surcharge,
                      // the actually stored value needs to be negative
                      value:
                        typeof nextSurcharge === 'undefined'
                          ? { string: '', number: 0 }
                          : {
                              string: (nextSurcharge * -1).toString(),
                              number: nextSurcharge * -1,
                            },
                    }
              ),
            ];
            return to;
          },
        });

        const toastId = timer(
          <span>
            {typeof nextSurcharge === 'undefined' ? (
              <>Weight/area surcharge cleared</>
            ) : (
              <>
                Weight/area surcharge automatically updated to{' '}
                <b>R{nextSurcharge}</b>
              </>
            )}
          </span>,
          {
            id: TOAST_ID,
            timeInMs: 15000,
            messageOptions: {
              action: {
                label: 'Undo',
                callback: () => {
                  undo();
                  dismiss(toastId);
                },
              },
            },
          }
        );
      };

      // wait a little bit so we don't spam them while they're typing
      const timeoutId = setTimeout(() => applySurcharge(), 750);

      return () => clearTimeout(timeoutId);
    }, [change, area, supplierDelivers, weight, length, width, height]);

    return null;
  }
);

const Wrapper = () => {
  const [initialized, setInitialized] = useState(false);
  const [initialValue, setInitialValue] = useState<number | undefined | null>(
    undefined
  );

  const { surcharges } = useCurrentProduct();

  /**
   * Set initial value on first render.
   */
  useEffect(() => {
    if (!surcharges || initialized) return;

    const surchargeWeight = surcharges.find(s => s.id === SURCHARGE_WEIGHT_KEY);
    if (!surchargeWeight) return;

    setInitialized(true);
    setInitialValue(
      surchargeWeight.value.string === ''
        ? undefined
        : surchargeWeight.value.number
    );
  }, [initialized, surcharges]);

  if (!initialized) return null;

  return <SurchargeWeight initialValue={initialValue} />;
};

export default Wrapper;
