import { useState } from 'react';
import { useCurrentDealSource } from 'hooks/useCurrentDealSource';
import { swapItem, shiftItem } from '@odo/utils/array.ts';

export const useImageList = images => {
  const [displayMode, setDisplayMode] = useState('tile');
  const [selected, setSelected] = useState([]);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const [renderCounter, setRenderCounter] = useState(0);
  const currentDeal = useCurrentDealSource();

  const tileSelected = displayMode === 'tile';
  const listSelected = displayMode === 'list';

  const handleDisplayModeChange = type => () => setDisplayMode(type);
  const showDeleteConfirm = () => setDeleteModalOpened(true);
  const closeDeleteConfirm = () => setDeleteModalOpened(false);

  const handleSelectAll = () => {
    if (selected.length === 0) {
      // No current selection - select all
      let newSelected = [];
      images.forEach((x, index) => newSelected.push(index));
      setSelected(newSelected);
    } else {
      // Have a current selection - deselect everything
      setSelected([]);
    }
  };

  const handleSelect = index => {
    if (selected.includes(index)) {
      setSelected([...selected.filter(x => x !== index)]);
    } else {
      selected.push(index); // NOTE: weirdly this seems necessary, ignoring for now
      setSelected([...selected]);
    }
  };

  const handleUpdate = index => updatedImage => {
    currentDeal.deal.imagesAndVideos.images[index] = {
      ...updatedImage,
      changed: true,
    };
    currentDeal.update(currentDeal.deal);
  };

  const handleDeleteSelected = async () => {
    selected.forEach(index => {
      currentDeal.deal.imagesAndVideos.images[index].delete = true;
    });

    currentDeal.update(currentDeal.deal);
    setSelected([]);
    setDeleteModalOpened(false);
  };

  const handleDisableSelected = () => {
    selected.forEach(index => {
      currentDeal.deal.imagesAndVideos.images[index].excludeImageTypes = 1;
      currentDeal.deal.imagesAndVideos.images[index].enabled = false;
      currentDeal.deal.imagesAndVideos.images[index].changed = true;
    });

    currentDeal.update(currentDeal.deal);
    setSelected([]);
  };

  const handleEnableSelected = () => {
    selected.forEach(index => {
      currentDeal.deal.imagesAndVideos.images[index].excludeImageTypes = 0;
      currentDeal.deal.imagesAndVideos.images[index].enabled = true;
      currentDeal.deal.imagesAndVideos.images[index].changed = true;
    });

    currentDeal.update(currentDeal.deal);
    setSelected([]);
  };

  const handleSwapPositions = (indexA, indexB) => {
    swapItem({
      list: currentDeal.deal.imagesAndVideos.images,
      from: indexA,
      to: indexB,
      getPosition: image => image.position,
      setPosition: (image, position) => {
        image.position = position;
        image.changed = true;
      },
    });

    currentDeal.deal.imagesAndVideos.images.sort((a, b) =>
      a.position > b.position ? 1 : a.position < b.position ? -1 : 0
    );

    currentDeal.update(currentDeal.deal);
  };

  /**
   * NOTE: shift and swap are different.
   *
   * Swap will just do what it says and swap two images positions.
   *
   * Shift will get all images from the source index up to the dest index (or vice versa),
   * put the source image in last position,
   * and shift the other images over by one position.
   */
  const handleShiftPositions = (sourceIndex, destIndex) => {
    shiftItem({
      list: currentDeal.deal.imagesAndVideos.images,
      from: sourceIndex,
      to: destIndex,
      getPosition: image => image.position,
      setPosition: (image, position) => {
        image.position = position;
        image.changed = true;
      },
    });

    currentDeal.deal.imagesAndVideos.images.sort((a, b) =>
      a.position > b.position ? 1 : a.position < b.position ? -1 : 0
    );

    currentDeal.update(currentDeal.deal);
  };

  const handleImageTypeSelect = index => imageType => {
    currentDeal.deal.imagesAndVideos.images.forEach((image, index) => {
      // remove selected type from any image that has it
      if (image.imageTypes.includes(imageType)) {
        currentDeal.deal.imagesAndVideos.images[index].imageTypes =
          image.imageTypes.filter(t => t !== imageType);
        currentDeal.deal.imagesAndVideos.images[index].changed = true;
      }
    });
    // add type to target image (cannot do push because of object property accessor issues with the deal model)
    currentDeal.deal.imagesAndVideos.images[index].imageTypes = [
      ...currentDeal.deal.imagesAndVideos.images[index].imageTypes,
      imageType,
    ];
    currentDeal.deal.imagesAndVideos.images[index].changed = true;
    setRenderCounter(c => c + 1);
    currentDeal.update();
  };

  const handleImageTypeDeselect = index => imageType => {
    // remove selected type from any image that has it
    currentDeal.deal.imagesAndVideos.images[index].imageTypes =
      currentDeal.deal.imagesAndVideos.images[index].imageTypes.filter(
        type => type !== imageType
      );
    currentDeal.deal.imagesAndVideos.images[index].changed = true;
    setRenderCounter(c => c + 1);
    currentDeal.update();
  };

  return {
    tileSelected,
    listSelected,
    selected,
    deleteModalOpened,
    hasSelection: selected.length > 0,
    handleImageTypeSelect,
    handleImageTypeDeselect,
    handleUpdate,
    handleEnableSelected,
    handleDisableSelected,
    handleSelectAll,
    handleDeleteSelected,
    handleSelect,
    handleSwapPositions,
    handleShiftPositions,
    handleDisplayModeChange,
    showDeleteConfirm,
    closeDeleteConfirm,
    renderCounter,
  };
};
