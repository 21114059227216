import { gql } from '@apollo/client';

export const GET_SUPPLIERS = gql`
  query GetSuppliers {
    getDropDownValues(name: SUPPLIERS) {
      key
      value
      metadata {
        key
        value
      }
    }
  }
`;
