import React, { useState } from 'react';
import VideoCard from './VideoCard';
import styles from './css/VideoList.module.css';
import { useCurrentDealSource } from 'hooks/useCurrentDealSource';
import {
  RPSButton,
  RPSInputModal,
  RPSListContainer,
  RPSModal,
} from '@rps/web-components/build/react-wrappers';
import { iconNames } from '@rps/web-components/build/web-components';
import { Deal } from 'models/Deal.jsx';
import { DealImagesAndVideos } from 'models/ImagesAndVideos.jsx';

const processVideoURL = url => {
  const youtubeRegex =
    /^http?s:\/\/(www.)?(?:youtube|youtu)\.(?:be|com)\/(?:embed\/?|watch\?v=)?(.+)/i;
  const vimeoRegex = /^http?s:\/\/(www.)?vimeo\.com\/(.+)/i;

  const generateYoutubeIframe = slug =>
    `<iframe width="560" height="315" src="https://www.youtube.com/embed/${slug}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
  const generateVimeoIframe = slug =>
    `<iframe src="https://player.vimeo.com/video/${slug}" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>`;

  if (url.match(youtubeRegex)) {
    const matches = url.match(youtubeRegex);
    if (matches[2]) {
      return generateYoutubeIframe(matches[2]);
    }
  } else if (url.match(vimeoRegex)) {
    const matches = url.match(vimeoRegex);
    if (matches[2]) {
      return generateVimeoIframe(matches[2]);
    }
  } else {
    return url;
  }
};

const VideoList = () => {
  const currentDeal = useCurrentDealSource();

  const [selected, setSelected] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleAddVideo = ev => {
    if (ev.detail.value) {
      if (!currentDeal.deal.imagesAndVideos.videos) {
        currentDeal.deal.imagesAndVideos.videos = [];
      }

      const video = processVideoURL(ev.detail.value);

      if (video) {
        currentDeal.deal.set(
          Deal.MODELS.IMAGES_AND_VIDEOS,
          DealImagesAndVideos.FIELDS.VIDEOS,
          [...currentDeal.deal.imagesAndVideos.videos, video]
        );
        currentDeal.update();
      }
      setShowModal(false);
    }
  };

  const toggleSelect = index => {
    if (selected.includes(index)) {
      setSelected(selected.filter(x => x !== index));
    } else {
      setSelected([...selected, index]);
    }
  };

  const handleSelectAll = () => {
    if (selected.length > 0) {
      setSelected([]);
    } else {
      let newSelected = currentDeal.deal.imagesAndVideos.videos.map(
        (v, index) => index
      );
      setSelected(newSelected);
    }
  };

  const handleDeleteSelected = () => {
    setShowDeleteModal(true);
  };

  const handleDeleteSingle = index => {
    if (!currentDeal.deal.imagesAndVideos.videos) {
      currentDeal.deal.imagesAndVideos.videos = [];
    }

    let newVideos = currentDeal.deal.imagesAndVideos.videos.filter(
      (v, i) => i !== index
    );

    currentDeal.deal.set(
      Deal.MODELS.IMAGES_AND_VIDEOS,
      DealImagesAndVideos.FIELDS.VIDEOS,
      [...newVideos]
    );
    currentDeal.update(currentDeal.deal);
  };

  const deleteVideos = () => {
    if (!currentDeal.deal.imagesAndVideos.videos) {
      currentDeal.deal.imagesAndVideos.videos = [];
    }

    let newVideos = currentDeal.deal.imagesAndVideos.videos.filter(
      (_, index) => !selected.includes(index)
    );
    currentDeal.deal.set(
      Deal.MODELS.IMAGES_AND_VIDEOS,
      DealImagesAndVideos.FIELDS.VIDEOS,
      [...newVideos]
    );
    currentDeal.update(currentDeal.deal);
    setShowDeleteModal(false);
  };

  const handleSwapVideos = (indexA, indexB) => {
    if (!currentDeal.deal.imagesAndVideos.videos) {
      currentDeal.deal.imagesAndVideos.videos = [];
    }

    let newVideos = currentDeal.deal.imagesAndVideos.videos;

    /**
     * swap values using array destructuring assignment.
     * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Destructuring_assignment
     */
    [newVideos[indexA], newVideos[indexB]] = [
      newVideos[indexB],
      newVideos[indexA],
    ];

    currentDeal.deal.set(
      Deal.MODELS.IMAGES_AND_VIDEOS,
      DealImagesAndVideos.FIELDS.VIDEOS,
      [...newVideos]
    );
    currentDeal.update();
  };

  return (
    <div className={styles.container} header="Videos">
      <RPSListContainer className="vertical">
        <RPSListContainer className="horizontal">
          <RPSButton cbClick={() => setShowModal(true)}>
            <div slot="slot-right">
              <rps-svg svg={iconNames.add} />
            </div>
            Add Video
          </RPSButton>
          {currentDeal.deal.imagesAndVideos.videos.length > 0 && (
            <>
              <RPSButton className="outline" cbClick={handleSelectAll}>
                <div slot="slot-left">
                  <rps-svg svg={iconNames.optionsVertical} />
                </div>
                {selected.length > 0 ? 'Deselect All' : 'Select All'}
              </RPSButton>

              {selected.length > 0 && (
                <RPSButton
                  className="error outline"
                  cbClick={handleDeleteSelected}
                >
                  <div slot="slot-left">
                    <rps-svg svg={iconNames.del} />
                  </div>
                  Delete Selected
                </RPSButton>
              )}
            </>
          )}
        </RPSListContainer>
        <RPSListContainer className="horizontal">
          {currentDeal.deal.imagesAndVideos.videos?.map((video, index) => {
            return (
              <VideoCard
                key={video}
                title={`Video ${index}`}
                video={video}
                selected={selected.includes(index)}
                index={index}
                videos={currentDeal.deal.imagesAndVideos.videos}
                onSelect={toggleSelect}
                onDelete={handleDeleteSingle}
                onSwap={handleSwapVideos}
                onUpdate={updatedVideo => {
                  currentDeal.deal.imagesAndVideos.videos[index] = {
                    ...updatedVideo,
                  };
                  currentDeal.update(currentDeal.deal);
                }}
              />
            );
          })}
        </RPSListContainer>
      </RPSListContainer>
      <RPSInputModal
        opened={showModal}
        popup
        cbConfirm={handleAddVideo}
        cbCancel={() => setShowModal(false)}
      />
      <RPSModal
        opened={showDeleteModal}
        popup
        cbConfirm={deleteVideos}
        cbCancel={() => setShowDeleteModal(false)}
      >
        Are you sure you want to delete {selected.length} videos?
      </RPSModal>
    </div>
  );
};

export default VideoList;
