import { createClient } from '@odo/services/urql';
import type {
  MutationLoginArgs,
  MutationLoginOutput,
  QueryProductsArgs,
  QueryProductsOutput,
} from '@odo/types/api';
import { throwGraphQLError } from '@odo/utils/graphql';
import { gql } from 'urql';

const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(user: { username: $username, password: $password }) {
      ... on User {
        firstname
        lastname
        username
        email
      }
    }
  }
`;

const GET_DEALS = gql`
  query getProducts($filter: InputProductFilter) {
    getProducts(filter: $filter) {
      id
    }
  }
`;

interface MutationLoginParams {
  username: string;
  password: string;
  signal?: AbortSignal;
}

export const mutationLogin = async ({
  username,
  password,
  signal,
}: MutationLoginParams) => {
  const { data, error } = await createClient({ signal })
    .mutation<MutationLoginOutput, MutationLoginArgs>(LOGIN, {
      username,
      password,
    })
    .toPromise();

  // only throw errors if we don't get any data back
  if (!(data && data.login?.username) && error) {
    throwGraphQLError(error);
  }

  return data && (data.login || undefined);
};

// TODO: replace with an authorization check instead of wasting time querying products
export const queryValidateAuth = async ({
  signal,
}: {
  signal?: AbortSignal;
}) => {
  const { data, error } = await createClient({ signal })
    .query<QueryProductsOutput, QueryProductsArgs>(
      GET_DEALS,
      { filter: { limit: 1 } },
      { requestPolicy: 'network-only' }
    )
    .toPromise();

  // only throw errors if we don't get any data back
  if (!(data && data.getProducts) && error) {
    if (error.networkError) {
      throw new Error('Network error');
    }

    const [firstGraphQLError] = error.graphQLErrors;
    if (firstGraphQLError) {
      throw new Error(firstGraphQLError.message);
    }
  }

  return data && data.getProducts ? true : false;
};
