import { useEffect, useState } from 'react';
import { SORT_DIR } from './types';
import type { SortCallback } from './types';
import type { ColumnType } from './types';

const Sort = ({
  id,
  sort,
  orderBy,
}: {
  id: ColumnType['id'];
  sort: SortCallback;
  orderBy?: { field: string; direction: SORT_DIR };
}) => {
  const [dir, setDir] = useState(SORT_DIR.desc);

  useEffect(() => {
    if (orderBy) {
      setDir(orderBy.field === id ? orderBy.direction : SORT_DIR.desc);
    }
  }, [id, orderBy]);

  return (
    <div
      style={{ marginLeft: '5px', padding: '0 7px', cursor: 'pointer' }}
      onClick={() => {
        const newDir = dir === SORT_DIR.asc ? SORT_DIR.desc : SORT_DIR.asc;
        sort({ id: id, dir: newDir });
        setDir(newDir);
      }}
    >
      {dir === SORT_DIR.asc && <i className="fas fa-caret-up" />}
      {dir === SORT_DIR.desc && <i className="fas fa-caret-down" />}
    </div>
  );
};

const ColumnHeading = ({
  column: { id, title, sort },
  orderBy,
}: {
  column: ColumnType;
  orderBy?: { field: string; direction: SORT_DIR };
}) => (
  <div style={{ display: 'flex' }}>
    <div style={{ fontWeight: 'bold' }}>{title}</div>
    {sort && <Sort id={id} sort={sort} orderBy={orderBy} />}
  </div>
);

export default ColumnHeading;
