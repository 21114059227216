import { useAttributeOptions } from '@odo/hooks/attributes';
import { AttributeCode } from '@odo/types/api';
import { getSurchargeTotal } from 'components/deals/DealSideEffects';
import { useCallback, useEffect, useState } from 'react';
import { Deal } from 'models/Deal';
import { DealPriceAndCustomOptions } from 'models/PriceAndCustomOptions.jsx';

/**
 * Because this is applying values to the RP DealModel (which is untyped),
 * we can't reasonably write a typescript version of this hook right now.
 *
 * That said, the underlying useAttributeOptions hook is typed,
 * so once we no longer need to apply defaults to RP DealModel,
 * we shouldn't struggle too much in creating a typescript version of this
 * if we still want this kind of implementation when the time comes.
 */
export const useApplyDefaultAttributes = () => {
  const [isReady, setIsReady] = useState(false);

  // all deals
  const buyInStockTypeOptions = useAttributeOptions(
    AttributeCode.buyInStockType
  );
  const surchargeTypeOptions = useAttributeOptions(AttributeCode.surcharges);

  // new deals
  const platformOptions = useAttributeOptions(AttributeCode.platform);
  const customerDeliveryTimeOptions = useAttributeOptions(
    AttributeCode.customerDeliveryTime
  );
  const supplierRepacksOptions = useAttributeOptions(
    AttributeCode.supplierRepacks
  );
  const campaignOptions = useAttributeOptions(AttributeCode.campaign);
  const taxClassOptions = useAttributeOptions(AttributeCode.taxClass);
  const adminCostOptions = useAttributeOptions(AttributeCode.adminCost);
  const conditionOptions = useAttributeOptions(AttributeCode.condition);
  const warrantyPeriodOptions = useAttributeOptions(
    AttributeCode.warrantyPeriod
  );

  const applyDefaultAttributes = useCallback(
    ({ dealModel, dealUpdate, isNew = false, isDuplicate: _ = false }) => {
      let shouldUpdate = false;

      // buy-in stock: "none" comes back as null from API so always needs to be selected
      if (
        !buyInStockTypeOptions
          .map(({ value }) => value)
          .includes(dealModel.product.buyInStockType)
      ) {
        const buyInStockNone = buyInStockTypeOptions.find(
          ({ key }) => key.toLowerCase() === 'none'
        );
        if (buyInStockNone) {
          dealModel.product.buyInStockType = buyInStockNone.value;
          shouldUpdate = true;
        }
      }

      // surcharges: any surcharges not on the model already need to be added as empty strings
      const notOnModel = surchargeTypeOptions.filter(
        ({ key: optionKey }) =>
          !(dealModel.priceAndCustomOptions.surcharges || []).some(
            ({ key: dealKey }) => dealKey === optionKey
          )
      );
      if (notOnModel.length > 0) {
        const allSurcharges = [
          ...(dealModel.priceAndCustomOptions.surcharges || []),
          ...notOnModel.map(({ key }) => ({ key, value: '' })),
        ];

        // recalculate the surcharge total as well just in case
        const total = getSurchargeTotal(allSurcharges);

        dealModel.set(
          Deal.MODELS.PRICE_AND_CUSTOM_OPTIONS,
          DealPriceAndCustomOptions.FIELDS.SURCHARGES,
          allSurcharges
        );
        dealModel.set(
          Deal.MODELS.PRICE_AND_CUSTOM_OPTIONS,
          DealPriceAndCustomOptions.FIELDS.SURCHARGE,
          parseFloat(total).toFixed(2)
        );
        shouldUpdate = true;
      }

      if (isNew) {
        shouldUpdate = true;

        // all platforms
        dealModel.buyerAndSupplier.platform = platformOptions.map(
          ({ value }) => value
        );

        // customer delivery time
        const customerDeliveryTimeDefault = customerDeliveryTimeOptions.find(
          option =>
            (option.metadata || []).some(
              ({ key, value }) => key === 'is_default' && value === '1'
            )
        );
        if (
          customerDeliveryTimeDefault &&
          !dealModel.shipping.customerDeliveryTime
        ) {
          dealModel.shipping.customerDeliveryTime =
            customerDeliveryTimeDefault.value;
        }

        // supplier repacks
        const supplierRepacksNone = supplierRepacksOptions.find(
          ({ key }) => key.toLowerCase() === 'none'
        );
        if (supplierRepacksNone && !dealModel.shipping.supplierRepacks) {
          dealModel.shipping.supplierRepacks = supplierRepacksNone.value;
        }

        // campaign
        const campaignNone = campaignOptions.find(
          ({ key }) => key.toLowerCase() === 'none'
        );
        if (campaignNone && !dealModel.buyerAndSupplier.campaign) {
          dealModel.buyerAndSupplier.campaign = campaignNone.value;
        }

        // taxable class
        const taxClassTaxable = taxClassOptions.find(
          ({ key }) => key.toLowerCase() === 'taxable_goods'
        );
        if (taxClassTaxable && !dealModel.product.taxClass) {
          dealModel.product.taxClass = taxClassTaxable.value;
        }

        // admin cost
        const adminCost1750 = adminCostOptions.find(({ key }) =>
          ['admincost_17_50', '17_50'].includes(key.toLowerCase())
        );
        if (adminCost1750 && !dealModel.priceAndCustomOptions.adminCost) {
          dealModel.priceAndCustomOptions.adminCost = adminCost1750.value;
        }

        // condition
        const conditionNew = conditionOptions.find(
          ({ key }) => key.toLowerCase() === 'new'
        );
        if (conditionNew && !dealModel.product.condition) {
          dealModel.product.condition = conditionNew.value;
        }

        // warranty period
        const warranty6Months = warrantyPeriodOptions.find(({ key }) =>
          ['warrantyperiod_6_months', '6_months'].includes(key.toLowerCase())
        );
        if (
          warranty6Months &&
          !dealModel.conditionsAndCategory.warrantyPeriod
        ) {
          dealModel.conditionsAndCategory.warrantyPeriod =
            warranty6Months.value;
        }
      }

      // we must only trigger this update once!
      shouldUpdate && dealUpdate();
    },
    [
      platformOptions,
      customerDeliveryTimeOptions,
      supplierRepacksOptions,
      campaignOptions,
      taxClassOptions,
      adminCostOptions,
      conditionOptions,
      buyInStockTypeOptions,
      surchargeTypeOptions,
      warrantyPeriodOptions,
    ]
  );

  useEffect(() => {
    if (
      platformOptions.length > 0 &&
      customerDeliveryTimeOptions.length > 0 &&
      supplierRepacksOptions.length > 0 &&
      campaignOptions.length > 0 &&
      taxClassOptions.length > 0 &&
      adminCostOptions.length > 0 &&
      conditionOptions.length > 0 &&
      warrantyPeriodOptions.length > 0 &&
      surchargeTypeOptions.length > 0 &&
      buyInStockTypeOptions.length > 0
    ) {
      setIsReady(true);
    }
  }, [
    platformOptions,
    customerDeliveryTimeOptions,
    supplierRepacksOptions,
    campaignOptions,
    taxClassOptions,
    adminCostOptions,
    conditionOptions,
    warrantyPeriodOptions,
    surchargeTypeOptions,
    buyInStockTypeOptions,
  ]);

  return [isReady, applyDefaultAttributes];
};
