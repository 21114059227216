/**
 * NOTE: this side effect is only necessary for now because custom options use their own context.
 * TODO: BP-664: remove when we integrate custom options directly into the product context
 */
import { useProductContext } from '@odo/contexts/product';
import { useCurrentProduct } from '@odo/contexts/product-editor';
import { useEffect } from 'react';

const SyncPriceToCustomOptions = () => {
  const currentProduct = useCurrentProduct();
  const { setActiveProduct } = useProductContext();

  useEffect(() => {
    const price = currentProduct.price?.number;
    if (typeof price !== 'number') return;

    setActiveProduct(activeProduct =>
      activeProduct
        ? {
            ...activeProduct,
            product: {
              ...(activeProduct?.product ? activeProduct.product : {}),
              price,
            },
          }
        : undefined
    );
  }, [currentProduct.price, setActiveProduct]);

  return null;
};

export default SyncPriceToCustomOptions;
