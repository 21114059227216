/**
 * NOTE: this is used to prevent runtime errors from breaking the entire app.
 * If you wrap this around a section of code, and that code ends up throwing an error,
 * then that section will be removed (blanked), but everything above it will survive.
 *
 * This can only be done with react class components (as far as I know),
 * and is one of the only cases where we should ever use them over functional components.
 */

import type { ErrorInfo, ReactNode } from 'react';
import { Component } from 'react';

interface Props {
  children: ReactNode;
  errorDisplay?: ReactNode;
  onErrorCallback?: (error: Error) => void;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.props.onErrorCallback && this.props.onErrorCallback(error);
    console.error(error, errorInfo);
  }

  public render() {
    if (this.state.hasError) {
      return this.props.errorDisplay ? this.props.errorDisplay : null;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
