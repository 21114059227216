import styles from './switch.module.css';
import type { InputHTMLAttributes } from 'react';
import { useRef } from 'react';

/**
 * Fun alternatives:
 * https://codepen.io/alvarotrigo/pen/PoOXJpM
 * https://codepen.io/alvarotrigo/pen/zYPydpB
 * https://codepen.io/alvarotrigo/pen/wvPRrZW
 *
 * Basic alternatives:
 * https://codepen.io/alvarotrigo/pen/oNoJePo
 * https://codepen.io/alvarotrigo/pen/abVPyaJ
 * https://codepen.io/alvarotrigo/pen/gOXZxNZ
 * https://codepen.io/alvarotrigo/pen/YzEdrKj
 * https://codepen.io/alvarotrigo/pen/GROPMZe
 */

type SwitchProps = InputHTMLAttributes<HTMLInputElement> & {
  activeColor?: string;
  width?: number;
};

/**
 * @see https://codepen.io/alvarotrigo/pen/RwjEZeJ
 */
const Switch = ({
  activeColor = '#7fc6a6',
  width = 2.7,
  ...restProps
}: SwitchProps) => {
  const id = useRef<string>(
    Math.random().toString(36).substring(2, 10)
  ).current;

  return (
    <span>
      <input
        id={id}
        type="checkbox"
        className={[styles.tgl, styles.tglflat].join(' ')}
        {...restProps}
      />
      <label
        htmlFor={id}
        className={styles.tglbtn}
        style={{
          // @ts-ignore
          '--active-color': activeColor,
          '--width': `${width}em`,
          '--height': `${width / 2}em`,
        }}
      />
    </span>
  );
};

export default Switch;
