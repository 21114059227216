import type { InputHTMLAttributes } from 'react';
import { useRef } from 'react';
import styles from './labelled-input.module.css';

type LabelledInputProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
};

const LabelledInput = ({ label, ...restProps }: LabelledInputProps) => {
  const id = useRef<string>(
    Math.random().toString(36).substring(2, 10)
  ).current;

  return (
    <div className={styles.labelledInput}>
      <label htmlFor={id} className={styles.label}>
        {label}
      </label>
      <input id={id} className={styles.input} {...restProps} />
    </div>
  );
};

export default LabelledInput;
