import { getFilterLabel } from './helpers';
import SearchFiltersContext from './context';
import { useContext, useMemo } from 'react';

export const useSearchFiltersContext = () => {
  const searchFilters = useContext(SearchFiltersContext);
  return searchFilters;
};

export const useActiveFilters = () => {
  const { filters } = useSearchFiltersContext();

  const activeFilters = useMemo(
    () => filters.filter(f => !!f.active),
    [filters]
  );

  return activeFilters;
};

export const useActiveFiltersLabel = () => {
  const activeFilters = useActiveFilters();

  const activeFiltersLabel = useMemo(
    () => activeFilters.map(getFilterLabel).join(' | '),
    [activeFilters]
  );

  return activeFiltersLabel;
};
