import {
  mutationCreateCustomOptions,
  mutationRemoveCustomOption,
  mutationUpdateCustomOption,
  queryCustomOptions,
} from '@odo/graphql/product/custom-options';
import {
  DependencyEnum,
  type ApiCustomOption,
  type ApiCustomOptionValue,
  type ApiProduct,
  type ProductInventory,
} from '@odo/types/api';
import type {
  Action,
  CustomOptionsInputWithTmpId,
  CustomOptionValuesInputWithTmpId,
  SaveCustomOptionsMutations,
  SaveUtilities,
  UpdateCustomOptionValuesInputWithTmpId,
} from '@odo/contexts/custom-options-editor/types';
import { actionReducerPrepMutations } from '@odo/contexts/custom-options-editor/reducer/save';
import {
  findOrMakeValueMutation,
  findOrMakeOptionMutation,
  getMaxGroupId,
  getMaxSortOrder,
} from './utils';
import prepCustomOptionTree from './prep-custom-option-tree';
import {
  success,
  invertedSuccessColors,
  error,
  dismiss,
  loading,
} from '@odo/utils/toast';
import type { CustomOptionTree } from '@odo/types/portal';
import config from '@odo/config';

const removeTmpIdsFromCustomOptionValues = <T extends { tmpValueId?: string }>(
  values: T[]
): Omit<T, 'tmpValueId'>[] =>
  values.map(({ tmpValueId: _, ...value }) => ({ ...value }));

const removeTmpIdsFromCustomOptions = <
  T extends { tmpOptionId?: string; values: X[] },
  X extends { tmpValueId?: string }
>(
  customOptions: T[]
): (Omit<T, 'tmpOptionId' | 'values'> & {
  values: Omit<X, 'tmpValueId'>[];
})[] =>
  customOptions.map(({ tmpOptionId: _, values, ...customOption }) => ({
    ...customOption,
    values: removeTmpIdsFromCustomOptionValues(values),
  }));

const getValueChildrenGroupIds = ({
  value,
  customOptions,
}: {
  value: ApiCustomOptionValue;
  customOptions: ApiCustomOption[];
}) =>
  (value.childrenGroupIds || '')
    .split(',')
    // JS is dumb, parseInt('') === NaN, but parseInt('123abc') === 123, so we can't use it
    // however, Number('') === 0, so we need to filter out empty strings
    .filter(id => id !== '' && !isNaN(Number(id)))
    .map(id => Number(id))
    // only keep childrenGroupIds for values that exist
    .filter(id =>
      customOptions.some(({ values }) =>
        (values || []).some(({ groupId }) => groupId === id)
      )
    );

const hasMutations = (mutations: SaveCustomOptionsMutations) =>
  [
    ...mutations.createCustomOptions,
    ...mutations.updateCustomOption,
    ...mutations.removeCustomOption,
  ].length > 0;

const runSaveCustomOptionsMutations = async ({
  mutations,
  signal,
}: {
  mutations: SaveCustomOptionsMutations;
  signal?: AbortSignal;
}) => {
  for (let x = 0; x < mutations.createCustomOptions.length; x++) {
    const createCustomOptions = mutations.createCustomOptions[x];
    try {
      await mutationCreateCustomOptions({
        signal,
        ...createCustomOptions.args,
        customOptions: removeTmpIdsFromCustomOptions<
          CustomOptionsInputWithTmpId,
          CustomOptionValuesInputWithTmpId
        >(createCustomOptions.args.customOptions),
      });
      createCustomOptions.meta.completed = true;
    } catch (e) {
      createCustomOptions.meta.failed = true;
      console.error(e);
    }
  }

  for (let x = 0; x < mutations.updateCustomOption.length; x++) {
    const updateCustomOption = mutations.updateCustomOption[x];
    try {
      await mutationUpdateCustomOption({
        signal,
        ...updateCustomOption.args,
        customOption: {
          ...updateCustomOption.args.customOption,
          values:
            removeTmpIdsFromCustomOptionValues<UpdateCustomOptionValuesInputWithTmpId>(
              updateCustomOption.args.customOption.values
            ),
        },
      });
      updateCustomOption.meta.completed = true;
    } catch (e) {
      updateCustomOption.meta.failed = true;
      console.error(e);
    }
  }

  for (let x = 0; x < mutations.removeCustomOption.length; x++) {
    const removeCustomOption = mutations.removeCustomOption[x];
    try {
      await mutationRemoveCustomOption({ ...removeCustomOption.args, signal });
      removeCustomOption.meta.completed = true;
    } catch (e) {
      removeCustomOption.meta.failed = true;
      console.error(e);
    }
  }
};

/**
 * NOTE: clone of `@odo/data/custom-options/utils.ts > calcValueQty`
 * but for raw custom option data from the API instead of our prepared custom option tree.
 */
const recursiveValueQty = ({
  value,
  customOptions,
  uniqueValueIds = [],
}: {
  value: ApiCustomOptionValue;
  customOptions: ApiCustomOption[];
  uniqueValueIds?: string[];
}): number => {
  if (uniqueValueIds.includes(value.valueId)) {
    return 0;
  }

  uniqueValueIds.push(value.valueId);

  const childrenGroupIds = getValueChildrenGroupIds({ value, customOptions });

  return childrenGroupIds.length > 0
    ? customOptions.reduce(
        (accQtyOptions, option) =>
          accQtyOptions +
          (option.values || [])
            .filter(
              value =>
                typeof value.groupId !== 'undefined' &&
                childrenGroupIds.includes(value.groupId)
            )
            .reduce(
              (accQtyValues, value) =>
                accQtyValues +
                recursiveValueQty({ value, customOptions, uniqueValueIds }),
              0
            ),
        0
      )
    : value.quantity || 0;
};

/**
 * Cleanup tasks currently include:
 * - removing floating childrenGroupIds
 * - calculating the cumulative quantity for each value
 * - preparing mutations to update the above
 */
const valueCleanup = ({
  value,
  customOptions,
  mutations,
  autoSumEnabled = true,
}: {
  value: ApiCustomOptionValue;
  customOptions: ApiCustomOption[];
  mutations: SaveCustomOptionsMutations;
  autoSumEnabled?: boolean;
}) => {
  const cumulativeQty = autoSumEnabled
    ? recursiveValueQty({ value, customOptions })
    : undefined;

  const childrenGroupIds = value.childrenGroupIds || '';
  const cleanedChildrenGroupIds = getValueChildrenGroupIds({
    value,
    customOptions,
  });

  // automatically link all child option values
  if (config.customOptions.automaticallyLinkAllChildOptionValues) {
    customOptions
      // find all child options (any that have even a single value in the childrenGroupIds list)
      .filter(option =>
        (option.values || []).some(
          ({ groupId }) =>
            typeof groupId !== 'undefined' &&
            cleanedChildrenGroupIds.includes(groupId)
        )
      )
      // ensure all their values are in the parent values childrenGroupIds
      .forEach(childOption =>
        (childOption.values || []).forEach(childValue => {
          if (
            typeof childValue.groupId !== 'undefined' &&
            !cleanedChildrenGroupIds.includes(childValue.groupId)
          ) {
            cleanedChildrenGroupIds.push(childValue.groupId);
          }
        })
      );
  }

  const nextChildrenGroupIds = cleanedChildrenGroupIds.join(',');

  if (
    (typeof cumulativeQty !== 'undefined' &&
      value.quantity !== cumulativeQty) ||
    childrenGroupIds !== nextChildrenGroupIds
  ) {
    const { value: mutationValue, mutationCallback } = findOrMakeValueMutation({
      customOptions,
      mutations,
      valueId: value.valueId,
    });

    if (mutationValue) {
      if (
        typeof cumulativeQty !== 'undefined' &&
        value.quantity !== cumulativeQty
      ) {
        value.quantity = cumulativeQty;
        mutationValue.quantity = cumulativeQty;
      }

      if (childrenGroupIds !== nextChildrenGroupIds) {
        value.childrenGroupIds = nextChildrenGroupIds;
        mutationValue.childrenGroupIds = nextChildrenGroupIds;
      }

      mutationCallback();
    }
  }

  return value;
};

/**
 * NOTE: used for ensuring our root option has a sort order = 0
 * and should guarantee unique sort orders for all options.
 */
const recursivelyIncrementOptionSortOrders = ({
  customOption,
  targetSortOrder,
  customOptions,
  mutations,
  excludeIds = [],
}: {
  customOption: ApiCustomOption;
  targetSortOrder: number;
  customOptions: ApiCustomOption[];
  mutations: SaveCustomOptionsMutations;
  excludeIds?: ApiCustomOption['id'][];
}) => {
  const internalExcludeIds = [...excludeIds, customOption.id];

  const nextOptions = customOptions.filter(
    ({ id, sortOrder }) =>
      sortOrder === targetSortOrder && !internalExcludeIds.includes(id)
  );

  if (nextOptions.length > 0) {
    let sortOrderIncrement = targetSortOrder;
    nextOptions.forEach(option => {
      recursivelyIncrementOptionSortOrders({
        customOption: option,
        targetSortOrder: ++sortOrderIncrement,
        customOptions,
        mutations,
        excludeIds: internalExcludeIds,
      });
    });
  }

  const { option: mutationOption, mutationCallback } = findOrMakeOptionMutation(
    {
      customOptions,
      mutations,
      optionId: customOption.id,
    }
  );
  if (mutationOption) {
    mutationOption.sortOrder = targetSortOrder;
    mutationCallback();
  }
};

/**
 * NOTE: used for ensuring that all options have the correct dependency value
 * after any potential changes to the custom option tree.
 */
const recursivelySetOptionDependency = ({
  customOption,
  customOptions,
  mutations,
  isChild,
  excludeIds = [],
}: {
  customOption: CustomOptionTree;
  customOptions: ApiCustomOption[];
  mutations: SaveCustomOptionsMutations;
  isChild: boolean;
  excludeIds?: CustomOptionTree['id'][];
}) => {
  // exit early if we've already checked this option
  if (excludeIds.includes(customOption.id)) return;
  excludeIds.push(customOption.id);

  // check if this option has the correct dependency, and if not set it in a mutation
  const dependency = isChild ? DependencyEnum.or : DependencyEnum.no;
  if (customOption.dependency !== dependency) {
    const { option: mutationOption, mutationCallback } =
      findOrMakeOptionMutation({
        customOptions,
        mutations,
        optionId: customOption.id,
      });

    if (mutationOption) {
      mutationOption.dependency = dependency;
      mutationCallback();
    }
  }

  // recursively do the same for all children
  customOption.values.forEach(value =>
    value.childOptions.forEach(childOption =>
      recursivelySetOptionDependency({
        customOption: childOption,
        customOptions,
        mutations,
        isChild: true,
        excludeIds,
      })
    )
  );
};

const save = async ({
  productId,
  actionList,
  autoSumEnabled = true,
  signal,
  onCompleteCallback,
}: {
  productId: ApiProduct['id'];
  /**
   * TODO: remove when we're certain we're not sending it from anywhere else.
   * @deprecated we've removed the API call that was dependent on this.
   */
  stockId?: ProductInventory['id'];
  /**
   * @deprecated we've removed the API call that was dependent on this.
   */
  latestQty?: ProductInventory['qty'];
  actionList: Action[];
  autoSumEnabled?: boolean;
  signal?: AbortSignal;
  onCompleteCallback?: (result: { customOptions?: ApiCustomOption[] }) => void;
}) => {
  // track whether this function has been aborted.
  let isActive = true;

  // some cleanup on abort
  signal?.addEventListener('abort', () => (isActive = false));

  const loadingToastId = loading('Saving Custom Options', {
    // this is set to top-center to work best with RP deal save
    position: 'top-center',
  });

  let savedCustomOptions = false;
  let latestCustomOptions: ApiCustomOption[] | undefined;

  try {
    const filteredActionList = actionList.filter(
      action => !autoSumEnabled || !action.excludeOnAutoSumEnabled
    );

    if (filteredActionList.length > 0) {
      const remoteCustomOptions = await queryCustomOptions({ productId });
      if (!remoteCustomOptions) {
        throw new Error('Failed to load remote custom options, pre save');
      }

      latestCustomOptions = remoteCustomOptions;

      const mutations: SaveCustomOptionsMutations = {
        createCustomOptions: [],
        updateCustomOption: [],
        removeCustomOption: [],
      };

      let maxGroupId = getMaxGroupId(remoteCustomOptions);
      let maxSortOrderForOptions = getMaxSortOrder(remoteCustomOptions);
      const utilities: SaveUtilities = {
        // value groupIds must be unique per product, this utility will ensure that
        getNewGroupId: () => ++maxGroupId, // the prefix increment will increment the value and then return it
        // option sort order must be managed from a product level as well
        getNewSortOrderForOption: () => ++maxSortOrderForOptions,
        // decided to add this to the util instead of passing as an argument coz it's rarely needed
        getProductId: () => productId,
      };

      filteredActionList.forEach(action => {
        try {
          if (action.type in actionReducerPrepMutations) {
            actionReducerPrepMutations[action.type]({
              action,
              customOptions: remoteCustomOptions,
              mutations,
              utilities,
            });
          }
        } catch (e) {
          console.error(e);
        }
      });

      await runSaveCustomOptionsMutations({ mutations, signal });
      if (hasMutations(mutations)) {
        savedCustomOptions = true;
      }
    }

    const remoteCustomOptionsPostSave = await queryCustomOptions({ productId });
    if (!remoteCustomOptionsPostSave) {
      throw new Error('Failed to load remote custom options, post save');
    }

    latestCustomOptions = remoteCustomOptionsPostSave;

    const postSaveMutations: SaveCustomOptionsMutations = {
      createCustomOptions: [],
      updateCustomOption: [],
      removeCustomOption: [],
    };

    // clean up values: quantities, children, and siblings
    latestCustomOptions = remoteCustomOptionsPostSave.map(customOption => {
      customOption.values = (customOption.values || []).map(value =>
        valueCleanup({
          value,
          customOptions: remoteCustomOptionsPostSave,
          mutations: postSaveMutations,
          autoSumEnabled,
        })
      );
      return customOption;
    });

    const customOptionTree = prepCustomOptionTree(latestCustomOptions);

    // NOTE: the first ROOT option, must have a sort order = 0
    // if not, ODO admin will not be able to parse the options on buyer sign off
    if (customOptionTree.length > 0) {
      const [firstRootOption] = customOptionTree;
      if (firstRootOption.sortOrder !== 0) {
        recursivelyIncrementOptionSortOrders({
          customOption: firstRootOption,
          targetSortOrder: 0,
          customOptions: remoteCustomOptionsPostSave,
          mutations: postSaveMutations,
        });
      }
    }

    // ensure that all options have the correct dependency, only these root options will be a "NO"
    // we use a shared excludeIds array for all options to avoid duplicating work
    const excludeIds = [];
    customOptionTree.forEach(customOption =>
      recursivelySetOptionDependency({
        customOption,
        customOptions: remoteCustomOptionsPostSave,
        mutations: postSaveMutations,
        isChild: false,
        excludeIds,
      })
    );

    await runSaveCustomOptionsMutations({
      mutations: postSaveMutations,
      signal,
    });

    if (hasMutations(postSaveMutations)) {
      savedCustomOptions = true;
    }

    // finished
    dismiss(loadingToastId);
    if (savedCustomOptions) {
      success('Custom Options Saved', {
        ...invertedSuccessColors,
        position: 'top-center',
      });
    }
  } catch (e) {
    console.error(e);

    isActive &&
      error(
        e instanceof Error && typeof e.message === 'string'
          ? e.message
          : 'Error saving custom options. Please try again.'
      );
  } finally {
    // clean up any loading related stuff
    dismiss(loadingToastId);

    if (isActive && onCompleteCallback) {
      onCompleteCallback({ customOptions: latestCustomOptions });
    }

    return void 0;
  }
};

export default save;
