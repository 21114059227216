import { Validator } from './Validator.jsx';

/**
 * @name ValidationModel
 * @description
 *	Base model for self-validating models
 *
 * @class
 * @abstract
 * @template {Object<string, any>} T
 */
export class ValidationModel {
  /** @param {T} properties */
  constructor(properties, validators, strict = true) {
    /** @private */
    this._meta = {
      dirty: false,
      strict: strict,
    };

    let error = false;

    if (properties === undefined) {
      console.error('new ValidationModel() requires valid properties argument');
      error = true;
    }

    if (typeof properties !== 'object') {
      console.error(
        'new ValidationModel() properties argument must be of type object (Key:value pairs).'
      );
      error = true;
    }

    if (validators === undefined) {
      console.error(
        'new ValidationModel() requires valid validators argument.'
      );
      error = true;
    }

    if (typeof validators !== 'object') {
      console.error(
        'new ValidationModel() validators argument must be of type object.'
      );
      error = true;
    }

    if (!error) {
      /** @private */
      this._properties = { ...properties };

      this._buildProperties();

      /** @private */
      this.validator = new Validator(validators);
    }
  }

  /** @private */
  _createProperty(key, value) {
    if (!this._properties[key]) {
      this._properties[key] = value;
    }

    Object.defineProperty(this, key, {
      get: () => {
        return this._properties[key];
      },
      set: value => {
        this._setProperty(key, value);
      },
    });
  }

  /** @private */
  _setProperty(key, value) {
    if (!this._meta.strict || this._properties.hasOwnProperty(key)) {
      if (this._properties[key] !== value) {
        this._meta.dirty = true;
        this._properties[key] = value;
      }
    } else {
      if (!this._meta.strict) {
        this._createProperty(key, value);
        this._setProperty(key, value);
      }
    }
  }

  /** @private */
  _buildProperties() {
    for (let propKey in this._properties) {
      this._createProperty(propKey, this._properties[propKey]);
    }
  }

  get getValidity() {
    if (this.validator) {
      // console.debug("Running validators on", this._properties);
      return this.validator.runValidators(this._properties);
    } else {
      console.error('Invalid Validator object');
      return undefined;
    }
  }

  set(key, value) {
    this._setProperty(key, value);
  }

  /**
   * @template {Object<string, any>} KeyVal
   * @param {KeyVal} obj Object of key value pairs to assign to this model's properties
   */
  setMany(obj) {
    for (let key in obj) {
      this.set(key, obj[key]);
    }
  }

  get properties() {
    return this._properties;
  }

  get isDirty() {
    return this._meta.dirty;
  }

  clearDirtyFlag() {
    this._meta.dirty = false;
  }
}
