import React, { useEffect, useMemo } from 'react';
import {
  RPSCard,
  RPSListContainer,
  RPSSwitchSimple,
  RPSCheckbox,
} from '@rps/web-components/build/react-wrappers';
import { Deal } from '../../../models/Deal.jsx';
import { useCurrentDealSource } from '../../../hooks/useCurrentDealSource';
import { DealBuyerAndSupplier } from '../../../models/BuyerAndSupplier.jsx';
import { DealShipping } from '../../../models/Shipping.jsx';
import { DealProduct } from '../../../models/Product.jsx';
import { DealPriceAndCustomOptions } from '../../../models/PriceAndCustomOptions.jsx';
import PropTypes from 'prop-types';
import { useAttributeOptions } from '@odo/hooks/attributes';
import { AttributeCode } from '@odo/types/api';
import { Select } from '@odo/components/elements/form-fields';
import { handleODOInput } from 'utils/odo-migration/forms';

const BorderedContainer = ({ children }) => (
  <RPSListContainer
    css=":host { padding: 1rem; border: 1px solid #aaa; width: fit-content; }"
    className="vertical"
  >
    {children}
  </RPSListContainer>
);

BorderedContainer.propTypes = {
  children: PropTypes.any,
};

export const CampaignSection = () => {
  const currentDeal = useCurrentDealSource();
  const model = currentDeal.deal.buyerAndSupplier;
  const handleInput = currentDeal.createInputHandler(
    Deal.MODELS.BUYER_AND_SUPPLIER
  );

  const dealTypeOptions = useAttributeOptions(AttributeCode.dealType);
  const campaignOptions = useAttributeOptions(AttributeCode.campaign);
  const campaignMailerOptions = useAttributeOptions(
    AttributeCode.campaignMailer
  );
  const platformOptions = useAttributeOptions(AttributeCode.platform);
  const priorityOptions = useAttributeOptions(AttributeCode.priority);

  const [firstPriority, middlePriority] = useMemo(() => {
    const numericPriorities = priorityOptions
      .filter(option => !isNaN(option.value))
      .sort((a, b) => a.value - b.value);

    const [first] = numericPriorities;
    const middle = numericPriorities[Math.floor(numericPriorities.length / 2)];
    const [last] = [...numericPriorities].reverse();

    return [first, middle, last];
  }, [priorityOptions]);

  const handleMailerInput = ev => {
    let mailers = model.campaignMailer || [];

    if (ev.detail.checked) {
      if (mailers.findIndex(x => x === ev.detail.id) === -1) {
        mailers.push(ev.detail.id);
      }
    } else {
      mailers = mailers.filter(x => x !== ev.detail.id).filter(x => x);
    }
    currentDeal.deal.set(
      Deal.MODELS.BUYER_AND_SUPPLIER,
      DealBuyerAndSupplier.FIELDS.MAILERS,
      [...mailers]
    );
    currentDeal.update();
  };

  const handlePlatformInput = ev => {
    if (model.platform.find(item => item === ev.detail.id)) {
      currentDeal.deal.set(
        Deal.MODELS.BUYER_AND_SUPPLIER,
        DealBuyerAndSupplier.FIELDS.PLATFORM,
        model.platform.filter(item => item !== ev.detail.id)
      );
    } else {
      currentDeal.deal.set(
        Deal.MODELS.BUYER_AND_SUPPLIER,
        DealBuyerAndSupplier.FIELDS.PLATFORM,
        [...model.platform, ev.detail.id]
      );
    }
    currentDeal.update();
  };

  const handleDealTypeInput = ev => {
    let dealType = model.dealType || [];
    if (ev.detail.checked) {
      if (dealType.findIndex(x => x === ev.detail.id) === -1) {
        dealType.push(ev.detail.id);
      }
    } else {
      dealType = dealType.filter(x => x !== ev.detail.id).filter(x => x);
    }

    let newDealType = [...dealType];
    /**
     * NOTE: sending an empty array on create product throws an error on the API
     * so for now we're just gonna send null when there are no deal type options.
     *
     * We need to also do this when duplicating a deal:
     * @see /src/components/deals/Duplicate.js -> processNewDeal
     */
    if (newDealType.length === 0 && !currentDeal.deal.meta.id) {
      newDealType = null;
    }

    currentDeal.deal.set(
      Deal.MODELS.BUYER_AND_SUPPLIER,
      DealBuyerAndSupplier.FIELDS.DEAL_TYPE,
      newDealType
    );

    /**
     * NOTE: when checking supplier voucher we want to set some fields.
     * but when unchecking we need to reset them.
     * a few however will be cleared as we cannot reliably know which it should be,
     * and will rather force the user to select the correct value before saving.
     */
    if (ev.detail.id === 'SUPPLIER_VOUCHER') {
      const checked = ev.detail.checked;

      currentDeal.deal.set(
        Deal.MODELS.PRODUCT,
        DealProduct.FIELDS.AREA,
        checked ? 'OTHER' : null
      );

      currentDeal.deal.set(
        Deal.MODELS.PRICE_AND_CUSTOM_OPTIONS,
        DealPriceAndCustomOptions.FIELDS.ADMIN,
        checked ? 'ADMINCOST_10_00' : 'ADMINCOST_17_50'
      );

      currentDeal.deal.set(
        Deal.MODELS.SHIPPING,
        DealShipping.FIELDS.APPLY_SHIPPING,
        !checked
      );

      currentDeal.deal.set(
        Deal.MODELS.SHIPPING,
        DealShipping.FIELDS.INDIVIDUAL_SHIPPING,
        !!checked
      );

      currentDeal.deal.set(
        Deal.MODELS.SHIPPING,
        DealShipping.FIELDS.SUPPLIER_DELIVERS,
        !!checked
      );

      currentDeal.deal.set(
        Deal.MODELS.SHIPPING,
        DealShipping.FIELDS.CUSTOMER_DELIVERY_TIME,
        checked ? 'NOT_APPLICABLE' : null
      );
    }

    /**
     * Automatically set first priority when "Main Deal" is toggled on.
     */
    if (
      ev.detail.id === 'MAIN_DEAL' &&
      ev.detail.checked &&
      typeof firstPriority !== 'undefined'
    ) {
      currentDeal.deal.set(
        Deal.MODELS.BUYER_AND_SUPPLIER,
        DealBuyerAndSupplier.FIELDS.PRIORITY,
        firstPriority.originalData.value
      );
    }

    /**
     * Automatically set middle priority when "Main Deal" is toggled off.
     */
    if (
      ev.detail.id === 'MAIN_DEAL' &&
      !ev.detail.checked &&
      typeof middlePriority !== 'undefined'
    ) {
      currentDeal.deal.set(
        Deal.MODELS.BUYER_AND_SUPPLIER,
        DealBuyerAndSupplier.FIELDS.PRIORITY,
        middlePriority.originalData.value
      );
    }

    currentDeal.update();
  };

  /**
   * Campaign cannot be empty.
   * Look for "none" option and set it.
   */
  useEffect(() => {
    if (!model.campaign && campaignOptions.length > 0) {
      const none = campaignOptions.find(c => c.key === 'NONE');
      if (none) {
        currentDeal.deal.set(
          Deal.MODELS.BUYER_AND_SUPPLIER,
          DealBuyerAndSupplier.FIELDS.CAMPAIGN,
          none.value,
          false
        );
        currentDeal.update();
      }
    }
  }, [currentDeal, model.campaign, campaignOptions]);

  return (
    <RPSCard>
      <div slot="header">
        <h5>Campaign</h5>
      </div>

      <BorderedContainer>
        <strong>Deal Type:</strong>
        {dealTypeOptions
          .filter(dealType => dealType.id !== 'NONE' && !!dealType.value)
          .map(dealType => (
            <RPSCheckbox
              id={dealType.value}
              className="small"
              cbInput={handleDealTypeInput}
              checked={(model.dealType || []).includes(dealType.value)}
              label={dealType.label.toUpperCase()}
              key={dealType.value}
            />
          ))}
      </BorderedContainer>

      <RPSListContainer className="vertical">
        <RPSSwitchSimple
          caching
          name="dealHeaderInfoBestSellers"
          checked={model.isBestSeller}
          label="Best Seller"
          cbInput={handleInput(DealBuyerAndSupplier.FIELDS.IS_BEST_SELLER)}
        />
      </RPSListContainer>

      <Select
        label="Campaign"
        value={model.campaign}
        onChange={handleODOInput({
          currentDeal,
          model: Deal.MODELS.BUYER_AND_SUPPLIER,
          field: DealBuyerAndSupplier.FIELDS.CAMPAIGN,
        })}
        options={[
          ...campaignOptions.map(option => ({
            id: option.value,
            value: option.value,
            label: option.label,
          })),
        ]}
        matchRPStyles
      />

      <BorderedContainer>
        <strong>Include in Campaign Mailers: </strong>
        {campaignMailerOptions
          .filter(campaign => campaign.value !== 'NONE' && !!campaign.value)
          .map(campaign => (
            <RPSCheckbox
              id={campaign.value}
              className="small"
              cbInput={handleMailerInput}
              checked={(model.campaignMailer || []).includes(campaign.value)}
              label={campaign.label.toUpperCase()}
              key={campaign.value}
            />
          ))}
      </BorderedContainer>

      <BorderedContainer>
        <strong>Platforms:</strong>
        {platformOptions.map(platform => (
          <RPSCheckbox
            id={platform.value}
            className="small"
            cbInput={handlePlatformInput}
            checked={(model.platform || []).includes(platform.value)}
            label={platform.label.toUpperCase()}
            key={platform.value}
          />
        ))}
      </BorderedContainer>
    </RPSCard>
  );
};
