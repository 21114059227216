import { useProductEditor } from '@odo/contexts/product-editor';
import ProductContext from './context';
import { useCallback, useContext } from 'react';
import type { EditorProductInterface } from '@odo/types/portal';

export const useProductContext = () => {
  const product = useContext(ProductContext);
  return product;
};

export const useProduct = () => {
  const { product } = useProductContext();
  return product;
};

export const useSetProduct = () => {
  const { setProduct } = useProductContext();
  return setProduct;
};

export const useSetProductAfterSave = () => {
  const { resetEditor } = useProductEditor();
  const setProduct = useSetProduct();

  const callback = useCallback(
    (product: EditorProductInterface) => {
      resetEditor();
      setProduct(product);
    },
    [setProduct, resetEditor]
  );

  return callback;
};
