import { ApolloClients } from './../../services/apolloClients';
import { GET_DROPDOWN_VALUES } from './../../gql/dropdowns/getDropdownValues';
import { DROPDOWN } from 'gql/dropdowns/getDropdownValues';

export async function fetchPriorityByValue(priorityValue) {
  const client = new ApolloClients().odo;

  const { data } = await client.query({
    query: GET_DROPDOWN_VALUES,
    variables: {
      name: DROPDOWN.PRIORITY,
    },
    errorPolicy: 'ignore',
    fetchPolicy: 'cache-first',
  });

  if (data.getDropDownValues?.length > 0) {
    const match = data.getDropDownValues.find(
      item => item.key == priorityValue
    );
    return match.value;
  }

  return '';
}
