import { SearchEditorProvider } from '@odo/contexts/search/editor';
import { SearchFiltersProvider } from '@odo/contexts/search/filters';
import type { ReactNode } from 'react';

const SearchProviders = ({ children }: { children: ReactNode }) => (
  <SearchFiltersProvider>
    <SearchEditorProvider>{children}</SearchEditorProvider>
  </SearchFiltersProvider>
);

export default SearchProviders;
