// Parse numbers that may contain text, commas, etc. i.e.: "R1,234.55"
// And return a correct numeric value.

export function parseDecimal(input) {
  if (typeof input !== 'string') {
    input = input.toString();
  }

  let processedValue = 0;
  const splitValues = input.match(/(.+)([\.,])(\d+)$/);

  if (splitValues && splitValues.length >= 4) {
    const lastSeparator = splitValues[2];
    if (splitValues[1].match(new RegExp(`[\\${lastSeparator}]`, 'g'))) {
      processedValue = input.replace(/[^0-9\-]/g, '');
    } else {
      const lhs = splitValues[1].replace(/[^0-9\-]/g, '');
      const rhs = splitValues[3];
      processedValue = `${lhs}.${rhs}`;
    }
  } else {
    processedValue = input.replace(/[a-z\s_\#\!]/gi, '').replace(',', '.');
  }

  if (!processedValue) {
    return NaN;
  } else {
    return Number.parseFloat(processedValue);
  }
}
