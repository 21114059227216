import { gql } from '@apollo/client';

export const GET_CATEGORIES = gql`
  query GetCategories {
    getCategories {
      id
      name
    }
  }
`;

export const GET_CATEGORY = gql`
  query GetCategory($categoryId: ID!) {
    getCategory(categoryId: $categoryId) {
      ... on Category {
        id
        name
        parentCategory
        activeFromDate
        activeToDate
      }
    }
  }
`;

export const GET_PRODUCT_CATEGORIES = gql`
  query GetProductCategories($productId: ID!) {
    getProductCategories(productId: $productId) {
      categoryId
      categoryName
    }
  }
`;
