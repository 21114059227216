/**
 * NOTE: we search for the SKU fieldId specifically when saving the deal.
 *
 * If we accidentally change the fieldId in one location, but not another,
 * we could end up with unexpected behaviour during save.
 *
 * So for this specific field at least we're gonna use a constant.
 *
 * If we find ourselves with more scenarios where we need to reference the same fieldId,
 * we might want to implement a more robust solution. But this is fine for now.
 */
export const SKU_FIELD_ID = 'sku';
