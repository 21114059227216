import Button from '@odo/components/elements/button';
import { FaUndo as IconReset } from 'react-icons/fa';
import { useProductEditor } from '@odo/contexts/product-editor';

const ResetButton = () => {
  const { canReset, reset } = useProductEditor();

  return (
    <>
      {/* reset button - mobile */}
      <Button
        hue="yellow"
        onClick={reset}
        variant={canReset ? 'outlined' : 'flat'}
        disabled={!canReset}
        display={['flex', 'none']}
        circular
        px={2}
        py={2}
      >
        <IconReset />
      </Button>

      {/* reset button - tablet up */}
      <Button
        hue="yellow"
        onClick={reset}
        variant={canReset ? 'outlined' : 'flat'}
        disabled={!canReset}
        display={['none', 'flex']}
      >
        <IconReset />
        Reset
      </Button>
    </>
  );
};

export default ResetButton;
