import React, {
  useEffect,
  useState,
  useMemo,
  useContext,
  useCallback,
} from 'react';
import { ModalContext } from '../../contexts/Modal';
import Last10Grid from './Last10Grid';
import { calculateProfit } from '../../utils/calculator/priceCalculator';
import { useUserDataSource } from '../../hooks/userDataSource';
import {
  RPSListContainer,
  RPSModal,
  RPSCard,
  RPSInput,
  RPSButton,
  RPSDropDown,
  RPSCheckbox,
  RPSButtonIcon,
  RPSKeyValueTable,
} from '@rps/web-components/build/react-wrappers';
import { iconNames } from '@rps/web-components/build/web-components';
import { AdminFeeOptions } from '../../components/deals/priceAndCustomOptions/data';
import { useCurrentDealSource } from '../../hooks/useCurrentDealSource';
import { resultRowsBasics, resultRowProviders } from './ResultRows';
import { DecimalInput } from './../uiComponents/DecimalInput.jsx';
import { getAdminCostValue } from 'utils/getAdminCostValue';
import PropTypes from 'prop-types';

const Calculator = ({ warning = '' }) => {
  const user = useUserDataSource();
  const currentDeal = useCurrentDealSource();

  const cachedHistoryKey = `priceCalculatorHistory-${user.state.userId}`;
  const cachedHistory = localStorage.getItem(cachedHistoryKey);
  const [calcHistory, setCalcHistory] = useState(
    cachedHistory ? JSON.parse(cachedHistory) : []
  );

  const initialState = {
    cost: '0',
    price: '0',
    retail: '0',
    soh: '0',
    admin: 'ADMINCOST_17_50',
    surcharge: '0',
    rebate: '0',
    insurance: false,
    insuranceValue: '0',
    vat: true,
  };

  const [calcState, setCalcState] = useState(initialState);

  useEffect(() => {
    if (currentDeal.tempDeal) {
      const pricing = currentDeal.tempDeal.priceAndCustomOptions;
      setCalcState({
        cost: pricing.cost || '0',
        price: pricing.price || '0',
        retail: pricing.retail || '0',
        soh: pricing.soh || '0',
        admin: pricing.adminCost || 'ADMINCOST_17_50',
        surcharge: pricing.surcharge || '0',
        rebate: pricing.rebate || '0',
        insurance: pricing.insurance || false,
        insuranceValue: pricing.insuranceValue || '0',
        vat: pricing.vat || true,
      });
    }
  }, [currentDeal.tempDeal]);

  const calcResult = useMemo(() => {
    let adminCost = getAdminCostValue(calcState.admin);
    const res = calculateProfit({
      price: +calcState.price,
      retail: +calcState.retail,
      cost: +calcState.cost,
      vat: calcState.vat ? 1 : 0,
      insurance: calcState.insurance,
      rebate: +calcState.rebate || 0,
      admin: adminCost,
      surcharge: +calcState.surcharge,
      soh: +calcState.soh,
    });
    return res;
  }, [calcState]);

  const saveCalculationToHistory = () => {
    calcHistory.push({
      ...calcState,
      weighted_average: calcResult.weightedAverage,
    });
    const newCalcHistory = calcHistory.slice(
      Math.max(calcHistory.length - 10, 0)
    );
    setCalcHistory([...newCalcHistory]);
    localStorage.setItem(cachedHistoryKey, JSON.stringify(newCalcHistory));
  };

  const clearFields = () => {
    setCalcState(initialState);
  };

  const populateFromHistory = row => {
    const data = calcHistory
      .slice(0)
      .reverse()
      .find((_, index) => index === +row.rowId);
    setCalcState({ ...data });
  };

  const resetInsuranceValue = useCallback(() => {
    calcState.insuranceValue = calcResult.insurance;
    setCalcState({ ...calcState });
  }, [calcResult.insurance, calcState]);

  useEffect(() => {
    if (calcState.insurance && !calcState.insuranceValue) {
      resetInsuranceValue();
    }
  }, [calcState.insurance, calcState.insuranceValue, resetInsuranceValue]);

  const handleInput = field => ev => {
    setCalcState({
      ...calcState,
      [field]: ev.detail.value || ev.detail.checked,
    });
  };

  const handleCheckboxInput = field => ev => {
    setCalcState({ ...calcState, [field]: ev.detail.checked });
  };

  const modal = useContext(ModalContext);

  const last10GridData = calcHistory
    .slice(0)
    .reverse()
    .map((item, index) => {
      return { ...item, id: index };
    });

  return (
    <RPSListContainer
      className="horizontal"
      css=":host { overflow-y: auto; } #list-container { justify-content: center; }"
    >
      <RPSCard css=":host { width: 49%; }">
        <div slot="header">
          <h6>Calculation Values</h6>
        </div>
        {warning && (
          <RPSModal opened>
            <h5 slot="header">Warning:</h5>
            <rps-input-label text={warning} open class="warning" />
            <div slot="footer" />
          </RPSModal>
        )}
        <RPSListContainer className="horizontal">
          <DecimalInput
            selectOnFocus
            label="Cost"
            value={calcState.cost || ''}
            type="currency"
            cbInput={handleInput('cost')}
          />
          <DecimalInput
            selectOnFocus
            label="Price"
            value={calcState.price || ''}
            type="currency"
            cbInput={handleInput('price')}
          />
          <DecimalInput
            selectOnFocus
            label="Retail"
            value={calcState.retail || ''}
            type="currency"
            cbInput={handleInput('retail')}
          />
          <DecimalInput
            selectOnFocus
            label="Original Stock"
            value={calcState.soh || ''}
            max={9999}
            cbInput={handleInput('soh')}
          />
          <DecimalInput
            selectOnFocus
            label="Rebate (%)"
            value={calcState.rebate || ''}
            cbInput={handleInput('rebate')}
          />
          <DecimalInput
            selectOnFocus
            label="Surcharge"
            value={calcState.surcharge || ''}
            cbInput={handleInput('surcharge')}
            type="currency"
          />
        </RPSListContainer>
        <RPSDropDown
          label="Admin Fee"
          defaultValue={AdminFeeOptions[0].id}
          items={AdminFeeOptions}
          value={calcState.admin}
          cbInput={handleInput('admin')}
        />
        <RPSListContainer className="vertical">
          <RPSCheckbox
            label="VAT Included"
            checked={calcState.vat}
            cbInput={handleCheckboxInput('vat')}
          />
          <span
            style={{
              display: 'flex',
              gap: '2rem',
              alignItems: 'center',
              width: 'min-content',
            }}
          >
            <RPSCheckbox
              label="Insurance"
              checked={calcState.insurance}
              cbInput={handleCheckboxInput('insurance')}
            />
            {calcState.insurance && (
              <>
                <RPSButtonIcon
                  class="medium"
                  svg="undo"
                  title="Reset Insurance"
                  cbClick={resetInsuranceValue}
                />
                <RPSInput
                  type="currency"
                  value={
                    calcResult.insurance !== true ? calcResult.insurance : 0
                  }
                  cbInput={handleInput('insuranceValue')}
                />
              </>
            )}
          </span>
        </RPSListContainer>
        <RPSListContainer
          className="horizontal"
          css="#list-container {justify-content: space-between;}"
        >
          <RPSButton
            className="secondary"
            cbClick={() => {
              clearFields();
              modal.pushToast('Calculator reset', 'success');
            }}
          >
            Reset Calculation
            <rps-svg slot="slot-left" svg={iconNames.reload} />
          </RPSButton>

          <RPSButton
            className="primary"
            cbClick={() => {
              saveCalculationToHistory();
              modal.pushToast('Calculation saved to history', 'success');
            }}
          >
            Save Calculation
            <rps-svg slot="slot-left" svg={iconNames.calculator} />
          </RPSButton>
        </RPSListContainer>
      </RPSCard>
      <RPSListContainer className="vertical">
        <Last10Grid populate={populateFromHistory} data={last10GridData} />
        <RPSCard>
          <div slot="header">
            <h6>Calculation Results</h6>
            <RPSListContainer className="vertical">
              <RPSKeyValueTable
                css=":host { width: 100%; margin: 0; } .rowCell {text-align: right;} "
                rows={resultRowsBasics}
                data={calcResult}
              />
              <RPSKeyValueTable
                css=":host { width: 100%; margin: 0; } .rowCell {text-align: right;} "
                rows={resultRowProviders}
                data={calcResult}
              />
            </RPSListContainer>
          </div>
        </RPSCard>
      </RPSListContainer>
    </RPSListContainer>
  );
};

Calculator.propTypes = {
  warning: PropTypes.any,
};

export default Calculator;
