import { gql } from '@apollo/client';

export const UPDATE_INVENTORY_ITEM = gql`
  mutation UpdateInventoryItem(
    $stockId: ID!
    $inventoryItem: UpdateInventoryItemInput!
  ) {
    updateInventoryItem(stockId: $stockId, inventoryItem: $inventoryItem) {
      ... on ResponseMessage {
        status
        code
        message
      }
      ... on Inventory {
        id
        qty
        minSaleQuantity
        maximumSaleQuantity
        useConfigMinSaleQty
        useConfigMaxSaleQty
        isInStock
        isApplyMaxSaleQtyToProductOptions
        isApplyMaxSaleQtyCustomerProfile
      }
    }
  }
`;
