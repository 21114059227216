import { Flex, Grid } from '@odo/components/elements/layout';
import { Text } from '@odo/components/elements/typography';
import ErrorBoundary from '@odo/components/widgets/error-boundary';
import { useCurrentProduct } from '@odo/contexts/product-editor';
import SectionWrapper from '@odo/screens/deal/editor/elements/section-wrapper';
import { Overscroll } from '@odo/screens/deal/editor/elements/styles';

const SummarySection = () => {
  const currentProduct = useCurrentProduct();

  return (
    <SectionWrapper title={currentProduct.name || 'Summary'}>
      <Text>TBC</Text>
    </SectionWrapper>
  );
};

const CostingInfoSection = () => {
  return (
    <SectionWrapper title="Costing Info">
      <Text>TBC</Text>
    </SectionWrapper>
  );
};

const SkuAndSuggestionsSection = () => {
  return (
    <SectionWrapper title="SKU & SKU Suggestions">
      <Text>TBC</Text>
    </SectionWrapper>
  );
};

const SummaryScreen = () => (
  <ErrorBoundary>
    <Grid gridTemplateColumns={['1fr', '3fr 1fr']} gap={[3, 4]}>
      <SummarySection />
      <Flex flexDirection="column" gap={[3, 4]}>
        <CostingInfoSection />
        <SkuAndSuggestionsSection />
      </Flex>
    </Grid>
    <Overscroll />
  </ErrorBoundary>
);

export default SummaryScreen;
