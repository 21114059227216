import { excelRounding } from '@odo/helpers/calculations/general';

interface Rate {
  flat: number;
  perKg: number;
}

const RATE_A: Rate = { flat: 42.36, perKg: 1.05 };
const RATE_B: Rate = { flat: 64.49, perKg: 2.11 };

const CPT_RATE_FRACTION = 2 / 3;
const JHB_RATE_FRACTION = 1 / 3;

const BASE_WEIGHT = 10;
const VOLUMETRIC_WEIGHT_COEFFICIENT = 4000;
const FUEL = 0.1726;
const VAT = 0.14;

const calcExpected = (rate: Rate, w: number) => {
  if (w <= BASE_WEIGHT) {
    return rate.flat;
  }
  return excelRounding(Math.ceil(w - BASE_WEIGHT) * rate.perKg + rate.flat);
};

const calcFuel = (expected: number) => excelRounding(expected * FUEL);

const calcVat = (expected: number, fuel: number) =>
  excelRounding((expected + fuel) * VAT, 'round');

const calcTotalExpected = (rate: Rate, w: number) => {
  const expected = calcExpected(rate, w);
  const fuel = calcFuel(expected);
  const vat = calcVat(expected, fuel);
  const totalExpected = excelRounding(expected + fuel + vat);
  return totalExpected;
};

export const calcVolumetricWeight = (
  length: number,
  width: number,
  depth: number
) => (length * width * depth) / VOLUMETRIC_WEIGHT_COEFFICIENT;

export const getGreaterWeight = ({
  weight,
  length,
  width,
  depth,
}: {
  weight?: number;
  length?: number;
  width?: number;
  depth?: number;
}) => {
  let volumetricWeight: number | undefined;

  if (
    typeof length !== 'undefined' &&
    typeof width !== 'undefined' &&
    typeof depth !== 'undefined'
  ) {
    volumetricWeight = calcVolumetricWeight(length, width, depth);
  }

  if (
    typeof weight !== 'undefined' &&
    typeof volumetricWeight !== 'undefined'
  ) {
    return excelRounding(Math.max(weight, volumetricWeight));
  } else if (typeof weight !== 'undefined') {
    return excelRounding(weight);
  } else if (typeof volumetricWeight !== 'undefined') {
    return excelRounding(volumetricWeight);
  }
};

// TODO: consider memoizing some of these functions
export const calcWeightAreaSurcharge = ({
  area,
  supplierDelivers,
  weight,
  length,
  width,
  depth,
}: {
  area: 'cpt' | 'jhb' | string;
  supplierDelivers?: boolean;
  weight?: number;
  length?: number;
  width?: number;
  depth?: number;
}) => {
  if (supplierDelivers || !['cpt', 'jhb'].includes(area)) {
    return;
  }

  const greaterWeight = getGreaterWeight({ weight, length, width, depth });
  if (typeof greaterWeight === 'undefined' || greaterWeight < BASE_WEIGHT) {
    return;
  }

  const rateA = calcTotalExpected(RATE_A, greaterWeight);
  const rateB = calcTotalExpected(RATE_B, greaterWeight);

  const surcharge = excelRounding(
    (rateB - rateA) * (area === 'cpt' ? CPT_RATE_FRACTION : JHB_RATE_FRACTION)
  );

  return surcharge;
};
