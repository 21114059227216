export const WatchForChanges = (target, key, onChange) => {
  let source = target[key];
  Object.defineProperty(target, key, {
    get: () => source,
    set: v => {
      source = v;
      onChange(target);
    },
  });
};

export const WaitForChanges = (target, key) => {
  return new Promise(resolve => {
    WatchForChanges(target, key, context => {
      resolve(context);
    });
  });
};
