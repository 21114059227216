import { debounce } from '@odo/utils/debounce';
import { useContext, useMemo } from 'react';
import CustomOptionsEditorContext from './context';

export const useCustomOptionsEditorContext = () =>
  useContext(CustomOptionsEditorContext);

export const useAddAction = () => {
  const { addAction } = useCustomOptionsEditorContext();
  return addAction;
};

export const useAddActionDebounced = (wait = 250) => {
  const addAction = useAddAction();
  const debounced = useMemo(() => debounce(addAction, wait), [addAction, wait]);
  return debounced;
};

export const useShowErrors = () => {
  const { showErrors } = useCustomOptionsEditorContext();
  return showErrors;
};

export const useCopyPaste = () => {
  const { copyingOptionId, copyOption, cancelCopy, pasteOption } =
    useCustomOptionsEditorContext();
  return { copyingOptionId, copyOption, cancelCopy, pasteOption };
};

export const useAutoSumEnabled = () => {
  const { autoSumEnabled } = useCustomOptionsEditorContext();
  return autoSumEnabled;
};
