import { Flex } from '@odo/components/elements/layout';
import { ImageListItem } from './';
import type { EditorProductImage } from '@odo/types/portal';

const ImageList = ({
  sortedImages,
  selected,
  toggleSelected,
}: {
  sortedImages: EditorProductImage[];
  selected: EditorProductImage['id'][];
  toggleSelected: (id: EditorProductImage['id']) => (select: boolean) => void;
}) => (
  <Flex flexDirection="column" gap={[2, 3]}>
    {sortedImages.map(image => (
      <ImageListItem
        key={image.id}
        image={image}
        isSelected={selected.includes(image.id)}
        toggleSelected={toggleSelected(image.id)}
      />
    ))}
  </Flex>
);

export default ImageList;
