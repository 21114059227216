import type { GraphQLError } from 'graphql';
import type { CombinedError } from 'urql';

export const isAuthError = (err: Readonly<GraphQLError>) => {
  if (!err.extensions || typeof err.extensions.code !== 'string') return false;

  return [
    'UNAUTHENTICATED',
    'AUTHENTICATION_REQUIRED',
    'ACCESS_DENIED',
  ].includes(err.extensions.code);
};

export const authErrorCallback = () => {
  console.debug(
    '[ApolloClients:odoClient] Authentication error - session expired, logging out.'
  );

  localStorage.removeItem('user');
  localStorage.setItem(
    'authError',
    'Session Expired, please log in again before continuing. (Note: Your work is safe!)'
  );
  localStorage.setItem('lastLocation', window.location.pathname);

  // FIXME: it'd be better to have a logout system that didn't depend on reloading.
  window.location.reload();
};

interface GraphQLErrorMessageObject {
  message: GraphQLError['message'];
  code: string;
  status: string;
}

type GraphQLErrorMessageArray = GraphQLErrorMessageObject[];

const isGraphQLErrorMessage = (
  message: GraphQLErrorMessageObject | unknown
): message is GraphQLErrorMessageObject =>
  typeof (message as GraphQLErrorMessageObject) === 'object' &&
  typeof (message as GraphQLErrorMessageObject).message === 'string';

const isGraphQLErrorMessageArray = (
  message: GraphQLErrorMessageArray | unknown
): message is GraphQLErrorMessageArray =>
  Array.isArray(message) && message.every(isGraphQLErrorMessage);

export const throwGraphQLError = (error: CombinedError) => {
  if (error.networkError) {
    throw new Error(`Network error: "${error.networkError.message}"`);
  }

  const [firstGraphQLError] = error.graphQLErrors;
  if (firstGraphQLError) {
    // NOTE: we HAVE to cast this to unknown because the type is not supporting our possible API data structures
    const message = firstGraphQLError.message as unknown;
    if (typeof message === 'string') {
      throw new Error(message);
    } else if (isGraphQLErrorMessageArray(message)) {
      const [firstMessage] = message;
      // TODO: could consider adding the code & status here in future
      throw new Error(firstMessage.message);
    }
  }

  throw new Error('GraphQL error. Please export your API logs for debugging.');
};
