import client from '@odo/services/urql';
import SearchEditorContext from './context';
import type { DealChanges, UpdateParams, UpdateProductInput } from './types';
import type { ReactNode } from 'react';
import { useCallback, useMemo, useState } from 'react';
import type {
  MutationUpdateProductFromGridArgs,
  MutationUpdateProductFromGridOutput,
} from '@odo/types/api';
import { dateObjectToIso } from '@odo/utils/date';
import { gql } from 'urql';
import {
  dismiss,
  error,
  invertedSuccessColors,
  loading,
  success,
} from '@odo/utils/toast';
import { cssColor } from '@odo/utils/css-color';
import { Text } from '@odo/components/elements/typography';

const UPDATE_DEAL = gql`
  mutation updateProduct($productId: ID!, $input: UpdateProduct!) {
    updateProduct(productId: $productId, input: $input) {
      ... on Product {
        id
        activeFromDate
        activeToDate
        priority
        isSampleReceived
        isPhotographedByStudio
      }
    }
  }
`;

/**
 * Update deal mutation.
 */
const updateDeal = async ({ id, input }: UpdateParams) => {
  let success = false;

  try {
    const { data, error } = await client
      .mutation<
        MutationUpdateProductFromGridOutput,
        MutationUpdateProductFromGridArgs
      >(UPDATE_DEAL, {
        productId: id,
        input,
      })
      .toPromise();

    // if there is any sort of error we must outright fail.
    if (error) {
      let message = 'There was an error uploading changes';
      const [firstGraphQLError] = error.graphQLErrors;
      if (firstGraphQLError) {
        message = firstGraphQLError.message;
      }
      throw new Error(message);
    }

    if (data && data?.updateProduct) {
      success = Object.entries(input).every(([field, value]) => {
        if (typeof data.updateProduct[field] === 'undefined') return false;

        return ['activeFromDate', 'activeToDate'].includes(field)
          ? dateObjectToIso(new Date(data.updateProduct[field]), true) === value
          : data.updateProduct[field] === value;
      });
    }
  } catch (e) {
    console.error(e);
    error(
      e instanceof Error && typeof e.message === 'string'
        ? e.message
        : 'There was an error uploading changes'
    );
  }

  return success;
};

const UploadingChanges = ({
  current,
  total,
}: {
  current: number;
  total: number;
}) => (
  <>
    Uploading changes:&nbsp;
    <Text color={cssColor('palette-blue')} fontWeight={600}>
      {current}/{total}
    </Text>
  </>
);

const SearchEditorProvider = ({ children }: { children: ReactNode }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [changes, setChanges] = useState<DealChanges[]>([]);
  const [uploadChangesCallback, setUploadChangesCallback] = useState<
    (() => void) | undefined
  >();

  const uploadChanges = useCallback(() => {
    const updates: UpdateParams[] = changes.map(changes => {
      const fields: UpdateProductInput = {
        isSampleReceived: changes.isSampleReceived,
        isPhotographedByStudio: changes.isPhotographedByStudio,
      };

      // TODO: BP-380: re-enable date editing once we can handle live dates properly
      ['priority'].forEach(field => {
        if (
          typeof changes.fields[field] !== 'undefined' &&
          changes.fields[field].original !== changes.fields[field].new
        ) {
          fields[field] = changes.fields[field].new;
        }
      });

      return { id: changes.id, input: { ...fields } };
    });

    // TODO: parallelize these uploads and add failure retries (not a high priority right now, but beneficial in future)
    const uploadAsync = async (updates: UpdateParams[]) => {
      setIsUploading(true);

      const total = updates.length;
      const successes: string[] = [];
      const failures: string[] = [];

      const toastId = loading(<UploadingChanges current={0} total={total} />, {
        position: 'top-center',
      });

      for (let x = 0; x < updates.length; x++) {
        const update = updates[x];

        loading(<UploadingChanges current={x + 1} total={total} />, {
          id: toastId,
        });

        // if the update input has less than 1 key, then there's nothing to update, otherwise go for it
        if (
          Object.keys(update.input).length < 1 ||
          (await updateDeal(update))
        ) {
          successes.push(update.id);
        } else {
          failures.push(update.id);
        }
      }

      if (successes.length > 0) {
        success('Changes uploaded', {
          id: toastId,
          ...invertedSuccessColors,
        });
      } else {
        dismiss(toastId);
      }

      if (failures.length > 0) {
        // TODO: have some better solution for users other than trying again
        error(`Error uploading changes for: ${failures.join(', ')}`);
      }

      if (successes.length > 0) {
        setChanges(dealChanges =>
          dealChanges.filter(change => !successes.includes(change.id))
        );
        // trigger our callback if one has been set
        uploadChangesCallback && uploadChangesCallback();
      }

      setIsUploading(false);
    };

    uploadAsync(updates);
  }, [changes, uploadChangesCallback]);

  const value = useMemo(
    () => ({
      isUploading,
      changes,
      setChanges,
      uploadChanges,
      setUploadChangesCallback,
    }),
    [changes, isUploading, uploadChanges]
  );

  return (
    <SearchEditorContext.Provider value={value}>
      {children}
    </SearchEditorContext.Provider>
  );
};

export default SearchEditorProvider;
