import React from 'react';
import { Deal } from '../../models/Deal.jsx';
import { FormLayout } from './shared/FormLayout.jsx';
import { ConditionsSection } from './conditionsAndCategory/ConditionsSection';
import { CategorySection } from './conditionsAndCategory/CategorySection';
import { Grid } from '@odo/components/elements/layout/grid';

const ConditionsAndCategoryScreen = () => {
  return (
    <FormLayout model={Deal.MODELS.CONDITIONS_AND_CATEGORY}>
      <Grid gridTemplateColumns={['1fr', null, '1fr 1fr']}>
        <ConditionsSection />
        <CategorySection />
      </Grid>

      <span style={{ paddingBottom: '35vh' }} />
    </FormLayout>
  );
};

export default ConditionsAndCategoryScreen;
