import { iconNames } from '@rps/web-components/build/web-components';

export const appMenu = [
  {
    id: 'dashboard',
    text: 'Dashboard',
    leftSvg: iconNames.dashboard,
    path: '/dashboard',
  },
  {
    id: 'create-deal',
    text: 'Create Deal',
    leftSvg: iconNames.dealAdd,
    path: '/deals/create',
  },
  {
    id: 'search-deals',
    text: 'Search Deals',
    leftSvg: iconNames.dealSearch,
    path: '/deals/search',
  },
  // NOTE: keeping these just in case we want them back before DERP
  // {
  //   id: 'manage-deals-local',
  //   text: 'Drafts (Unsaved)',
  //   leftSvg: iconNames.edit,
  //   path: '/deals/list',
  // },
  // {
  //   id: 'import-deals',
  //   text: 'Upload Deals',
  //   leftSvg: iconNames.dealUpload,
  //   path: '/deals/upload',
  // },
];
