/**
 * This is validation will be removed after DERP
 */

const numericOnChangeRegex = new RegExp(/^-?\d*\.?\d?\d?$/);
const positiveNumericRegex = new RegExp(/^\d*\.?\d?$/);

export const isPositiveNumeric = (value: number | string | unknown) =>
  typeof value === 'number' ||
  (typeof value === 'string' && positiveNumericRegex.test(value));

export const isNumericOnChange = (value: number | string | unknown) =>
  typeof value === 'number' ||
  (typeof value === 'string' && numericOnChangeRegex.test(value));
