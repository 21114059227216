import React from 'react';
import { RPSListContainer } from '@rps/web-components/build/react-wrappers';
import { Deal } from '../../models/Deal.jsx';
import { FormLayout } from './shared/FormLayout.jsx';
import { BuyerSection } from './buyerAndSupplier/BuyerSection';
import { CampaignSection } from './buyerAndSupplier/CampaignSection';
import { SupplierSection } from './buyerAndSupplier/SupplierSection';
import { Grid } from '@odo/components/elements/layout/grid';

export const BuyerAndSupplierScreen = () => {
  return (
    <FormLayout model={Deal.MODELS.BUYER_AND_SUPPLIER}>
      <Grid gridTemplateColumns={['1fr', null, '1fr 1fr']}>
        <RPSListContainer className="vertical">
          <SupplierSection />
          <BuyerSection />
        </RPSListContainer>

        <CampaignSection />
      </Grid>
    </FormLayout>
  );
};
