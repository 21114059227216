import { html } from 'lit';
import { iconNames } from '@rps/web-components/build/web-components';
import { ellipsis } from '@odo/utils/string.ts';

export function dealListColumnDefinition(
  handleSelect,
  handleDelete,
  selected,
  toggleSelected,
  handleShowErrors
) {
  return [
    {
      headerText: '',
      key: 'id',
      sortable: false,
      data: {
        control: {
          customControl: {
            render: row => {
              const handleToggle = () => {
                toggleSelected(row);
              };

              return html`<div
                style="padding: var(--grid-padding); border-bottom: 1px solid var(--component-secondary);"
              >
                <rps-svg
                  @click=${handleToggle}
                  svg="${selected.includes(row)
                    ? iconNames.checkedBox
                    : iconNames.checkBox}"
                ></rps-svg>
              </div> `;
            },
          },
        },
      },
    },
    {
      headerText: 'Draft/Saved',
      key: 'meta',
      data: {
        transform: v =>
          typeof v.id === 'string' && v.id !== '' ? 'Saved' : 'Draft',
      },
    },
    {
      rowId: 'test',
      headerText: 'Validity',
      key: 'getValidity',
      sortable: true,
      data: {
        transform: v => {
          if (v?.isValid) {
            switch (v.status) {
              case 'partial':
                return 'Some errors, can upload.';
              case 'complete':
                return 'Ready for upload.';
              default:
                break;
            }
          }

          return 'Has Errors, cannot upload.';
        },
        control: {
          customControl: {
            render: (data, row) => {
              const status = row.getValidity?.status || '';
              let style = '--state-error';
              if (status === 'partial') style = '--state-warning';
              if (status === 'complete') style = '--state-success';
              return html`<div
                style="color: var(${style}); padding: var(--grid-padding); border-bottom: 1px solid var(--component-secondary); display: flex; gap: 1rem; align-items: center;"
              >
                <strong>${data}</strong>
                ${status !== 'complete'
                  ? html`<rps-button-icon
                      class="error"
                      @click=${() => {
                        handleShowErrors(row);
                      }}
                      svg="${iconNames.help}"
                    ></rps-button-icon>`
                  : ''}
              </div>`;
            },
          },
        },
      },
    },
    {
      headerText: 'Enabled?',
      key: 'product',
      data: { transform: v => v.status.toString().toUpperCase() },
    },
    {
      headerText: 'Brand',
      key: 'product',
      data: { transform: v => v.brand || '-' },
    },
    {
      headerText: 'Name',
      key: 'product',
      data: { transform: v => ellipsis(v.name, 30) || '-' },
    },
    {
      headerText: 'SKU',
      key: 'product',
      data: { transform: v => v.sku || '-' },
    },
    {
      headerText: 'Price',
      key: 'priceAndCustomOptions',
      data: { type: 'currency', transform: v => v.price },
    },
    {
      headerText: 'Cost',
      key: 'priceAndCustomOptions',
      data: { type: 'currency', transform: v => v.cost },
    },
    {
      headerText: '',
      key: 'edit',
      data: {
        control: {
          customControl: {
            render: (data, row, rowId) => {
              return html`<div
                class="container"
                style="border-bottom: var(--grid-border); display: grid; place-items: center;"
              >
                <rps-button @click=${() => handleSelect(+rowId)} title="Edit">
                  <rps-svg svg=${iconNames.edit}></rps-svg>
                </rps-button>
              </div>`;
            },
          },
        },
      },
    },
    {
      headerText: '',
      id: 'del',
      key: 'del',
      data: {
        control: {
          customControl: {
            render: (data, row, rowId) => {
              return html` <div
                class="container"
                style="border-bottom: var(--grid-border); display: grid; place-items: center;"
              >
                <rps-button
                  class="error"
                  @click=${() => handleDelete(+rowId)}
                  title="Delete"
                >
                  <rps-svg svg=${iconNames.del}></rps-svg>
                </rps-button>
              </div>`;
            },
          },
        },
      },
    },
  ];
}
