import { queryProductBySKU } from '@odo/graphql/product-new';

const validateSku = async ({ id, sku }: { id?: string; sku: string }) => {
  const allowed = await queryProductBySKU({ sku, id });
  if (!allowed) {
    throw new Error('Your sku is no longer available.');
  }
};

export default validateSku;
