import readXlsxFile from 'read-excel-file';
import { Deal } from '../models/Deal.jsx';
import { DealShipping } from '../models/Shipping.jsx';
import { DealPriceAndCustomOptions } from 'models/PriceAndCustomOptions.jsx';
import { DealProduct } from '../models/Product.jsx';
import { DealBuyerAndSupplier } from 'models/BuyerAndSupplier.jsx';
import { PlatformEnum } from 'constants/ODOEnums';
import { ApolloClients } from '../services/apolloClients';
import { GET_SUPPLIERS } from '../gql/deals/getSuppliers';
import { fetchPriorityByValue } from 'utils/data/fetchPriorityByValue';

export class SpreadsheetLoader {
  constructor(fileInput) {
    this._file = fileInput;
    this.data = [];
    this.mappedData = [];
    this.headings = [];
    this.variants = [];
    this.deals = [];
    this.isReady = false;
    this.errors = [];

    this.variantRegEx = /^variant \((.*)\)/i;
    this.skuVariantRegEx = /^sku additions \((.*)\)/i;
    this.valueSeparatorRegex = /[\n,;]/;

    this._loadSpreadsheet();
  }

  async _loadSpreadsheet() {
    try {
      const data = await readXlsxFile(this._file);

      if (data.length <= 1) {
        console.warn('Not enough rows to process in spreadsheet', this._file);
      } else {
        this.headings = data.splice(0, 1)[0];
        this.data = data;

        this.deals = this._build();
        this.isReady = true;
      }
    } catch (err) {
      console.error(
        `[SpreadsheetLoader] Failed to load or read XLSX file. Details: `,
        err
      );
    }
  }

  getRowValue(row, headerName, defaultValue) {
    const index = this.headings.findIndex(heading =>
      heading?.trim().match(new RegExp(`^${headerName}`, 'i'))
    );
    if (index >= 0) {
      if (!row[index]) return defaultValue;

      if (typeof defaultValue === 'number') {
        // Do some regex replacement to ensure we can correctly convert values entered like "R399.99", "R 50" or "99,5"
        // to their numeric equivalents.
        let processedValue = 0;
        const splitValues = row[index].toString().match(/(.+)([\.,])(\d+)$/);
        if (splitValues && splitValues.length >= 4) {
          const lastSeparator = splitValues[2];
          if (splitValues[1].match(new RegExp(`[\\${lastSeparator}]`, 'g'))) {
            processedValue = row[index].toString().replace(/[^0-9\-]/g, '');
          } else {
            const lhs = splitValues[1].replace(/[^0-9\-]/g, '');
            const rhs = splitValues[3];
            processedValue = `${lhs}.${rhs}`;
          }
        } else {
          processedValue = row[index]
            .toString()
            .replace(/[a-z\s_\#\!]+/gi, '')
            .replace(',', '.');
        }
        if (!processedValue) {
          return defaultValue;
        } else {
          return Number.parseFloat(processedValue);
        }
      } else if (typeof defaultValue === 'boolean') {
        return (
          !row[index].match(/(false|no|off|disabled)/gi) || !row[index] === ''
        );
      } else {
        return row[index];
      }
    } else {
      return defaultValue;
    }
  }

  async _loadDeal(row, deal, rowIndex) {
    const models = Deal.MODELS;

    const getTomorrowsDate = () => {
      const startDate = new Date();

      startDate.setDate(startDate.getDate() + 1);

      return startDate;
    };

    const valueMap = [
      // Map incoming headings to sub-models and property names
      // Format: <Model Name>, <Model Key Name>, <Heading Match Name>, <Default Value>
      [models.BUYER_AND_SUPPLIER, DealBuyerAndSupplier.FIELDS.SHOP, 'shop', ''],
      [
        models.BUYER_AND_SUPPLIER,
        DealBuyerAndSupplier.FIELDS.BUYER,
        'buyer',
        '',
      ],
      [
        models.BUYER_AND_SUPPLIER,
        DealBuyerAndSupplier.FIELDS.PRIORITY,
        'priority',
        '',
      ],
      [
        models.BUYER_AND_SUPPLIER,
        DealBuyerAndSupplier.FIELDS.SUPPLIER,
        'supplier',
        '',
      ],
      [models.PRODUCT, DealProduct.FIELDS.BRAND, 'brand', ''],
      [
        models.BUYER_AND_SUPPLIER,
        DealBuyerAndSupplier.FIELDS.PLATFORM,
        'platform',
        [PlatformEnum[1], PlatformEnum[2], PlatformEnum[3]],
      ],

      [models.PRODUCT, DealProduct.FIELDS.NAME, 'product', ''],
      [models.PRODUCT, DealProduct.FIELDS.ENABLED, 'status', false],
      [models.PRODUCT, DealProduct.FIELDS.TAXABLE_CLASS, 'taxable class', ''],
      [models.PRODUCT, DealProduct.FIELDS.SKU, 'sku$', ''],
      [models.PRODUCT, DealProduct.FIELDS.ACTIVE_FROM_DATE, 'start date', ''],
      [models.PRODUCT, DealProduct.FIELDS.ACTIVE_TO_DATE, 'end date', ''],

      [
        models.PRICE_AND_CUSTOM_OPTIONS,
        DealPriceAndCustomOptions.FIELDS.COST,
        'cost price',
        0,
      ],
      [
        models.PRICE_AND_CUSTOM_OPTIONS,
        DealPriceAndCustomOptions.FIELDS.PRICE,
        'odo sales price',
        0,
      ],
      [
        models.PRICE_AND_CUSTOM_OPTIONS,
        DealPriceAndCustomOptions.FIELDS.RETAIL,
        'retail price',
        0,
      ],
      [
        models.SHIPPING,
        DealShipping.FIELDS.ORIGINAL_STOCK,
        'original stock',
        0,
      ],
      [
        models.PRICE_AND_CUSTOM_OPTIONS,
        DealPriceAndCustomOptions.FIELDS.SURCHARGE,
        'surcharge',
        0,
      ],
      [
        models.PRICE_AND_CUSTOM_OPTIONS,
        DealPriceAndCustomOptions.FIELDS.ADMIN,
        'admin fee',
        '',
      ],
      [
        models.PRICE_AND_CUSTOM_OPTIONS,
        DealPriceAndCustomOptions.FIELDS.INSURANCE,
        'insurance',
        false,
      ],
      [
        models.PRICE_AND_CUSTOM_OPTIONS,
        DealPriceAndCustomOptions.FIELDS.REBATE,
        'rebate',
        0,
      ],

      [models.SHIPPING, DealShipping.FIELDS.LENGTH, 'shipping length', 0],
      [models.SHIPPING, DealShipping.FIELDS.WIDTH, 'shipping width', 0],
      [models.SHIPPING, DealShipping.FIELDS.HEIGHT, 'shipping height', 0],
      [models.SHIPPING, DealShipping.FIELDS.WEIGHT, 'shipping weight', 0],
      [models.SHIPPING, DealShipping.FIELDS.X_QUANTITY_LEFT, 'qty', 0],
    ];

    valueMap.forEach(mapping => {
      let result = this.getRowValue(row, mapping[2], mapping[3]);

      deal[mapping[0]].set(mapping[1], result);
    });

    if (deal.product.name) {
      const name = deal.product.name;

      // Matches phrases in parentheses like "R83.99 per bottle" or "R30 per can"
      const nameRegex = /\((R\d+[\.,]?\d* per \w+)[,\)]/i;
      const match = name.match(nameRegex);
      if (match && match[1]) {
        deal.product.pill1 = match[1];
      }
    }

    if (deal.priceAndCustomOptions.adminCost) {
      switch (deal.priceAndCustomOptions.adminCost) {
        case '0.00':
        case 0:
          deal.priceAndCustomOptions.adminCost = 'ADMINCOST_0_00';
          break;
        case '10.00':
        case 10:
          deal.priceAndCustomOptions.adminCost = 'ADMINCOST_10_00';
          break;
        case '17.50':
        case 17.5:
          deal.priceAndCustomOptions.adminCost = 'ADMINCOST_17_50';
          break;
        case '34.00':
        case 34:
          deal.priceAndCustomOptions.adminCost = 'ADMINCOST_34_00';
          break;
        default:
          deal.priceAndCustomOptions.adminCost = 'NONE';
          break;
      }
    }

    if (deal.product.taxClass) {
      const taxClass = deal.product.taxClass;
      if (taxClass.trim().match(/taxable goods/i)) {
        deal.product.taxClass = 'TAXABLE_GOODS';
      } else if (taxClass.trim().match(/shipping/i)) {
        deal.product.taxClass = 'SHIPPING';
      } else {
        deal.product.taxClass = 'NONE';
      }
    } else {
      deal.product.taxClass = 'NONE';
    }

    // If supplier is ID, map to expected name
    if (deal.buyerAndSupplier.supplier) {
      const supplierString = deal.buyerAndSupplier.supplier?.toString() || '';
      if (supplierString.match(/^\d+$/)) {
        const client = new ApolloClients().odo;
        const { data } = await client.query({
          query: GET_SUPPLIERS,
          errorPolicy: 'ignore',
          fetchPolicy: 'cache-first',
        });

        if (data?.getDropDownValues?.length > 0) {
          const supplier = data.getDropDownValues.find(
            x => x.key == deal.buyerAndSupplier.supplier
          );
          if (supplier) {
            deal.buyerAndSupplier.supplier = supplier.value;
          }
        }
      }
    }

    if (['NONE', ''].includes(deal.buyerAndSupplier.platform)) {
      deal.buyerAndSupplier.platform = [];
    } else {
      if (deal.buyerAndSupplier.platform === 'ALL') {
        deal.buyerAndSupplier.platform = [
          PlatformEnum[1],
          PlatformEnum[2],
          PlatformEnum[3],
        ];
      } else {
        deal.buyerAndSupplier.platform = deal.buyerAndSupplier.platform
          .split(',')
          .map(x => x.toUpperCase().trim());
      }
    }

    if (deal.buyerAndSupplier.priority) {
      deal.buyerAndSupplier.priority = await fetchPriorityByValue(
        deal.buyerAndSupplier.priority
      );
    }

    if (
      deal.buyerAndSupplier.buyer &&
      typeof deal.buyerAndSupplier.buyer === 'string'
    ) {
      deal.buyerAndSupplier.buyer = deal.buyerAndSupplier.buyer.toUpperCase();
    }

    if (deal.product.activeFromDate || deal.product.activeToDate) {
      if (!deal.product.activeFromDate) {
        this.errors.push(
          `End date found but start date missing on row ${rowIndex}.`
        );
      } else if (!deal.product.activeToDate) {
        this.errors.push(
          `Start date found but end date missing on row ${rowIndex}.`
        );
      } else {
        const startDate = new Date(deal.product.activeFromDate);
        const endDate = new Date(deal.product.activeToDate);
        if (endDate < startDate) {
          this.errors.push(
            `End Date is set to before Start Date on row ${rowIndex}`
          );
        }
      }
    } else {
      deal.product.activeFromDate = getTomorrowsDate();
      deal.product.activeToDate = getTomorrowsDate();
    }

    // Set category IDs if present
    const shopId = this.getRowValue(row, 'shop', '');
    const tmpCategories = this.getRowValue(row, 'default category', '');
    if (tmpCategories) {
      const categoryIds = tmpCategories.toString().split(/[,\.]/);
      if (categoryIds.length > 0) {
        deal.conditionsAndCategory.category = categoryIds;
        if (shopId) {
          deal.conditionsAndCategory.category = [
            ...deal.conditionsAndCategory.category,
            shopId,
          ];
        }
      }
    }

    return deal;
  }

  _getOptionPairs(row) {
    const nameIndices = [];
    const valueIndices = [];
    this.headings.forEach((heading, index) => {
      if (heading.match(/option name/i)) {
        nameIndices.push(index);
      }
      if (heading.match(/option value/i)) {
        valueIndices.push(index);
      }
    });

    return nameIndices
      .map((nameIndex, index) => ({
        name: row[nameIndex],
        value: row[valueIndices[index]],
      }))
      .filter(x => x.name || x.value);
  }

  _buildCustomOptions(rows, baseDeal) {
    console.debug(
      `[SpreadsheetLoader:_buildCustomOptions] Importing ${rows.length} options for product ${baseDeal.product.name}.`
    );
    const resultOptions = [];
    let internalIdCounter = 0;

    const createTopLevelOption = root => {
      return {
        type: root.length > 1 ? 1 : 0,
        options: null,
        title: root[0].name,
        optionTitle: root[0].name,
        internalId: internalIdCounter++,
        sku: '',
      };
    };

    const createSubOption = (root, row) => {
      const baseValue = {
        type: root.length > 2 ? 1 : 0,
        options: null,
        optionTitle: root[0].value,
        title: root.length > 1 ? root[1].name : root[0].value,
        internalId: internalIdCounter++,
        sku: '',
      };

      if (root.length === 1) {
        // This is a Leaf node - get values from row and populate
        baseValue.qty = this.getRowValue(row, 'qty', 0);
        baseValue.sku = this.getRowValue(row, 'sku', root[0].value);
        baseValue.cost = this.getRowValue(row, 'cost price', 0);
        baseValue.price =
          this.getRowValue(row, 'odo sales price', 0) -
          baseDeal.priceAndCustomOptions.price;
      }

      return baseValue;
    };

    for (const row of rows) {
      const options = this._getOptionPairs(row.data);

      // Create initial top level option
      let existingTopLevel = resultOptions.findIndex(
        x => x.optionTitle === options[0].name
      );
      if (existingTopLevel === -1) {
        resultOptions.push(createTopLevelOption(options));
        existingTopLevel = resultOptions.length - 1;
      }

      // Build sub-options and values.
      const buildOption = (root = options, resultRoot = resultOptions) => {
        let existingIndex = -1;

        if (root.length > 1)
          existingIndex = resultRoot.findIndex(
            x => x.title === root[1].name && x.optionTitle === root[0].value
          );

        if (existingIndex >= 0) {
          if (root.length > 1) {
            if (!resultRoot[existingIndex].options) {
              resultRoot[existingIndex].options = [];
            }
            buildOption(root.splice(1), resultRoot[existingIndex].options);
          }
        } else {
          resultRoot.push(createSubOption(root, row.data));
          if (root.length > 1) {
            if (!resultRoot[resultRoot.length - 1].options) {
              resultRoot[resultRoot.length - 1].options = [];
            }
            buildOption(
              root.splice(1),
              resultRoot[resultRoot.length - 1].options
            );
          }
        }
      };

      if (options.length > 0) {
        if (!resultOptions[existingTopLevel].options) {
          resultOptions[existingTopLevel].options = [];
        }
      }
      buildOption(options, resultOptions[existingTopLevel].options);
    }

    return resultOptions;
  }

  async _build() {
    return new Promise(async resolve => {
      let deals = [];
      let products = [];

      for (let i = 0; i < this.data.length; i++) {
        const row = this.data[i];
        const id = this.getRowValue(row, 'id');
        if (!id) {
          this.errors.push(`Missing ID for row ${i + 2}.`);
          continue;
        }
        if (!products[id]) products[id] = [];
        if (row) {
          products[id].push({ data: row, index: i + 2 });
        }
      }

      for (const product of products) {
        if (!product) continue;
        const row = product[0].data;
        let deal = new Deal();

        deal = await this._loadDeal(row, deal, product[0].index);
        deal.customOptions = this._buildCustomOptions(product.slice(1), deal);
        if (
          deal.customOptions?.filter(x => x.title || x.optionTitle).length > 0
        ) {
          deal.priceAndCustomOptions.hasCustomOptions = 'yes';
        }

        deals.push(deal);
      }

      resolve(deals);
    });
  }
}
