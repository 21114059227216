import { cssColor } from '@odo/utils/css-color';
import { Flex } from '@odo/components/elements/layout/flex';
import Button from '@odo/components/elements/button';
import { NavItem } from '@odo/components/layouts/app-container';
import { useEffect, useState } from 'react';
import {
  FaCopy as IconDuplicate,
  FaCalculator as IconCalculator,
} from 'react-icons/fa';
import { useCurrentProduct } from '@odo/contexts/product-editor';
import type { Validity } from '@odo/screens/deal/editor/helpers';
import { getAdminUrl } from '@odo/screens/deal/editor/helpers';
import { validateProduct } from '@odo/screens/deal/editor/helpers';
import { buyerAndSupplierValidators } from '@odo/screens/deal/editor/buyer-and-supplier';
import { productValidators } from '@odo/screens/deal/editor/product';
import { conditionsAndCategoryValidators } from '@odo/screens/deal/editor/conditions-and-category';
import { priceAndCustomOptionsValidators } from '@odo/screens/deal/editor/price-and-custom-options';
import { shippingAndInventoryValidators } from '@odo/screens/deal/editor/shipping-and-inventory';
import { imagesAndVideosValidators } from '@odo/screens/deal/editor/images-and-videos';
import { summaryValidators } from '@odo/screens/deal/editor/summary';
import { Status } from '@odo/screens/deal/editor/types';
import ResetButton from './reset-button';
import StatusButton from './status-button';
import SaveButton from './save-button';

const Toolbar = () => {
  const currentProduct = useCurrentProduct();
  const [validity, setValidity] = useState<Validity | undefined>(undefined);

  /**
   * Re-run validation.
   */
  useEffect(() => {
    setValidity(
      validateProduct(currentProduct, [
        ...buyerAndSupplierValidators,
        ...productValidators,
        ...conditionsAndCategoryValidators,
        ...priceAndCustomOptionsValidators,
        ...shippingAndInventoryValidators,
        ...imagesAndVideosValidators,
        ...summaryValidators,
      ])
    );
  }, [currentProduct]);

  return (
    <Flex flexDirection="row" gap={[2, 3]}>
      {!!currentProduct?.id && (
        <>
          <NavItem justifyContent="center" height="42px" width="42px">
            <Button
              hue="grey"
              variant="flat"
              circular
              px={1}
              py={1}
              // TODO: deal duplication
              onClick={() => window.alert('Duplication feature pending...')}
            >
              <IconDuplicate size={16} color={cssColor('grey')} />
            </Button>
          </NavItem>

          <a
            href={getAdminUrl({
              dest: 'priceCalculator',
              productId: currentProduct.id,
            })}
            target="_blank"
            rel="noreferrer"
          >
            <NavItem justifyContent="center" height="42px" width="42px">
              <Button hue="grey" variant="flat" circular px={1} py={1}>
                <IconCalculator size={16} color={cssColor('grey')} />
              </Button>
            </NavItem>
          </a>
        </>
      )}

      <NavItem justifyContent="center" height="42px">
        <ResetButton />
        <SaveButton status={validity?.status} />
      </NavItem>

      <NavItem
        px={0}
        py={0}
        height="42px"
        width={validity?.status === Status.valid ? '42px' : undefined}
        justifyContent={
          validity?.status === Status.valid ? 'center' : 'space-between'
        }
      >
        <StatusButton validity={validity} />
      </NavItem>
    </Flex>
  );
};

export default Toolbar;
