// Safer useEffect - keeps track of component's mounted status

import { useEffect } from 'react';

export const useSafeEffect = (callback, dependencies) => {
  useEffect(() => {
    let isMounted = true;
    let unmountCallback = null;
    if (isMounted) {
      unmountCallback = callback(isMounted);
    }

    return () => {
      isMounted = false;
      if (unmountCallback) {
        unmountCallback();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};
