import { ValidationModel } from './base/ValidationModel.jsx';

export const DefaultSizeChartImage = {
  filePath: '',
  url: '',
  delete: false,
  fileContents: '',
  fileMimeType: '',
  fileName: '',
  fileLabel: '',
};

export const SizeChartValidators = {
  partial: {},
  complete: {
    measurement: {
      validator: x => {
        let isValid = false;
        const isSet = x.desktop || x.tablet || x.mobile || x.measurement;
        if (x.measurement && isSet) {
          isValid = x.measurement > 0;
        }
        return isValid;
      },
      error: 'Please select the measurement to use',
    },
  },
};

export const SizeChartFields = {
  id: null,
  recommendation: null,
  measurement: null,
  delete: false,
  desktop: DefaultSizeChartImage,
  tablet: DefaultSizeChartImage,
  mobile: DefaultSizeChartImage,
  smDesktopImage: DefaultSizeChartImage,
  smTabletImage: DefaultSizeChartImage,
  smMobileImage: DefaultSizeChartImage,
  desktopDelete: false,
  tabletDelete: false,
  mobileDelete: false,
};

export class DealSizeChart extends ValidationModel {
  constructor() {
    super(SizeChartFields, SizeChartValidators);
    this.id;
    this.recommendation;
    this.measurement;
    this.desktop;
    this.tablet;
    this.mobile;
    this.smDesktopImage;
    this.smTabletImage;
    this.smMobileImage;
    this.desktopDelete;
    this.tabletDelete;
    this.mobileDelete;
  }
}

DealSizeChart.FIELDS = {
  ID: 'id',
  RECOMMENDATION: 'recommendation',
  MEASUREMENT: 'measurement',
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  MOBILE: 'mobile',
  SM_DESKTOP_IMAGE: 'smDesktopImage',
  SM_TABLET_IMAGE: 'smTabletImage',
  SM_MOBILE_IMAGE: 'smMobileImage',
  DELETE: 'delete',
  DESKTOP_DELETE: 'desktopDelete',
  TABLET_DELETE: 'tabletDelete',
  MOBILE_DELETE: 'mobileDelete',
};
