import { createContext } from 'react';
import { DataSource } from '@rps/js-utils/datasource';
import { DataSource as ODODataSource } from '@odo/services/data-source';
import { Deal } from '../models/Deal.jsx';

// Initial DataSource used by Provider
export const DataSources = {
  // Keeps track of the current logged in user
  user: new ODODataSource(
    {
      logged_in: false,
      name: '',
      busy: false,
    },
    { key: 'user', provider: 'localStorage' }
  ),
  // List of recent cached deals belonging to the current user
  userDeals: new DataSource({
    deals: [],
  }),
  // Current deal for editing
  deal: new DataSource({ deal: new Deal(), dealList: [] }),
  deals: new DataSource({ deals: [] }),
  historyOfChanges: new DataSource(
    { current: {} },
    { key: 'historyOfChange', provider: 'memory' }
  ),
};

// Context and provider exports
export const DataSourceContext = createContext({ ...DataSources });
export const DataSourceProvider = DataSourceContext.Provider;
