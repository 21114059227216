/**
 * Data Structure for representing a complex object as a normalized array, with
 * basic insert/delete operations.
 *
 * @export
 * @class NormalizedObject
 */
export class NormalizedTree {
  constructor(copyFrom) {
    this.nodes = {};
    this.delimiter = '---';

    if (copyFrom) {
      if (copyFrom instanceof Array) {
        this.nodes = {};
        copyFrom
          .map(src => ({ key: src.key, data: src }))
          .forEach(n => (this.nodes[n.key] = n.data));
      } else if (copyFrom instanceof NormalizedTree) {
        // Copy constructor
        this.nodes = { ...copyFrom.nodes };
      } else if (typeof copyFrom === 'object') {
        return NormalizedTree.fromObject(copyFrom);
      }
    }
  }

  /**
   * Takes any JavaScript object and converts it into a normalized array.
   * The process is reversible with the toObject() method (@see NormalizedObject~toObject )
   *
   * @static
   * @param {Object} obj - the object to transform
   * @param {Function} [idFunction=undefined] - optional callback to call for each node to return custom IDs. Not needed in 99% of cases.
   * @return {NormalizedObject}
   * @memberof NormalizedObject
   */
  static fromObject(obj, idFunction = undefined) {
    const newObject = new NormalizedTree();

    const buildMapFromObject = (obj, keyRoot = '') => {
      Object.keys(obj).forEach(key => {
        let id = idFunction ? idFunction(obj[key]) : key;
        let nodeKey = keyRoot ? [keyRoot, id].join(newObject.delimiter) : id;

        if (typeof obj[key] === 'object') {
          // Special handling for objects and arrays
          if (obj[key] instanceof Array) {
            // Arrays get directly mapped to their key
            //newObject.nodes.push({ key: nodeKey, data: [...obj[key]] });
            newObject.nodes[nodeKey] = [...obj[key]];
          } else {
            // Objects get assigned a value of 'undefined' and this function gets called
            // recursively to process child elements.
            //newObject.nodes.push({ key: nodeKey, data: undefined });
            newObject.nodes[nodeKey] = undefined;
            buildMapFromObject(obj[key], nodeKey);
          }
        } else if (typeof obj[key] === 'function') {
          // Skip functions
        } else {
          // Scalar/primitive value, can be assigned directly
          //newObject.nodes.push({ key: nodeKey, data: obj[key] });
          newObject.nodes[nodeKey] = obj[key];
        }
      });
    };

    buildMapFromObject(obj);

    return newObject;
  }

  _getNodeDepth(nodeKey) {
    const keyMatcher = new RegExp(this.delimiter, 'gi');
    return nodeKey.match(keyMatcher)?.length || 0;
  }

  /**
   * Convert a NormalizedObject to a normal JS Object with the correct structure.
   * @example
   * ```js
   * const input = {a: 1, b: { c: "Hello", d: [1,2,3] }};
   *
   * const normalized = NormalizedObject.fromObject(input);
   *
   * // normalized === [{key: 'a', data: 1}, {key: 'b', value: undefined}, {key: 'b---c', value: 'Hello'}, {key: 'b---d', value: [1,2,3]}]
   *
   * const output = normalized.toObject();
   *
   * // output === {a: 1, b: { c: "Hello", d: [1,2,3] }}
   * ```
   *
   * @return {Object}
   * @memberof NormalizedObject
   */
  toObject() {
    let obj = {};

    const nodeKeys = Object.keys(this.nodes).sort(
      (a, b) => this._getNodeDepth(a) - this._getNodeDepth(b)
    );

    // Get nodes for a specific 'depth' as determined by the selector.
    const getNodesBySelector = selector => {
      const matcher = new RegExp(`^${selector}`, 'g');
      return nodeKeys.filter(
        node =>
          node.match(matcher) &&
          this._getNodeDepth(node) === this._getNodeDepth(selector)
      );
    };

    /**
     * Recursive function to generate values in an object.
     *
     * @param {string} [rootSelector=''] - selector to start with.
     * @param {*} [objRoot=obj] - ref to object to modify
     */
    const processNodes = (rootSelector = '', objRoot = obj) => {
      const nodeKeys = getNodesBySelector(rootSelector);
      nodeKeys.forEach(nodeKey => {
        // Create selector for children, if any
        const childSelector = `${nodeKey}${this.delimiter}`;
        const childNodes = getNodesBySelector(childSelector);

        // This RegEx will match the trailing word of the selector.
        // i.e.: foo---bar---baz will match "baz". This -will- break
        // if an object key has a non alphanumeric character in its name.
        // Most objects will have standard JS identifiers and will be fine.
        const leafKeyMatcher = /(\w+)$/g;
        const leafKey = nodeKey.match(leafKeyMatcher)[0];

        if (childNodes.length > 0) {
          // Children exist for this node, create object and recurse
          objRoot[leafKey] = {};
          processNodes(childSelector, objRoot[leafKey]);
        } else {
          // Leaf node - set value and continue
          objRoot[leafKey] = this.nodes[nodeKey];
        }
      });
    };

    processNodes();

    return obj;
  }

  /**
   * Find single node with precise selector.
   *
   * @param {String} selector - Root selector, i.e.: 'product---name'
   * @param {String} searchAttributes - Optional regex attributes (i.e.: Pass 'i' for case insensitive match)
   * @return {*}
   * @memberof NormalizedObject
   */
  findNode(selector) {
    return this.nodes[selector];
  }

  /**
   * Returns a dynamic regex to match a single node in the list.
   *
   * @param {String} selector - selector to match against
   * @param {String} [searchAttributes=''] - regex search attributes
   * @return {RegExp}
   * @memberof NormalizedObject
   */
  matchOne(selector, searchAttributes = '') {
    return new RegExp(`^${selector}$`, searchAttributes);
  }

  /**
   * Returns a dynamic regex to match a node and all of its children in the list.
   *
   * @param {String} selector - selector to match against
   * @param {String} [searchAttributes=''] - regex search attributes
   * @return {RegExp}
   * @memberof NormalizedObject
   */
  matchMany(selector, searchAttributes = '') {
    return new RegExp(`^${selector}`, searchAttributes);
  }

  /**
   * Returns all entries that match the start of a selector.
   * Filters out the parent node, so only the children for the specified selector
   * are returned.
   *
   * @param {String} selector
   * @param {String} searchAttributes
   * @return {Array.<*>}
   * @memberof NormalizedObject
   */
  findChildren(selector, searchAttributes) {
    const childMatcher = this.matchMany(selector, searchAttributes);
    const parentMatcher = this.matchOne(selector, searchAttributes);

    const keys = Object.keys(this.nodes).filter(
      node => node.match(childMatcher) && !node.match(parentMatcher)
    );
    return keys.map(key => this.nodes[key]);
  }

  /**
   * Gets data for a specific node.
   *
   * @param {string} selector
   * @param {string} [searchAttributes='']
   * @return {*}
   * @memberof NormalizedObject
   */
  getNodeData(selector, searchAttributes = '') {
    return this.findNode(selector, searchAttributes);
  }

  /**
   * Returns the value of a parent node and an array of child values.
   *
   * @param {string} selector
   * @param {string} [searchAttributes='']
   * @return {*}
   * @memberof NormalizedObject
   */
  getNodeTree(selector, searchAttributes = '') {
    const node = this.findNode(selector, searchAttributes);
    if (node) {
      const children = this.findChildren(selector, searchAttributes);
      return { node, children };
    } else {
      return undefined;
    }
  }

  /**
   * Insert a value by selector. If it's an array, it can be spread into the
   * existing array, overwrite the existing one or be added as a single value.
   *
   * @param {string} selector
   * @param {*} value
   * @param {boolean} [spreadValue=false] - if value or the matched node is an array, spread/merge into existing array or overwrite?
   * @memberof NormalizedObject
   */
  insert(selector, value, spreadValue = false) {
    let existing = this.findNode(selector);
    if (existing) {
      if (existing instanceof Array) {
        if (value instanceof Array) {
          if (spreadValue) {
            this.nodes[selector] = [...existing, ...value];
          } else {
            this.nodes[selector] = value;
          }
        } else {
          this.nodes[selector] = [...existing, value];
        }
      } else {
        this.nodes[selector] = value;
      }
    } else {
      this.nodes[selector] = value;
    }
  }

  getTopLevelNodes(selector) {
    const targetDepth = this._getNodeDepth(selector);
    const matcher = this.matchMany(selector);
    return Object.keys(this.nodes)
      .filter(
        node => node.match(matcher) && this._getNodeDepth(node) === targetDepth
      )
      .map(key => ({ key, value: this.nodes[key] }));
  }

  getDirectChildNodes(selector) {
    const targetDepth = this._getNodeDepth(selector);
    const matcher = this.matchMany(selector);
    return Object.keys(this.nodes)
      .filter(
        node =>
          node.match(matcher) && this._getNodeDepth(node) === targetDepth + 1
      )
      .map(key => ({ key, value: this.nodes[key] }));
  }

  update(selector, value) {
    let existing = this.findNode(selector);
    if (existing) {
      this.nodes[selector] = value;
    }
  }

  updateChildren(selector, value) {
    let existing = this.getNodeTree(selector);
    if (existing && existing.children) {
      const childMatcher = this.matchMany(selector);
      const parentMatcher = this.matchOne(selector);

      const keys = Object.keys(this.nodes).filter(
        node => node.match(childMatcher) && !node.match(parentMatcher)
      );
      keys.every(key => (this.nodes[key] = { ...this.nodes[key], ...value }));
    }
  }

  /**
   * Finds the direct parent of the given selector, if any.
   * @param {*} selector
   * @returns {Node}
   */
  findParent(selector) {
    const newSelector = selector.replace(/---(\w+)$/, '');
    return this.findNode(newSelector);
  }

  toArray() {
    return Object.keys(this.nodes).map(nodeKey => ({
      key: nodeKey,
      value: this.nodes[nodeKey],
    }));
  }

  /**
   * Remove a node and all children from the list.
   *
   * @param {string} selector
   * @memberof NormalizedObject
   */
  remove(selector) {
    const matcher = this.matchMany(selector);
    Object.keys(this.nodes)
      .filter(node => node.match(matcher))
      .forEach(nodeKey => {
        delete this.nodes[nodeKey];
      });
  }

  /**
   * Descends through a tree, calling the provided callback and adding returned values together.
   * Returns total sum of values returned.
   * @param {string} selector
   * @returns accumulated value
   */
  sumTree(selector, cb, defaultValue = 0, leavesOnly) {
    const currentNode = this.getNodeTree(selector);
    if (!currentNode) {
      return 0;
    }
    const hasChildren = currentNode.children.length > 0;

    if (hasChildren) {
      const sum = root => {
        const children = this.getDirectChildNodes(root);
        const thisNode = this.getNodeData(root);

        if (children.length > 0) {
          let accumulator = defaultValue;

          children.forEach(child => {
            accumulator += sum(child.key);
          });

          if (leavesOnly === false) {
            accumulator += +cb(thisNode);
          }

          return accumulator;
        } else {
          return +cb(thisNode);
        }
      };

      return sum(selector);
    } else {
      return +cb(currentNode.node);
    }
  }
}
