import { ValidationModel } from './base/ValidationModel.jsx';
import { ImageTypeEnum } from '../constants/ODOEnums';

const ImagesAndVideosProperties = {
  images: [],
  videos: [],
  isPhotographedByStudio: false,
  duplicateImages: false,
};

const ImagesAndVideosValidators = {
  partial: {
    allTypes: {
      validator: model =>
        // don't error out if there are no images
        !model.duplicateImages ||
        model.images.length === 0 ||
        // every type should be on at least one image (image can be disabled, but not deleted)
        [
          ImageTypeEnum.IMAGE,
          ImageTypeEnum.THUMBNAIL,
          ImageTypeEnum.SMALL_IMAGE,
        ].every(type =>
          model.images.some(
            img => !img?.delete && img.imageTypes.includes(type)
          )
        ),
      error: 'Select an email, website, and thumbnail image',
    },
  },
  complete: {
    allTypes: {
      validator: model =>
        model.duplicateImages ||
        model.images.length === 0 ||
        [
          ImageTypeEnum.IMAGE,
          ImageTypeEnum.THUMBNAIL,
          ImageTypeEnum.SMALL_IMAGE,
        ].every(type =>
          model.images.some(
            img => !img?.delete && img.imageTypes.includes(type)
          )
        ),
      error: 'WARNING: Select an email, website, and thumbnail image.',
    },
    noImages: {
      validator: model => model.images.length > 0,
      error: 'WARNING: No image(s) uploaded for this deal.',
    },
  },
};

export class DealImagesAndVideos extends ValidationModel {
  constructor() {
    super(ImagesAndVideosProperties, ImagesAndVideosValidators);

    this.images;
    this.videos;
    this.isPhotographedByStudio;
  }

  hasCoverImage() {
    if (this.images.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  getCoverImageURL() {
    return this.images[0].url;
  }
}

DealImagesAndVideos.FIELDS = {
  IMAGES: 'images',
  VIDEOS: 'videos',
  IS_PHOTOGRAPHED_BY_STUDIO: 'isPhotographedByStudio',
};
