import type { ComponentType } from 'react';

export enum SORT_DIR {
  asc = 'ASC',
  desc = 'DESC',
}

export interface ColumnRendererProps<TData = any> {
  id: string;
  data: TData;
}

export type SortCallback = (args: { id: string; dir: SORT_DIR }) => void;

export interface ColumnType<TData = any> {
  id: string;
  title: string;
  width: string;
  render: ComponentType<ColumnRendererProps<TData>>;
  sort?: SortCallback;
}

export interface RowType<TData = any> {
  rowId: string;
  data: TData;
  terms?: string[];
}

export interface GridType<TData = any> {
  columns: ColumnType<TData>[];
  rows: RowType<TData>[];
}
