import { useState } from 'react';

export const useModalProvider = () => {
  const [modal, setModal] = useState(null);
  const [modalShown, setModalShown] = useState(false);
  const [toasts, setToasts] = useState([]);

  const openModal = element => {
    setModal(element);
    setModalShown(true);
  };

  const closeModal = immediate => {
    setModalShown(false);
    setTimeout(() => setModal(null), immediate ? 0 : 1000);
  };

  const pushToast = (
    message,
    classes,
    expireInSeconds = 8,
    effect = 'slide',
    position = 'topCenter'
  ) => {
    toasts.unshift({
      classes,
      message,
      expireInSeconds,
      effect,
      position,
    });
    setToasts([...toasts]);
  };

  const onToastClose = index => {
    toasts.splice(index, 1);
    setToasts([...toasts]);
  };

  return {
    modal,
    open: openModal,
    close: closeModal,
    show: modalShown,
    pushToast,
    toasts,
    closeToast: onToastClose,
  };
};
