// Check the result of a GraphQL query and determine if Auth has expired.

export const hasAuthError = queryData => {
  if (!queryData.errors) return false;
  for (let error of queryData.errors) {
    if (isAuthError(error)) {
      return true;
    }
  }

  return false;
};

export const isAuthError = err => {
  if (!err.extensions) return false;
  return [
    'UNAUTHENTICATED',
    'AUTHENTICATION_REQUIRED',
    'ACCESS_DENIED',
  ].includes(err.extensions.code);
};
