import Card from '@odo/components/elements/card';
import { Box, Flex } from '@odo/components/elements/layout';
import PackageDimensions from '@odo/screens/deal/editor/shipping-and-inventory/widgets/package-dimensions';
import PackageLegend from '@odo/screens/deal/editor/shipping-and-inventory/widgets/package-legend';
import { useState } from 'react';

const PackagePreview = ({
  width,
  height,
  length,
}: {
  width: number;
  height: number;
  length: number;
}) => {
  const [base, setBase] = useState<
    { base: number; widthInPx: number } | undefined
  >();

  return (
    <Card
      borderRadius={4}
      overflow="hidden"
      height="100%"
      mainProps={{ px: 0, py: 0, height: '100%' }}
    >
      <Box
        height="100%"
        style={{
          backgroundImage:
            'linear-gradient(45deg, hsl(206deg 14% 10% / 10%) 25%, transparent 25%), linear-gradient(135deg, hsl(206deg 14% 10% / 10%) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, hsl(206deg 14% 10% / 10%) 75%), linear-gradient(135deg, transparent 75%, hsl(206deg 14% 10% / 10%) 75%)',
          backgroundPosition: '0 0, 10px 0, 10px -10px, 0px 10px',
          backgroundSize: '20px 20px',
          position: 'relative',
        }}
      >
        <Flex alignItems="center" justifyContent="center">
          <PackageDimensions
            canvasSize={330}
            width={width}
            height={height}
            length={length}
            setBase={setBase}
            style={{ maxWidth: '100%', maxHeight: '100%' }}
          />
        </Flex>

        <PackageLegend base={base?.base} width={base?.widthInPx} />
      </Box>
    </Card>
  );
};

export default PackagePreview;
