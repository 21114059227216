/**
 * Slightly hacky in-memory cache.
 * Created for image urls in search which need(ed) to be queried individually.
 * TODO: find a better solution when time allows.
 */

// let's abuse Map for a bit
const cacheLayers = new Map();

interface CachedGetAndSet<TValue = unknown> {
  cacheName: string;
  cacheKey: string;
  setter: (cacheValue: TValue | null) => void;
  getter: (args: {
    cacheKey: string;
    setValue: (value: TValue) => void;
  }) => void;
}

export const cacheGetAndSet: <TValue>(
  args: CachedGetAndSet<TValue>
) => void = async ({ cacheName, cacheKey, setter, getter }) => {
  let cache;

  if (cacheLayers.has(cacheName)) {
    cache = cacheLayers.get(cacheName);
  } else {
    cache = new Map();
    cacheLayers.set(cacheName, cache);
  }

  if (cache.has(cacheKey)) {
    setter(cache.get(cacheKey));
  } else {
    // set to null to begin with so that we never run the getter again (just in case)
    // will be updated by getter when it's done
    cache.set(cacheKey, null);

    getter({
      cacheKey,
      setValue: value => {
        cache.set(cacheKey, value);
        setter(value);
      },
    });
  }
};

interface CachedGet {
  cacheName: string;
  cacheKey: string;
}

export const cacheGet: <TValue = unknown>(
  args: CachedGet
) => TValue | undefined = ({ cacheName, cacheKey }) => {
  if (cacheLayers.has(cacheName)) {
    const cache = cacheLayers.get(cacheName);
    if (cache.has(cacheKey)) {
      return cache.get(cacheKey);
    }
  }

  return undefined;
};
