import React, { useState, useEffect } from 'react';
import { ODOForgotPasswordURL } from '../../config';
import { RPSCard, RPSToast } from '@rps/web-components/build/react-wrappers';
import { useLoginController } from './useLoginController';
import { iconNames } from '@rps/web-components/build/web-components';
import { useModalProvider } from './../../hooks/useModalProvider';
import styles from './Login.module.css';
import Button from '@odo/components/elements/button/old';
import Divider from '@odo/components/elements/divider';
import LabelledInput from '@odo/components/elements/labelled-input';
import metadata from '../../buildData.json';
import { version } from '../../config';

/**
 * Login screen component.
 */
const Login = () => {
  const controller = useLoginController();
  const modal = useModalProvider();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const loginHandler = async e => {
    e.preventDefault();

    if (!email || !password) return;

    controller.setMessage('');
    await controller.login(email, password);
  };

  // The "forgot" action opens the "forgot password" URL on Magento (Specified in the relevant .env file)
  const forgotHandler = () => {
    window.open(ODOForgotPasswordURL, '_blank');
  };

  // The "register" action opens the Magento popup for first-time auth.
  const registerHandler = async () => {
    controller.setMessage('');
    await controller.register();
  };

  // On-load useEffect that checks if any auth errors (i.e.: Session timeouts) were pushed localStorage
  // and displays them as a toast.
  useEffect(() => {
    const authError = localStorage.getItem('authError');
    if (authError) {
      localStorage.removeItem('authError');
      modal.pushToast(authError, 'warning', 10);
    }
  }, [modal]);

  return (
    <div className={styles.loginContainer}>
      <RPSCard css=":host { width: 48rem; }">
        <div slot="header" className={styles.loginHeader}>
          <rps-svg class="large" svg={iconNames.odo.blueLogo} />
        </div>

        {controller.message && (
          <rps-input-label text={controller.message} class="warning" />
        )}

        {controller.busy ? (
          <rps-spinner-pause />
        ) : (
          <form action="#" onSubmit={loginHandler}>
            <div className={styles.loginFields}>
              <LabelledInput
                label="Email"
                type="text"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />

              <LabelledInput
                label="Password"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />

              <a href="#" onClick={forgotHandler}>
                Forgot password?
              </a>

              <Button
                size="full"
                type="submit"
                primary
                disabled={!email || !password}
                onClick={loginHandler}
              >
                LOG IN
              </Button>

              <Divider text="OR" />

              <Button size="full" outlined onClick={registerHandler}>
                REGISTER
              </Button>

              <span
                style={{
                  color: 'var(--text-tertiary)',
                  lineHeight: '1.6rem',
                  fontSize: '1.2rem',
                  textAlign: 'right',
                }}
              >
                {version} - {metadata.majorVersion}.{metadata.minorVersion}.
                {metadata.bugfix}/c{metadata.commitRevision}
              </span>
            </div>
          </form>
        )}
      </RPSCard>

      {/* Renders toasts (Special case for this context which is "outside" the main app container which usually handles this) */}
      <div style={{ isolation: 'isolate' }}>
        {modal.toasts.length > 0 && (
          <RPSToast
            popup
            opened
            className={modal.toasts[0].classes}
            text={modal.toasts[0].message}
            cbClose={() => modal.closeToast()}
            cbExpire={() => modal.closeToast()}
            expireseconds={modal.toasts[0].expireInSeconds}
            effect={modal.toasts[0].effect}
            position={modal.toasts[0].position}
          />
        )}
      </div>
    </div>
  );
};

export default Login;
