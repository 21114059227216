import { useCallback, useState } from 'react';
import { Deal } from 'models/Deal';
import { loadRpsDeal } from '@odo/helpers/rps-deals';

/**
 * Trying to create a sane abstraction of the "current" deal source context/hook.
 * TODO: this is only used for duplication via the RedPanda context. Remove it once we have our own duplication.
 */
export const useDealModel = () => {
  const [dealModel, setDealModel] = useState<
    Record<string, unknown> | undefined
  >(undefined);

  const loadFullDealModel = useCallback((dealId: string) => {
    setDealModel(undefined);

    (async (dealId: string) => {
      const dealModel = new Deal();
      await loadRpsDeal({ id: dealId, dealModel });
      setDealModel(dealModel);
    })(dealId);
  }, []);

  return { dealModel, setDealModel, loadFullDealModel };
};
