import type { HTMLAttributes, ReactNode } from 'react';
import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import DialogContext from './context';
import type { LayoutProps } from '@odo/lib/styled';
import styled, { compose, layout } from '@odo/lib/styled';
import { isHTMLElement } from '@odo/types/guards';
import type { DialogType } from './types';
import { focusable, tabbable } from '@odo/utils/tabbable';

interface NavigatorUAData {
  brands: Array<{ brand: string; version: string }>;
  mobile: boolean;
  platform: string;
}

type NavigatorWithUAData = Navigator & {
  userAgentData: NavigatorUAData;
};

type WindowNavigator = Navigator | NavigatorWithUAData;

const isNavigatorUserAgent = (
  navigator: WindowNavigator
): navigator is NavigatorWithUAData =>
  typeof (navigator as NavigatorWithUAData).userAgentData !== 'undefined';

/**
 * These APIs are deprecated and/or not super trustworthy.
 * But there's no way to detect lack of support for `overflow: hidden` on document.body
 * So we're gonna rely on these for now.
 * If they do fail, the impacts are not so drastic as to be breaking.
 */
const getPlatform = () =>
  isNavigatorUserAgent(window.navigator)
    ? window.navigator.userAgentData?.platform
    : navigator.platform;

const DialogBackdrop = styled.div`
  position: fixed;
  z-index: 75;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsl(226deg 25% 12% / 30%);
  backdrop-filter: blur(2px);
  display: grid;
  place-content: center;

  transition: opacity 75ms ease, backdrop-filter 75ms ease;

  opacity: 0;
  pointer-events: none;

  &.active {
    opacity: 1;
    pointer-events: unset;
  }
`;

type DialogContentWrapperProps = LayoutProps;

const DialogContentWrapper = styled.div<DialogContentWrapperProps>`
  max-width: calc(100vw - 20px);
  /* NOTE: we have two max-height declarations */
  /* the latter uses dynamic viewport height which is better for mobile devices but isn't fully supported */
  /* so the former will be used as a fallback when the latter fails */
  max-height: calc(90vh - 20px);
  max-height: calc(100dvh - 20px);

  /* our enter/exit animations */
  transition: transform 100ms ease, opacity 75ms ease;
  @media (prefers-reduced-motion) {
    transition: unset;
  }

  opacity: 0;
  transform: translateY(50px);

  &.active {
    opacity: 1;
    transform: translateY(0px);
  }

  ${compose(layout)}
`;

DialogContentWrapper.defaultProps = {
  overflow: 'auto',
};

const HIDDEN_STYLES: React.CSSProperties = {
  border: 0,
  clip: 'rect(0 0 0 0)',
  height: '1px',
  margin: '-1px',
  overflow: 'hidden',
  padding: 0,
  position: 'fixed',
  whiteSpace: 'nowrap',
  width: '1px',
  top: 0,
  left: 0,
};

let rafId = 0;
const enqueueFocus = (el: HTMLElement) => {
  window.cancelAnimationFrame(rafId);
  rafId = window.requestAnimationFrame(() => el.focus());
};

const FocusGuard = (props: HTMLAttributes<HTMLSpanElement>) => {
  const [role, setRole] = useState<'button' | undefined>();

  useEffect(() => {
    // NOTE: not sure about this user agent check, the original was an apple vendor check
    if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
      setRole('button');
    }
  }, []);

  return (
    <span
      tabIndex={0}
      role={role}
      aria-hidden={role ? undefined : true}
      style={HIDDEN_STYLES}
      {...props}
    />
  );
};

const DialogInner = ({ dialog }: { dialog: DialogType }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const lastActiveElement = useRef<HTMLElement | undefined>();
  const [internalOpen, setInternalOpen] = useState(false);

  /**
   * Focus first focusable element on open.
   * NOTE: uses internal open as it must wait for the content to be rendered.
   */
  useEffect(() => {
    if (!dialog.autoFocus || !internalOpen || !contentRef.current) return;
    const [first] = focusable(contentRef.current);
    if (first) enqueueFocus(first);
  }, [internalOpen, dialog.autoFocus]);

  /**
   * Return focus to previously focused element on close.
   * NOTE: uses internal open as it must wait for the content to be removed.
   */
  useEffect(() => {
    if (internalOpen) return;

    if (lastActiveElement.current) {
      enqueueFocus(lastActiveElement.current);
      lastActiveElement.current = undefined;
    }
  }, [internalOpen]);

  /**
   * Show/hide state toggle and exit animation.
   */
  useEffect(() => {
    if (internalOpen === dialog.isOpen) return;

    if (dialog.isOpen) {
      // capture currently focused element
      if (document.activeElement && isHTMLElement(document.activeElement)) {
        lastActiveElement.current = document.activeElement;
      }

      // render content
      setInternalOpen(true);
    } else {
      // exit animation
      if (wrapperRef.current) {
        const wrapper = wrapperRef.current;
        wrapper.classList.remove('active');
      }

      // wait for transition to finish, then remove
      const timeoutId = setTimeout(() => setInternalOpen(false), 100);

      return () => clearTimeout(timeoutId);
    }
  }, [internalOpen, dialog.isOpen]);

  /**
   * Enter animation.
   * NOTE: We want to wait for the DOM node to be rendered before trying to animate it.
   */
  useEffect(() => {
    if (!internalOpen || !wrapperRef.current) return;
    const wrapper = wrapperRef.current;
    wrapper.classList.add('active');
  }, [internalOpen]);

  /**
   * Close on escape keyup.
   *
   * NOTE: keypress event is deprecated.
   * but still technically works for everything but the escape key.
   */
  useEffect(() => {
    if (!dialog.isOpen) return;

    const escapeClose = (e: KeyboardEvent) =>
      e.key === 'Escape' && dialog.close();

    document.addEventListener('keyup', escapeClose);
    return () => document.removeEventListener('keyup', escapeClose);
  }, [dialog]);

  return (
    <>
      {internalOpen && (
        <DialogContentWrapper
          ref={wrapperRef}
          role="dialog"
          tabIndex={-1}
          aria-labelledby={dialog.labelledById}
          aria-describedby={dialog.describedById}
          aria-modal="true"
        >
          {/* focus guard: wraps shift-tab onto first element back down to the last */}
          <FocusGuard
            onFocus={() => {
              if (!contentRef.current) return;
              const [last] = [...tabbable(contentRef.current)].reverse();
              if (last) enqueueFocus(last);
            }}
          />

          {/* render the main content of our dialog */}
          <div ref={contentRef}>{dialog.render()}</div>

          {/* focus guard: wraps tab onto last element back up to the first */}
          <FocusGuard
            onFocus={() => {
              if (!contentRef.current) return;
              const [first] = tabbable(contentRef.current);
              if (first) enqueueFocus(first);
            }}
          />
        </DialogContentWrapper>
      )}
    </>
  );
};

const DialogPortal = ({
  dialogs,
  hasOpenDialog,
}: {
  dialogs: DialogType[];
  hasOpenDialog: boolean;
}) => {
  const backdropRef = useRef<HTMLDivElement>(null);
  const dialogInnerRef = useRef<HTMLDivElement>(null);

  /**
   * Disable scroll.
   *
   * Stolen from Floating UIs FloatingOverlay element.
   * Main difference being that we only ever render ONE overlay, so we don't need to check for locks.
   * @see https://github.com/floating-ui/floating-ui/blob/master/packages/react/src/components/FloatingOverlay.tsx
   */
  useEffect(() => {
    if (!hasOpenDialog) return;

    const isIOS = /iP(hone|ad|od)|iOS/.test(getPlatform());
    const bodyStyle = document.body.style;
    // RTL <body> scrollbar
    const scrollbarX =
      Math.round(document.documentElement.getBoundingClientRect().left) +
      document.documentElement.scrollLeft;
    const paddingProp = scrollbarX ? 'paddingLeft' : 'paddingRight';
    const scrollbarWidth =
      window.innerWidth - document.documentElement.clientWidth;
    const scrollX = bodyStyle.left
      ? parseFloat(bodyStyle.left)
      : window.scrollX;
    const scrollY = bodyStyle.top ? parseFloat(bodyStyle.top) : window.scrollY;

    bodyStyle.overflow = 'hidden';

    if (scrollbarWidth) {
      bodyStyle[paddingProp] = `${scrollbarWidth}px`;
    }

    // Only iOS doesn't respect `overflow: hidden` on document.body, and this
    // technique has fewer side effects.
    if (isIOS) {
      // iOS 12 does not support `visualViewport`.
      const offsetLeft = window.visualViewport?.offsetLeft || 0;
      const offsetTop = window.visualViewport?.offsetTop || 0;

      Object.assign(bodyStyle, {
        position: 'fixed',
        top: `${-(scrollY - Math.floor(offsetTop))}px`,
        left: `${-(scrollX - Math.floor(offsetLeft))}px`,
        right: '0',
      });
    }

    return () => {
      Object.assign(bodyStyle, {
        overflow: '',
        [paddingProp]: '',
      });

      if (isIOS) {
        Object.assign(bodyStyle, {
          position: '',
          top: '',
          left: '',
          right: '',
        });
        window.scrollTo(scrollX, scrollY);
      }
    };
  }, [hasOpenDialog]);

  /**
   * Close on backdrop click
   */
  useEffect(() => {
    if (!hasOpenDialog || !backdropRef.current || !dialogInnerRef.current) {
      return;
    }

    const outsideClickClose = (e: MouseEvent | TouchEvent) => {
      if (
        dialogInnerRef.current &&
        e.target instanceof Element &&
        !dialogInnerRef.current.contains(e.target)
      ) {
        dialogs.forEach(dialog => dialog.isOpen && dialog.close());
      }
    };

    const backdrop = backdropRef.current;
    backdrop.addEventListener('mousedown', outsideClickClose);
    backdrop.addEventListener('touchstart', outsideClickClose);

    return () => {
      backdrop.removeEventListener('mousedown', outsideClickClose);
      backdrop.removeEventListener('touchstart', outsideClickClose);
    };
  }, [hasOpenDialog, dialogs]);

  return (
    <DialogBackdrop
      ref={backdropRef}
      className={hasOpenDialog ? 'active' : undefined}
    >
      <div ref={dialogInnerRef}>
        {dialogs.map(dialog => (
          <DialogInner key={dialog.id} dialog={dialog} />
        ))}
      </div>
    </DialogBackdrop>
  );
};

// I suspect the typescript version needs to be updated to support inert
// TODO: remove this unnecessary abstraction once the inert attribute is available natively
const InertableDiv = styled.div<{ inert?: string }>``;

const DialogProvider = ({ children }: { children: ReactNode }) => {
  const [dialogs, setDialogs] = useState<DialogType[]>([]);

  const setDialog = useCallback(
    (dialog: DialogType) =>
      setDialogs(dialogs => [
        ...dialogs.filter(({ id }) => id !== dialog.id),
        dialog,
      ]),
    []
  );

  const removeDialog = useCallback(
    (dialogId: DialogType['id']) =>
      setDialogs(dialogs => dialogs.filter(({ id }) => id !== dialogId)),
    []
  );

  const hasOpenDialog = useMemo(
    () => dialogs.some(({ isOpen }) => isOpen),
    [dialogs]
  );

  const value = useMemo(
    () => ({ setDialog, removeDialog }),
    [setDialog, removeDialog]
  );

  return (
    <DialogContext.Provider value={value}>
      {/* render the main content of the page inert while a dialog is open */}
      <InertableDiv inert={hasOpenDialog ? 'true' : undefined}>
        {children}
      </InertableDiv>

      <DialogPortal dialogs={dialogs} hasOpenDialog={hasOpenDialog} />
    </DialogContext.Provider>
  );
};

export default DialogProvider;
