import {
  FaUndo as IconUndo,
  FaRedo as IconRedo,
  FaCheckDouble as IconAutoSumOn,
  FaBan as IconAutoSumOff,
} from 'react-icons/fa';
import {
  BsStack as IconRowCollapsed,
  BsPlusLg as IconAdd,
  BsXLg as IconDelete,
} from 'react-icons/bs';
import { RiMenuFoldLine as IconRowExpanded } from 'react-icons/ri';
import {
  BiChevronUp as IconMoveUp,
  BiChevronDown as IconMoveDown,
} from 'react-icons/bi';
import {
  MdOutlineContentCopy as IconCopy,
  MdDriveFileMove as IconPaste,
} from 'react-icons/md';
import { ReactComponent as IconAddOption } from './add-option.svg';
import Button from '@odo/components/elements/button/button';
import type {
  CustomOptionTree,
  CustomOptionTreeValue,
} from '@odo/types/portal';
import type { ReactNode } from 'react';
import { useEffect, useCallback, useMemo, useRef, useState } from 'react';
import { Input, InputCurrency } from '@odo/components/elements/form-fields';
import {
  ActionTypeEnum,
  useAddActionDebounced,
  useAutoSumEnabled,
  useCopyPaste,
  useCustomOptionsEditorContext,
  useShowErrors,
} from '@odo/contexts/custom-options-editor';
import type {
  ApiCustomOption,
  ApiCustomOptionValue,
  ApiProduct,
} from '@odo/types/api';
import { InputTypeEnum } from '@odo/types/api';
import { useToggle } from '@odo/hooks/general';
import {
  getOptionFieldError,
  getValueFieldError,
  calcValueQty,
  calcOptionQty,
} from '@odo/data/custom-options/utils';
import uuid from '@odo/utils/uuid';
import {
  ToolbarWrapper,
  ToolbarPart,
  ToolbarSeparator,
  OptionsWrapper,
  RowInner,
  TierGraphWrapper,
  TierGraphItem,
  TierGraphInfoWrapper,
  TierGraphInfoLabel,
  TierGraphInfoNumber,
  ValueAndActionsWrapper,
  SortActionsWrapper,
  InputWrapper,
  ActionsWrapper,
  ChildWrapper,
  SmallHeading,
  ToggleCollapseWrapper,
  SlowSwing,
  Hidable,
} from './styles';
import { cssColor } from '@odo/utils/css-color';

const TITLE_WIDTH = '250px';

const COLORS: {
  base: string;
  text: string;
  bg: string;
}[] = [
  // green
  {
    base: 'hsl(71deg 68% 51%)',
    text: 'hsl(71deg 68% 51%)',
    bg: 'hsl(71deg 68% 93%)',
  },
  // violet
  {
    base: 'hsl(318deg 74% 62%)',
    text: 'hsl(318deg 74% 62%)',
    bg: 'hsl(318deg 53% 94%)',
  },
  // yellow
  {
    base: 'hsl(43deg 100% 57%)',
    text: 'hsl(43deg 100% 57%)',
    bg: 'hsl(43deg 71% 91%)',
  },
  // blue
  {
    base: 'hsl(198deg 100% 41%)',
    text: 'hsl(198deg 100% 41%)',
    bg: 'hsl(198deg 59% 94%)',
  },
  // hot-pink/red
  // {
  //   base: 'hsl(337deg 93% 64%)',
  //   text: 'hsl(337deg 93% 64%)',
  //   bg: 'hsl(337deg 56% 93%)',
  // },
];

/**
 * Helper functions.
 */
const getColor = (idx: number) => COLORS[idx % COLORS.length];

// allows: '' & '350.' to give users the option to type in new or decimal values
const currencyMask = (value: string) =>
  value === '' || !!value.match(/^(\d*)(\.\d{0,2})?$/);

const intMask = (value: string) => value === '' || !!value.match(/^-?(\d*)$/);

const floatToString = (value: number | null | undefined) =>
  typeof value !== 'undefined' && value !== null ? value.toString() : '';

const calcMaxTier = (options: CustomOptionTree[]) => {
  let tier = 0;

  const recursive = (option: CustomOptionTree, internalTier: number) => {
    if (tier < internalTier) {
      tier = internalTier;
    }
    option.values.forEach(value =>
      value.childOptions.forEach(childOption =>
        recursive(childOption, internalTier + 1)
      )
    );
  };

  options.forEach(option => recursive(option, 1));

  return tier;
};

type SetCumulativeQuantityInternal = (valueQtyLatest?: {
  id: string;
  qty: number;
}) => void;

/**
 * Components.
 */
const Row = ({ children }: { children: ReactNode }) => (
  <RowInner>{children}</RowInner>
);

const InputRow = ({ children }: { children: ReactNode }) => (
  <InputWrapper>{children}</InputWrapper>
);

const TierGraph = ({ tier, maxTier }: { tier: number; maxTier: number }) => (
  <TierGraphWrapper>
    <>
      {new Array(maxTier).fill(null).map((_, idx) => (
        <TierGraphItem
          key={idx}
          borderColor={tier - 1 === idx ? getColor(idx).base : 'transparent'}
          backgroundColor={idx < tier - 1 ? cssColor('foreground') : undefined}
        />
      ))}

      <TierGraphInfoWrapper color={getColor(tier - 1).text}>
        <TierGraphInfoLabel>tier</TierGraphInfoLabel>
        <TierGraphInfoNumber>{tier}</TierGraphInfoNumber>
      </TierGraphInfoWrapper>
    </>
  </TierGraphWrapper>
);

const ToggleCollapse = ({
  disabled,
  tier,
  isCollapsed,
  toggle,
}: {
  disabled?: boolean;
  tier: number;
  isCollapsed: boolean;
  toggle: () => void;
}) => (
  <ToggleCollapseWrapper className={disabled ? 'disabled' : undefined}>
    <Button
      size="none"
      padding="none"
      fontSize="large"
      btnType="plain"
      color={!disabled ? getColor(tier - 1).text : undefined}
      onClick={toggle}
      disabled={disabled}
    >
      {isCollapsed ? (
        <IconRowCollapsed width={24} height={24} />
      ) : (
        <IconRowExpanded width={24} height={24} />
      )}
    </Button>
  </ToggleCollapseWrapper>
);

const SortActions = ({
  up,
  down,
  canMoveUp,
  canMoveDown,
}: {
  up: () => void;
  down: () => void;
  canMoveUp: boolean;
  canMoveDown: boolean;
}) => (
  <SortActionsWrapper>
    <Button
      size="none"
      btnType="plain"
      onClick={() => up()}
      disabled={!canMoveUp}
    >
      <IconMoveUp />
    </Button>
    <Button
      size="none"
      btnType="plain"
      onClick={() => down()}
      disabled={!canMoveDown}
    >
      <IconMoveDown />
    </Button>
  </SortActionsWrapper>
);

const DropdownValue = ({
  value,
  parentSellingPrice,
  optionId,
  productId,
  parentValueId,
  swapUpWith,
  swapDownWith,
  tier,
  maxTier,
  disableRemove,
  setCumulativeQuantity,
}: {
  value: CustomOptionTreeValue;
  parentSellingPrice: number;
  optionId: ApiCustomOption['id'];
  productId: ApiProduct['id'];
  parentValueId?: ApiCustomOptionValue['valueId'];
  swapUpWith?: ApiCustomOptionValue['valueId'];
  swapDownWith?: ApiCustomOptionValue['valueId'];
  tier: number;
  maxTier: number;
  disableRemove?: boolean;
  setCumulativeQuantity: SetCumulativeQuantityInternal;
}) => {
  const addAction = useAddActionDebounced();
  const showErrors = useShowErrors();
  const autoSumEnabled = useAutoSumEnabled();
  const { copyingOptionId, pasteOption } = useCopyPaste();

  const [collapseChildren, toggleCollapsed] = useToggle();

  const [titleValue, setTitleValue] = useState(value.title);
  const [skuValue, setSkuValue] = useState(value.sku);

  const [priceValue, setPriceValue] = useState(floatToString(value.price));
  const [salePriceValue, setSalePriceValue] = useState(
    floatToString(parentSellingPrice + (value?.price || 0))
  );
  const [costValue, setCostValue] = useState(floatToString(value.cost));
  const [qtyValue, setQtyValue] = useState(value.quantity.string);

  const hasChildren = value.childOptions.length > 0;

  const autoSumEnabledInternal = hasChildren && autoSumEnabled;

  const sellingPrice =
    parentSellingPrice +
    (!isNaN(parseFloat(priceValue)) ? parseFloat(priceValue) : 0);

  useEffect(() => {
    setTitleValue(value.title);
  }, [value.title]);

  useEffect(() => {
    setSkuValue(value.sku || '');
  }, [value.sku]);

  useEffect(() => {
    setPriceValue(floatToString(value.price));
  }, [value.price]);

  useEffect(() => {
    setSalePriceValue(floatToString(parentSellingPrice + (value?.price || 0)));
  }, [parentSellingPrice, value.price]);

  useEffect(() => {
    setCostValue(floatToString(value.cost));
  }, [value.cost]);

  useEffect(() => {
    setQtyValue(value.quantity.string);
  }, [value.quantity]);

  return (
    <>
      <Row>
        <TierGraph tier={tier} maxTier={maxTier} />

        <ValueAndActionsWrapper>
          <InputRow>
            <ToggleCollapse
              disabled={!hasChildren}
              tier={tier}
              isCollapsed={collapseChildren}
              toggle={toggleCollapsed}
            />

            <SortActions
              canMoveUp={!!swapUpWith}
              canMoveDown={!!swapDownWith}
              up={() =>
                swapUpWith &&
                addAction({
                  type: ActionTypeEnum.SwapValues,
                  optionId,
                  valueAId: value.valueId,
                  valueBId: swapUpWith,
                })
              }
              down={() =>
                swapDownWith &&
                addAction({
                  type: ActionTypeEnum.SwapValues,
                  optionId,
                  valueAId: value.valueId,
                  valueBId: swapDownWith,
                })
              }
            />

            <Input
              label="Value"
              value={titleValue}
              onChange={e => {
                const newValue = e.target.value;
                setTitleValue(newValue);
                addAction({
                  type: ActionTypeEnum.UpdateValue,
                  optionId,
                  valueId: value.valueId,
                  fields: { title: newValue },
                });
              }}
              style={{ maxWidth: TITLE_WIDTH, minWidth: '175px' }}
              error={
                showErrors ? getValueFieldError(value, 'title') : undefined
              }
            />

            <Input
              label="SKU"
              value={skuValue}
              onChange={e => {
                /**
                 * NOTE: this prevents even typing a space.
                 * it's fine for SKU because we don't allow spaces.
                 * but if we need it for other fields rather trim onBlur or when saving.
                 */
                const newValue = e.target.value.trim();
                setSkuValue(newValue);
                addAction({
                  type: ActionTypeEnum.UpdateValue,
                  optionId,
                  valueId: value.valueId,
                  fields: { sku: newValue },
                });
              }}
              style={{ maxWidth: '250px', minWidth: '135px' }}
              error={showErrors ? getValueFieldError(value, 'sku') : undefined}
            />

            <InputCurrency
              label="Price Diff"
              value={priceValue}
              style={{ maxWidth: '100px', minWidth: '60px' }}
              readOnly
              disabled
            />

            <InputCurrency
              label="Sale Price"
              value={salePriceValue}
              onChange={e => {
                if (currencyMask(e.target.value)) {
                  setSalePriceValue(e.target.value);

                  // reverse calculate the "price"
                  const salePriceFloat = parseFloat(e.target.value);
                  if (!isNaN(salePriceFloat)) {
                    const price = salePriceFloat - parentSellingPrice;
                    addAction({
                      type: ActionTypeEnum.UpdateValue,
                      optionId,
                      valueId: value.valueId,
                      fields: { price },
                    });
                  }
                }
              }}
              style={{ maxWidth: '100px', minWidth: '60px' }}
            />

            <Input
              label="Cost"
              value={costValue}
              onChange={e => {
                if (currencyMask(e.target.value)) {
                  setCostValue(e.target.value);

                  const cost = parseFloat(e.target.value);
                  if (!isNaN(cost)) {
                    addAction({
                      type: ActionTypeEnum.UpdateValue,
                      optionId,
                      valueId: value.valueId,
                      fields: { cost },
                    });
                  }
                }
              }}
              style={{ maxWidth: '100px', minWidth: '60px' }}
              withPrefix
            />

            <Input
              label="Qty"
              value={
                autoSumEnabledInternal ? calcValueQty(value, true) : qtyValue
              }
              error={qtyValue === ''}
              onChange={
                autoSumEnabledInternal
                  ? undefined
                  : e => {
                      if (intMask(e.target.value)) {
                        setQtyValue(e.target.value);

                        const quantity = Number(e.target.value);
                        /**
                         * NOTE: empty strings get converted to `0` by Number.
                         * We need them for editing, to allow negative or cleared values.
                         * But we want to skip adding an action for these empty values.
                         */
                        if (e.target.value !== '' && !isNaN(quantity)) {
                          addAction({
                            type: ActionTypeEnum.UpdateValue,
                            optionId,
                            valueId: value.valueId,
                            fields: { quantity },
                            /**
                             * if this value has children when users set the qty,
                             * we know you've done so by disabling auto-sum.
                             * in that case, we can filter out this action in future if auto-sum is re-enabled.
                             */
                            ...(hasChildren
                              ? { excludeOnAutoSumEnabled: true }
                              : {}),
                          });

                          // trigger our cumulative qty setter, but also pass the latest value
                          setCumulativeQuantity({
                            id: value.valueId,
                            qty: quantity,
                          });
                        }
                      }
                    }
              }
              style={{ maxWidth: '65px', minWidth: '45px' }}
              readOnly={autoSumEnabledInternal}
              disabled={autoSumEnabledInternal}
            />
          </InputRow>

          <ActionsWrapper>
            <Hidable
              className={hasChildren || !copyingOptionId ? 'hidden' : undefined}
            >
              <Button
                btnType="plain"
                size="small"
                padding="none"
                onClick={() => pasteOption(value.valueId)}
                disabled={hasChildren || !copyingOptionId}
              >
                <IconPaste size={18} />
              </Button>
            </Hidable>

            <Button
              btnType="plain"
              size="small"
              padding="none"
              disabled={hasChildren}
              onClick={() =>
                addAction({
                  type: ActionTypeEnum.AddOption,
                  productId,
                  parentValueId: value.valueId,
                  tmpOptionId: uuid(),
                  tmpValueId: uuid(),
                })
              }
            >
              <IconAddOption width={30} height={30} />
            </Button>

            {/**
             * NOTE: if a value has children and you delete the value
             * those children will become floating options (ie. root options)
             */}
            <Button
              btnType="plain"
              size="small"
              padding="none"
              disabled={disableRemove || hasChildren}
              onClick={() =>
                addAction({
                  type: ActionTypeEnum.RemoveValue,
                  optionId,
                  valueId: value.valueId,
                  parentValueId,
                })
              }
            >
              <IconDelete />
            </Button>
          </ActionsWrapper>
        </ValueAndActionsWrapper>
      </Row>

      {value.childOptions.length > 0 && (
        <ChildWrapper className={collapseChildren ? 'hide' : undefined}>
          {value.childOptions.map((childOption, idx) => (
            <ProductOption
              key={childOption.id}
              option={childOption}
              parentSellingPrice={sellingPrice}
              productId={productId}
              parentValueId={value.valueId}
              tier={tier + 1}
              maxTier={maxTier}
              hasSibling={idx > 0}
              setCumulativeQuantity={setCumulativeQuantity}
            />
          ))}
        </ChildWrapper>
      )}
    </>
  );
};

const ProductOption = ({
  option,
  parentSellingPrice,
  productId,
  parentValueId,
  swapUpWith,
  swapDownWith,
  tier = 1,
  maxTier,
  collapseChildren: collapseChildrenParent,
  hasSibling,
  setCumulativeQuantity,
}: {
  option: CustomOptionTree;
  parentSellingPrice: number;
  productId: ApiProduct['id'];
  parentValueId?: ApiCustomOptionValue['valueId'];
  swapUpWith?: ApiCustomOption['id'];
  swapDownWith?: ApiCustomOption['id'];
  tier?: number;
  maxTier: number;
  collapseChildren?: boolean;
  hasSibling?: boolean;
  setCumulativeQuantity: SetCumulativeQuantityInternal;
}) => {
  const addAction = useAddActionDebounced();
  const showErrors = useShowErrors();
  const autoSumEnabled = useAutoSumEnabled();
  const { copyingOptionId, copyOption, cancelCopy } = useCopyPaste();

  const [collapseChildren, toggleCollapsed] = useToggle();

  const [titleValue, setTitleValue] = useState(option.title);

  const isCopying = copyingOptionId === option.id;

  useEffect(() => {
    setTitleValue(option.title || '');
  }, [option.title]);

  return (
    <>
      {hasSibling && (
        <div
          style={{
            background: `linear-gradient(to right, ${
              getColor(tier - 1).base
            }, ${getColor(tier - 1).base} 50%, #e6e6e8 50%, #e6e6e8)`,
            height: '4px',
            margin: '24px 0',
            width: '100%',
          }}
        />
      )}

      <Row>
        <TierGraph tier={tier} maxTier={maxTier} />

        <ValueAndActionsWrapper>
          <InputRow>
            <ToggleCollapse
              disabled={option.values.length === 0}
              tier={tier}
              isCollapsed={collapseChildrenParent || collapseChildren}
              toggle={toggleCollapsed}
            />

            <SortActions
              canMoveUp={!!swapUpWith}
              canMoveDown={!!swapDownWith}
              up={() =>
                swapUpWith &&
                addAction({
                  type: ActionTypeEnum.SwapOptions,
                  optionAId: option.id,
                  optionBId: swapUpWith,
                })
              }
              down={() =>
                swapDownWith &&
                addAction({
                  type: ActionTypeEnum.SwapOptions,
                  optionAId: option.id,
                  optionBId: swapDownWith,
                })
              }
            />

            <Input
              label="Dropdown Title"
              value={titleValue}
              onChange={e => {
                const newValue = e.target.value;
                setTitleValue(newValue);
                addAction({
                  type: ActionTypeEnum.UpdateOption,
                  id: option.id,
                  fields: { title: newValue },
                });
              }}
              style={{ maxWidth: TITLE_WIDTH, minWidth: '175px' }}
              error={
                showErrors ? getOptionFieldError(option, 'title') : undefined
              }
            />

            {tier === 1 && option.type === InputTypeEnum.dropdown && (
              <Input
                label="Qty"
                value={calcOptionQty(option, autoSumEnabled)}
                style={{ maxWidth: '65px', minWidth: '45px' }}
                readOnly
                disabled
              />
            )}
          </InputRow>

          <ActionsWrapper>
            <Button
              btnType="plain"
              size="small"
              padding="none"
              color={isCopying ? 'hsl(318deg 74% 62%)' : undefined}
              onClick={() => (!isCopying ? copyOption(option) : cancelCopy())}
            >
              <SlowSwing className={isCopying ? 'active' : undefined}>
                <IconCopy size={20} />
              </SlowSwing>
            </Button>

            <Button
              btnType="plain"
              size="small"
              padding="none"
              onClick={() =>
                addAction({
                  type: ActionTypeEnum.AddValue,
                  optionId: option.id,
                  tmpValueId: uuid(),
                  parentValueId,
                })
              }
            >
              <IconAdd />
            </Button>

            <Button
              btnType="plain"
              size="small"
              padding="none"
              onClick={() =>
                addAction({
                  type: ActionTypeEnum.RemoveOption,
                  id: option.id,
                  parentValueId,
                })
              }
            >
              <IconDelete />
            </Button>
          </ActionsWrapper>
        </ValueAndActionsWrapper>
      </Row>

      {option.type === InputTypeEnum.dropdown && option.values.length > 0 && (
        <ChildWrapper
          className={
            collapseChildrenParent || collapseChildren ? 'hide' : undefined
          }
        >
          {option.values.map((value, idx) => (
            <DropdownValue
              key={value.valueId}
              value={value}
              optionId={option.id}
              parentSellingPrice={parentSellingPrice}
              parentValueId={parentValueId}
              productId={productId}
              tier={tier}
              maxTier={maxTier}
              swapUpWith={
                idx > 0 ? option.values.at(idx - 1)?.valueId : undefined
              }
              swapDownWith={
                idx + 1 < option.values.length
                  ? option.values.at(idx + 1)?.valueId
                  : undefined
              }
              disableRemove={option.values.length === 1}
              setCumulativeQuantity={setCumulativeQuantity}
            />
          ))}
        </ChildWrapper>
      )}
    </>
  );
};

export const Toolbar = () => {
  const {
    autoSumEnabled,
    canUndo,
    canRedo,
    canClearActions,
    productMeta,
    moveActionOffset,
    copyingOptionId,
    pasteOption,
    clearActions,
    toggleAutoSumEnabled,
  } = useCustomOptionsEditorContext();

  const addAction = useAddActionDebounced();

  if (!productMeta) {
    return null;
  }

  return (
    <ToolbarWrapper>
      <ToolbarPart>
        <Button
          iconLeft={IconUndo}
          disabled={!canUndo}
          onClick={() => canUndo && moveActionOffset(-1)}
        >
          UNDO
        </Button>

        <Button
          iconLeft={IconRedo}
          disabled={!canRedo}
          onClick={() => canRedo && moveActionOffset(+1)}
        >
          REDO
        </Button>

        <ToolbarSeparator />

        <Button
          btnType="outline"
          onClick={() =>
            addAction({
              type: ActionTypeEnum.AddOption,
              tmpOptionId: uuid(),
              tmpValueId: uuid(),
              productId: productMeta.id,
            })
          }
        >
          NEW OPTION
        </Button>

        <Hidable className={!copyingOptionId ? 'hidden' : undefined}>
          <ToolbarSeparator />
        </Hidable>

        <Hidable className={!copyingOptionId ? 'hidden' : undefined}>
          <Button
            iconLeft={IconPaste}
            onClick={() => pasteOption()}
            disabled={!copyingOptionId}
          >
            PASTE
          </Button>
        </Hidable>
      </ToolbarPart>

      <ToolbarPart>
        <Hidable className={!canClearActions ? 'hidden' : undefined}>
          <Button
            btnType="outline"
            onClick={() => clearActions()}
            disabled={!canClearActions}
          >
            CLEAR CHANGES
          </Button>
        </Hidable>

        <Button
          btnType={autoSumEnabled ? 'primary' : 'outline'}
          iconLeft={autoSumEnabled ? IconAutoSumOn : IconAutoSumOff}
          onClick={() => toggleAutoSumEnabled()}
        >
          AUTO-SUM {autoSumEnabled ? 'ENABLED' : 'DISABLED'}
        </Button>
      </ToolbarPart>
    </ToolbarWrapper>
  );
};

const CustomOptions = ({
  setCumulativeQuantity: setCumulativeQuantityProp,
}: {
  setCumulativeQuantity?: (qty: number) => void;
}) => {
  const cumulativeQty = useRef<undefined | number>();
  const { productMeta, editorCustomOptions, autoSumEnabled, getCumulativeQty } =
    useCustomOptionsEditorContext();

  const maxTier = useMemo(
    () => calcMaxTier(editorCustomOptions),
    [editorCustomOptions]
  );

  const setCumulativeQuantity = useCallback(
    (valueQtyLatest?: { id: string; qty: number }) => {
      if (!autoSumEnabled || !setCumulativeQuantityProp) return;

      const nextCumulativeQty = getCumulativeQty(
        valueQtyLatest ? { [valueQtyLatest.id]: valueQtyLatest.qty } : undefined
      );

      // if next cumulative quantity has changed trigger our set function.
      if (
        typeof nextCumulativeQty !== 'undefined' &&
        nextCumulativeQty !== cumulativeQty.current
      ) {
        cumulativeQty.current = nextCumulativeQty;
        setCumulativeQuantityProp(nextCumulativeQty);
      }
    },
    [setCumulativeQuantityProp, autoSumEnabled, getCumulativeQty]
  );

  if (!productMeta) {
    return null;
  }

  return (
    <>
      {editorCustomOptions.length > 0 ? (
        <OptionsWrapper>
          {editorCustomOptions.map((option, idx) => (
            <ProductOption
              key={option.id}
              option={option}
              parentSellingPrice={productMeta.price || 0}
              productId={productMeta.id}
              maxTier={maxTier}
              swapUpWith={
                idx > 0 ? editorCustomOptions.at(idx - 1)?.id : undefined
              }
              swapDownWith={
                idx + 1 < editorCustomOptions.length
                  ? editorCustomOptions.at(idx + 1)?.id
                  : undefined
              }
              hasSibling={idx > 0}
              setCumulativeQuantity={setCumulativeQuantity}
            />
          ))}
        </OptionsWrapper>
      ) : (
        <SmallHeading>No custom options for this product...</SmallHeading>
      )}
    </>
  );
};

export default CustomOptions;
