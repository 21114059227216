import React, { useCallback } from 'react';
import { PortalErrorBoundary } from 'components/customOptions/v3/PortalErrorBoundary.jsx';
import CustomOptions, {
  Toolbar,
} from '@odo/screens/deal/editor/price-and-custom-options/custom-options.tsx';
import { useCurrentDealSource } from '../../../hooks/useCurrentDealSource';
import { Deal } from 'models/Deal.jsx';
import { DealShipping } from 'models/Shipping.jsx';
import Card from '@odo/components/elements/card';
import { Heading } from '@odo/screens/deal/editor/price-and-custom-options/styles.ts';
import { Overscroll } from '@odo/screens/deal/editor/elements/styles.ts';

export const CustomOptionsSection = () => {
  const currentDeal = useCurrentDealSource();

  const setCumulativeQuantity = useCallback(
    qty => {
      currentDeal.deal.set(
        Deal.MODELS.SHIPPING,
        DealShipping.FIELDS.X_QUANTITY_LEFT,
        qty
      );

      /**
       * NOTE: we're doing this here and in DealSideEffects. The side effect is to ensure it's always in sync.
       * But if we don't do it here when they're changing qty, they'll see a flash of warning between renders.
       */
      currentDeal.deal.set(
        Deal.MODELS.SHIPPING,
        DealShipping.FIELDS.CUMULATIVE_CUSTOM_OPTIONS_QTY,
        qty
      );

      currentDeal.update();
    },
    [currentDeal]
  );

  return (
    <PortalErrorBoundary boundary="CustomOptionsSection">
      <Card>
        <Heading>Custom Options</Heading>
        <Toolbar />
        <CustomOptions setCumulativeQuantity={setCumulativeQuantity} />
      </Card>

      <Overscroll />
    </PortalErrorBoundary>
  );
};
