import metadata from 'buildData.json';

const version = process.env.REACT_APP_VERSION;
const cssPath = process.env.REACT_APP_CSS_URL;
const ODOAdminUrl = process.env.REACT_APP_ODO_BASE_URL;
const ODOGraphQLEndpoint = process.env.REACT_APP_ODO_GRAPHQL_URL;
const ODOForgotPasswordURL = process.env.REACT_APP_FORGOT_PASSWORD_URL;
const ODOSignoffBaseUrl = process.env.REACT_APP_ODO_SIGNOFF_BASE_URL;
const enableLogDumps = process.env.REACT_APP_ENABLE_LOGDUMPS === 'true';
const enableLogTrace = process.env.REACT_APP_ENABLE_LOG_TRACE === 'true';
const logDumpHistorySize = +process.env.REACT_APP_LOGDUMP_HISTORY_SIZE || 1000;

const concurrentImageUploadsEnabled =
  process.env.REACT_APP_CONCURRENT_IMAGE_UPLOADS === 'true';

const datadogApplicationId = process.env.REACT_APP_DATADOG_APPLICATION_ID;
const datadogClientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;
const datadogService = process.env.REACT_APP_DATADOG_SERVICE;
const datadogEnv = process.env.REACT_APP_DATADOG_ENV;
const semVer = `${metadata.majorVersion}.${metadata.minorVersion}.${metadata.bugfix}`;

window.cssPath = cssPath;

export {
  version,
  cssPath,
  ODOAdminUrl,
  ODOForgotPasswordURL,
  ODOGraphQLEndpoint,
  ODOSignoffBaseUrl,
  logDumpHistorySize,
  enableLogDumps,
  enableLogTrace,
  concurrentImageUploadsEnabled,
  datadogApplicationId,
  datadogClientToken,
  datadogService,
  datadogEnv,
  semVer,
};
