import { type Validator } from '@odo/screens/deal/editor/types';

export const summarySectionValidators: Validator[] = [];

export const costingInfoSectionValidators: Validator[] = [];

export const skuAndSuggestionsSectionValidators: Validator[] = [];

// TODO: decide whether to have custom extra validators here, or just repeat all the other ones
const validators: Validator[] = [
  ...summarySectionValidators,
  ...costingInfoSectionValidators,
  ...skuAndSuggestionsSectionValidators,
];

export default validators;
