import { useCurrentDealSource } from 'hooks/useCurrentDealSource';
import { Deal } from 'models/Deal.jsx';
import { DealShipping } from 'models/Shipping.jsx';
import { handleODOInput } from 'utils/odo-migration/forms';
import { Flex } from '@odo/components/elements/layout/flex';
import { Input } from '@odo/components/elements/form-fields';
import PackageDimensions from '@odo/screens/deal/editor/shipping-and-inventory/widgets/package-dimensions';
import { isPositiveNumeric } from './validation';

export const PackageSection = () => {
  const currentDeal = useCurrentDealSource();
  const model = currentDeal.deal.shipping;
  const VOLUMETRIC_WEIGHT_COEFFICIENT = 4000;
  const greaterWeightValue = () =>
    (model.width * model.height * model.length) / VOLUMETRIC_WEIGHT_COEFFICIENT;
  const displayWeightValue = () => {
    if (greaterWeightValue() > model.weight) {
      return (
        'Greater Weight: ' +
        greaterWeightValue().toString() +
        'kg' +
        ' (Volumetric Weight)'
      );
    } else {
      return (
        'Greater Weight: ' +
        model.weight?.toString() +
        'kg' +
        ' (Manual Weight)'
      );
    }
  };

  return (
    <Flex flexDirection="column" gap="2rem" width="100%" maxWidth="400px">
      <h5>Package Weight</h5>
      <Input
        label="Manual Weight (kg)"
        value={model.weight}
        onChange={e =>
          isPositiveNumeric(e.target.value) &&
          handleODOInput({
            currentDeal,
            model: Deal.MODELS.SHIPPING,
            field: DealShipping.FIELDS.WEIGHT,
          })(e)
        }
        inputMode="decimal"
        min={0}
        max={Number.MAX_SAFE_INTEGER}
        step={0.01}
        required
        selectOnFocus
        matchRPStyles
      />

      <rps-input-label text={displayWeightValue()} type="number" />

      <h5>Package Preview</h5>
      <Flex
        justifyContent="center"
        alignItem="center"
        weight="400px"
        height="400px"
      >
        <PackageDimensions
          width={model.width}
          height={model.height}
          length={model.length}
        />
      </Flex>
    </Flex>
  );
};
