import { Status, type Validator } from '@odo/screens/deal/editor/types';
import { ImageType } from '@odo/types/api';

export const imagesSectionValidators: Validator[] = [
  {
    validate: product =>
      (product.images || []).filter(
        image => !image.shouldDelete && !image.isHidden
      ).length > 0,
    message: 'No image(s) will be shown for this deal',
    status: Status.warning,
  },
  {
    validate: product =>
      // if there are no images going to be displayed, defer to our first validator
      (product.images || []).filter(
        image => !image.shouldDelete && !image.isHidden
      ).length === 0 ||
      // else confirm that we have all of our image types selected
      [ImageType.email, ImageType.website, ImageType.emailThumbnail].every(
        imageType =>
          (product.images || []).some(
            image =>
              // image isn't going to be deleted
              !image.shouldDelete &&
              // image has the correct type
              image.imageTypes?.includes(imageType)
          )
      ),
    message: 'Select an email, website, and thumbnail image.',
    status: Status.error,
  },
];

export const videosSectionValidators: Validator[] = [];

const validators: Validator[] = [
  ...imagesSectionValidators,
  ...videosSectionValidators,
];

export default validators;
