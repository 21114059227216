// Preset product combinations and their defaults, i.e.: "Product+Alcohol+Wine" has certain fields filled in by default.

import { Deal } from 'models/Deal.jsx';
import { DealBuyerAndSupplier } from 'models/BuyerAndSupplier.jsx';
import { DealShipping } from 'models/Shipping.jsx';
import { DealConditionsAndCategory } from 'models/ConditionsAndCategory.jsx';
import { DealProduct } from 'models/Product.jsx';
import { DealPriceAndCustomOptions } from 'models/PriceAndCustomOptions.jsx';
import {
  DESCRIPTION_BASE,
  DESCRIPTION_WINE,
  DESCRIPTION_BEER,
  DESCRIPTION_ALCOHOL,
  DESCRIPTION_PERISHABLES,
  DESCRIPTION_PERSONALIZED_VOUCHER,
  DESCRIPTION_TRAVEL_VOUCHER,
  DESCRIPTION_PARALLEL_IMPORT,
} from './data';

const MODELS = Deal.MODELS;
const BUYER_AND_SUPPLIER = DealBuyerAndSupplier.FIELDS;
const SHIPPING = DealShipping.FIELDS;
const PRODUCT = DealProduct.FIELDS;
const CONDITIONS = DealConditionsAndCategory.FIELDS;
const PRICE_AND_OPTIONS = DealPriceAndCustomOptions.FIELDS;

/**
 * NOTE: there's no reasonable way to get these enum values dynamically
 * without rebuilding the create screen or something at least as drastic.
 */
const VOUCHER_FIELDS = [
  {
    model: MODELS.BUYER_AND_SUPPLIER,
    field: BUYER_AND_SUPPLIER.DEAL_TYPE,
    value: ['SUPPLIER_VOUCHER'],
  },
  // presets for supplier voucher type:
  { model: MODELS.PRODUCT, field: PRODUCT.AREA, value: 'OTHER' },
  {
    model: MODELS.PRICE_AND_CUSTOM_OPTIONS,
    field: PRICE_AND_OPTIONS.ADMIN,
    value: 'ADMINCOST_10_00',
  },
  { model: MODELS.SHIPPING, field: SHIPPING.APPLY_SHIPPING, value: false },
  { model: MODELS.SHIPPING, field: SHIPPING.INDIVIDUAL_SHIPPING, value: true },
  { model: MODELS.SHIPPING, field: SHIPPING.SUPPLIER_DELIVERS, value: true },
  {
    model: MODELS.SHIPPING,
    field: SHIPPING.CUSTOMER_DELIVERY_TIME,
    value: 'NOT_APPLICABLE',
  },
  // placeholder values to avoid customers checking out with pargo points for vouchers
  { model: MODELS.SHIPPING, field: SHIPPING.WIDTH, value: 20 },
  { model: MODELS.SHIPPING, field: SHIPPING.LENGTH, value: 20 },
  { model: MODELS.SHIPPING, field: SHIPPING.HEIGHT, value: 20 },
  { model: MODELS.SHIPPING, field: SHIPPING.WEIGHT, value: 20 },
  // empty shipping is different to `R0` shipping
  { model: MODELS.SHIPPING, field: SHIPPING.SHIPPING_COST, value: '' },
];

export const applyPresetCombinations = (key, deal) => {
  if (!productCombinations[key]) {
    return;
  }

  productCombinations[key].forEach(fieldPreset => {
    const { model, field, value } = fieldPreset;
    if (typeof value === 'function') {
      deal[model].set(field, value(deal));
    } else {
      deal[model].set(field, value);
    }
  });
};

export const productCombinations = {
  /**
   * Base/Reset.
   */
  reset: [
    {
      model: MODELS.BUYER_AND_SUPPLIER,
      field: BUYER_AND_SUPPLIER.DEAL_TYPE,
      value: [],
    },
    {
      model: MODELS.CONDITIONS_AND_CATEGORY,
      field: CONDITIONS.IS_ALCOHOLIC,
      value: false,
    },
    {
      model: MODELS.CONDITIONS_AND_CATEGORY,
      field: CONDITIONS.IS_PARALLEL_IMPORT,
      value: false,
    },
    {
      model: MODELS.CONDITIONS_AND_CATEGORY,
      field: CONDITIONS.ADDITIONAL_INFO,
      value: '',
    },
    {
      model: MODELS.PRODUCT,
      field: PRODUCT.DESCRIPTION,
      value: DESCRIPTION_BASE,
    },
    { model: MODELS.PRODUCT, field: PRODUCT.PILL1, value: '' },
    { model: MODELS.PRODUCT, field: PRODUCT.AREA, value: null },
    { model: MODELS.SHIPPING, field: SHIPPING.APPLY_SHIPPING, value: true },
    { model: MODELS.SHIPPING, field: SHIPPING.WIDTH, value: '' },
    { model: MODELS.SHIPPING, field: SHIPPING.LENGTH, value: '' },
    { model: MODELS.SHIPPING, field: SHIPPING.HEIGHT, value: '' },
    { model: MODELS.SHIPPING, field: SHIPPING.WEIGHT, value: '' },
    {
      model: MODELS.SHIPPING,
      field: SHIPPING.INDIVIDUAL_SHIPPING,
      value: false,
    },
    { model: MODELS.SHIPPING, field: SHIPPING.SUPPLIER_DELIVERS, value: false },
    { model: MODELS.SHIPPING, field: SHIPPING.SHIPPING_COST, value: '' },
    {
      model: MODELS.PRICE_AND_CUSTOM_OPTIONS,
      field: PRICE_AND_OPTIONS.ADMIN,
      value: 'ADMINCOST_17_50',
    },
    {
      model: MODELS.PRICE_AND_CUSTOM_OPTIONS,
      field: PRICE_AND_OPTIONS.INSURANCE,
      value: false,
    },
  ],

  /**
   * Alcohol.
   */
  'product+alcohol+wine': [
    {
      model: MODELS.CONDITIONS_AND_CATEGORY,
      field: CONDITIONS.IS_ALCOHOLIC,
      value: true,
    },
    {
      model: MODELS.CONDITIONS_AND_CATEGORY,
      field: CONDITIONS.ADDITIONAL_INFO,
      value: 'Not for persons under the age of 18.',
    },
    {
      model: MODELS.PRODUCT,
      field: PRODUCT.DESCRIPTION,
      value: DESCRIPTION_WINE,
    },
    { model: MODELS.SHIPPING, field: SHIPPING.LENGTH, value: 30 },
    { model: MODELS.SHIPPING, field: SHIPPING.WIDTH, value: 20 },
    { model: MODELS.SHIPPING, field: SHIPPING.HEIGHT, value: 30 },
    { model: MODELS.SHIPPING, field: SHIPPING.WEIGHT, value: 9 },
    {
      model: MODELS.PRODUCT,
      field: PRODUCT.PILL1,
      value: deal => {
        const name = deal.product.name;
        if (!name) {
          return '';
        }

        // Matches phrases in parentheses like "R83.99 per bottle" or "R30 per can"
        const nameRegex = /\((R\d+[\.,]?\d* per \w+)[,\)]/i;
        const match = name.match(nameRegex);
        if (match && match[1]) {
          return match[1];
        } else {
          return '';
        }
      },
    },
  ],

  'product+alcohol+liquorAndBeer': [
    {
      model: MODELS.CONDITIONS_AND_CATEGORY,
      field: CONDITIONS.IS_ALCOHOLIC,
      value: true,
    },
    {
      model: MODELS.CONDITIONS_AND_CATEGORY,
      field: CONDITIONS.ADDITIONAL_INFO,
      value: 'Not for persons under the age of 18.',
    },
    {
      model: MODELS.PRODUCT,
      field: PRODUCT.DESCRIPTION,
      value: DESCRIPTION_BEER,
    },
  ],

  'product+alcohol+other': [
    {
      model: MODELS.CONDITIONS_AND_CATEGORY,
      field: CONDITIONS.IS_ALCOHOLIC,
      value: true,
    },
    {
      model: MODELS.CONDITIONS_AND_CATEGORY,
      field: CONDITIONS.ADDITIONAL_INFO,
      value: 'Not for persons under the age of 18.',
    },
    {
      model: MODELS.PRODUCT,
      field: PRODUCT.DESCRIPTION,
      value: DESCRIPTION_ALCOHOL,
    },
  ],

  /**
   * Consumables & Perishables.
   */
  'product+consumablesAndPerishables': [
    {
      model: MODELS.PRODUCT,
      field: PRODUCT.DESCRIPTION,
      value: DESCRIPTION_PERISHABLES,
    },
  ],

  /**
   * Voucher.
   */
  'voucher+personalisedProduct': [
    {
      model: MODELS.PRODUCT,
      field: PRODUCT.DESCRIPTION,
      value: DESCRIPTION_PERSONALIZED_VOUCHER,
    },

    ...VOUCHER_FIELDS,
  ],

  'voucher+travelAndExperiences': [
    {
      model: MODELS.PRODUCT,
      field: PRODUCT.DESCRIPTION,
      value: DESCRIPTION_TRAVEL_VOUCHER,
    },

    ...VOUCHER_FIELDS,
  ],

  'voucher+other': [...VOUCHER_FIELDS],

  /**
   * Supplier Dispatch.
   */
  supplierDispatch: [
    {
      model: MODELS.PRICE_AND_CUSTOM_OPTIONS,
      field: PRICE_AND_OPTIONS.INSURANCE,
      value: false,
    },
    {
      model: MODELS.PRICE_AND_CUSTOM_OPTIONS,
      field: PRICE_AND_OPTIONS.ADMIN,
      value: 'ADMINCOST_10_00',
    },
    { model: MODELS.PRODUCT, field: PRODUCT.AREA, value: null },
    { model: MODELS.SHIPPING, field: SHIPPING.APPLY_SHIPPING, value: true },
    {
      model: MODELS.SHIPPING,
      field: SHIPPING.INDIVIDUAL_SHIPPING,
      value: true,
    },
    { model: MODELS.SHIPPING, field: SHIPPING.SUPPLIER_DELIVERS, value: true },
    {
      model: MODELS.SHIPPING,
      field: SHIPPING.MIN_QTY_IN_SHOPPING_CART,
      value: 0,
    },
    {
      model: MODELS.SHIPPING,
      field: SHIPPING.MAX_QTY_IN_SHOPPING_CART,
      value: 10,
    },
    {
      model: MODELS.SHIPPING,
      field: SHIPPING.USE_CONFIG_MAX_QTY_CART,
      value: true,
    },
    {
      model: MODELS.SHIPPING,
      field: SHIPPING.USE_CONFIG_MIN_QTY_CART,
      value: true,
    },
  ],

  /**
   * Parallel Import.
   */
  parallelImport: [
    {
      model: MODELS.CONDITIONS_AND_CATEGORY,
      field: CONDITIONS.IS_PARALLEL_IMPORT,
      value: true,
    },
    {
      model: MODELS.CONDITIONS_AND_CATEGORY,
      field: CONDITIONS.ADDITIONAL_INFO,
      value: 'Parallel Import',
    },
    {
      model: MODELS.SHIPPING,
      field: SHIPPING.MIN_QTY_IN_SHOPPING_CART,
      value: 0,
    },
    {
      model: MODELS.SHIPPING,
      field: SHIPPING.MAX_QTY_IN_SHOPPING_CART,
      value: 10,
    },
    {
      model: MODELS.SHIPPING,
      field: SHIPPING.USE_CONFIG_MAX_QTY_CART,
      value: true,
    },
    {
      model: MODELS.SHIPPING,
      field: SHIPPING.USE_CONFIG_MIN_QTY_CART,
      value: true,
    },
    {
      model: MODELS.PRODUCT,
      field: PRODUCT.DESCRIPTION,
      value: DESCRIPTION_PARALLEL_IMPORT,
    },
  ],
};
