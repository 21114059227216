// Deal wizard container

import React, { useState } from 'react';
import { useParams, Outlet as RouteChildren } from 'react-router-dom';
import { ProgressTrack } from '../shared/ProgressTrack';
import DealLoader from './DealLoader';
import NewDealPrep from './NewDealPrep';
import AttributeProvider from '@odo/contexts/attributes/provider';
import DealSideEffects from './DealSideEffects';

export const DealWizard = () => {
  const { dealId } = useParams();
  const [isDealReady, setIsDealReady] = useState(false);

  return (
    <AttributeProvider>
      {!dealId && (
        <NewDealPrep
          isDealReady={isDealReady}
          setIsDealReady={setIsDealReady}
        />
      )}

      {!!dealId && (
        <DealLoader
          dealId={dealId}
          isDealReady={isDealReady}
          setIsDealReady={setIsDealReady}
        />
      )}

      {isDealReady && (
        <>
          <DealSideEffects />

          {/**
           * I've decided this thing being sticky is a massive waste of vertical space.
           * So I've moved it into here for now, where it can be scrolled out of view.
           **/}
          <ProgressTrack />

          <RouteChildren />
        </>
      )}
    </AttributeProvider>
  );
};
