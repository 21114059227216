import { useState } from 'react';
import { ApolloClients } from './../../services/apolloClients';
import { gql } from '@apollo/client';
import { useUserDataSource } from 'hooks/userDataSource';
import { useNavigate } from 'react-router-dom';
import { GET_ODO_DEALS } from './../../gql/deals/getDeals';
import { ODO_LOGIN } from 'gql/authentication/login';

const GET_REQUEST_TOKEN = gql`
  query GetRequestToken {
    getOauthRequestToken {
      ... on OauthForm {
        initialResponse
        htmlForm
      }
    }
  }
`;

/**
 * Class used to open a popup window and populate it with HTML sent from the Magento API.
 */
// TODO: Create a more generic version of this class for JS-UTILS
class PopupWindow {
  constructor(
    url,
    windowName,
    options = {
      rawHTML: '',
      mountSelector: 'html',
      dimensions: { width: 480, height: 512 },
      position: { left: 0, top: 0 },
      centerOnScreen: true,
    }
  ) {
    this.url = url;
    this.options = options;
    this.windowName = windowName;
  }

  init() {
    this._createWindow();
    this._buildNewDOM();

    this._focusWindow();
  }

  _createWindow() {
    const options = [
      'popup',
      `width=${this.options.dimensions.width}`,
      `height=${this.options.dimensions.height}`,
    ];

    if (this.options.centerOnScreen) {
      const leftOffset =
        window.innerWidth / 2 - this.options.dimensions.width / 2;
      const topOffset =
        window.innerHeight / 2 - this.options.dimensions.height / 2;
      options.push(`left=${leftOffset}`, `top=${topOffset}`);
    } else {
      options.push(
        `left=${this.options.position.left}`,
        `top=${this.options.position.top}`
      );
    }
    this.window = window.open('', this.windowName, options.join(','));
  }

  _windowIsValid() {
    return this.window && !this.window.closed;
  }

  _buildNewDOM() {
    if (!this.options.rawHTML) return;
    if (!this._windowIsValid()) return;

    try {
      const newDocument = new DOMParser().parseFromString(
        this.options.rawHTML,
        'text/html'
      );
      if (this.window) {
        const body = newDocument.querySelector(this.options.mountSelector);
        if (body) {
          this.window.document.replaceChild(
            body,
            this.window.document.documentElement
          );
        } else {
          console.warn(
            `[PopupWindow::_buildNewDOM] Mount selector '${this.options.mountSelector}' not found in rawHTML:`,
            this.options.rawHTML,
            'Attempting to set root of DOM doc instead.'
          );
          this.window.document.replaceChild(
            newDocument,
            this.window.document.documentElement
          );
        }
      } else {
        console.error(
          `[PopupWindow::_buildNewDOM] Error adding DOM to window: No window exists.`
        );
      }
    } catch (e) {
      console.error(
        `[PopupWindow::_buildNewDOM] Error creating new DOM from rawHTML string. Details: `,
        this.url,
        this.options,
        e
      );
    }
  }

  _focusWindow() {
    if (!this._windowIsValid()) return;

    this.window.focus();
  }
}

/**
 * Login controller hook.
 *
 * @return {{
 * 	login: (username: String, password: String, forceAuthorization: Boolean = false) => Promise<any>,
 * 	message: String,
 * 	setMessage: (newMessage: String) => void,
 * 	directLogin: (username: String, password: String) => Promise<any>,
 * 	directLoginAuth: (username: String, password: String) => Promise<any>,
 * 	busy: Boolean
 * }}
 */
export const useLoginController = () => {
  const client = new ApolloClients().odo;

  const [message, setMessage] = useState('');
  const [busy, setBusy] = useState(false);

  const user = useUserDataSource();
  const navigate = useNavigate();

  const getODORequestToken = async () => {
    const { data } = await client.query({
      query: GET_REQUEST_TOKEN,
      errorPolicy: 'ignore',
      fetchPolicy: 'network-only',
      context: {},
    });

    const form = data.getOauthRequestToken?.htmlForm || '';
    const tokens = data.getOauthRequestToken?.initialResponse || '';

    console.debug(
      `[useLoginController::getODORequestToken@125] Received request token response:`,
      tokens
    );
    user.tokens.setRequestTokens(tokens);

    return { form, tokens };
  };

  const directLogin = async (username, password) => {
    const client = new ApolloClients().odoNoAuth;
    const { data } = await client.mutate({
      mutation: ODO_LOGIN,
      variables: {
        username: username,
        password: password,
      },
      errorPolicy: 'ignore',
      fetchPolicy: 'network-only',
    });

    if (data?.login?.username) {
      console.log('[useLoginController:directLogin@143] Login Succeeded!');
      return data.login;
    } else {
      return undefined;
    }
  };

  const directLoginAuth = async (username, password) => {
    const { data } = await client.mutate({
      mutation: ODO_LOGIN,
      variables: {
        username: username,
        password: password,
      },
      errorPolicy: 'ignore',
      fetchPolicy: 'network-only',
    });

    if (data?.login?.username) {
      console.log('[useLoginController:directLogin@143] Login Succeeded!');
      return data.login;
    } else {
      return undefined;
    }
  };

  const login = async (username, password) => {
    setBusy(true);
    user.tokens.setAuthTokens('');
    user.tokens.setRequestTokens('');
    try {
      const userData = await directLogin(username, password);

      if (!userData) {
        console.debug(
          `[useLoginController::login@153] Login failed - username or password incorrect, or network connection interrupted.`
        );
        setMessage(
          'Login failed! Please check username and password and try again.'
        );
        return;
      }

      // Check if user is already authorized by attempting to fetch some deals
      // TODO: get an API call set up for checking this instead of risking hitting the DB.
      const { data } = await client.query({
        query: GET_ODO_DEALS,
        variables: { filter: { limit: 1 } },
        errorPolicy: 'ignore',
        fetchPolicy: 'network-only',
      });

      if (data?.getProducts?.length > 0) {
        user.actions.setUserData(userData);
        const lastLocation = localStorage.getItem('lastLocation');
        if (lastLocation) {
          localStorage.removeItem('lastLocation');
          navigate(lastLocation);
        } else {
          navigate('/dashboard');
        }
      }
    } catch (e) {
      console.debug(
        `[useLoginController::login] No existing authorized user. Continuing with auth process.`,
        e
      );
      setBusy(false);
    } finally {
      setBusy(false);
    }
  };

  const register = async () => {
    setBusy(true);
    user.tokens.setAuthTokens('');
    user.tokens.setRequestTokens('');
    try {
      const { form } = await getODORequestToken();

      const win = new PopupWindow('/login-form', 'MagentoLoginForm');

      win.options.rawHTML = form;
      win.options.dimensions = { width: 800, height: 600 };
      win.init();

      navigate('/auth/loading');
    } catch (e) {
      console.error(
        `[useLoginController::login@171] Exception thrown by login controller. See previous messages for trace. Details:`,
        e
      );
      setBusy(false);
    } finally {
      setBusy(false);
    }
  };

  return {
    login,
    register,
    message,
    setMessage,
    directLogin,
    directLoginAuth,
    busy,
  };
};
