// Some constants to avoid typo's
const SESSION_STORAGE = 'sessionStorage';
const KEY_VALUE = 'keyValue';
const DB = 'db';
const MEM = 'memory';

/**
 * A wrapper class that handles all kinds of caching with a uniform interface.
 * The different caching "classes" are integrated into this one instead of splitting them to get developers to use the single instance
 * and prevent specific instances being used that cant be "swapped out"
 *
 * @class Cache
 */
export class Cache {
	/**
	 * Configures an instance of Cache.
	 * NB: dbConfig is ONLY needed if you are going to use [storageType='db']
	 * 
	 * @description appName= Name of application, 
	 * storeName= DB name, 
	 * version= IndexedDb version
	 * 
	 * @param {string} [storageType='keyValue'] Possible values are 'sessionStorage' or 'keyValue' or 'db' or 'memory'
	 * localStorage is discouraged as it is SYNC, and as such not offered, rather use 'memory' if you need session only storage
	 * @param {'myApp'} appName - The App's name for this cache
	 * @param {'db1'} storeName - the database name in this storage
	 * @param {'2'} version - The version to use
	 * @memberof Cache
	 */
	async configure(storageType = 'keyValue', appName = 'myApp', storeName = 'db1', version = '2') {
		const dbConfig = { appName, storeName, version };
		this.layer = undefined;			// The caching layer to use
		this.storageType = storageType;

		switch (storageType) {
			case SESSION_STORAGE:
				this.layer = window.sessionStorage;
				break;
			case KEY_VALUE:
				this.layer = await this._useIDB(dbConfig);
				break;
			case DB:
				this.layer = await this._useLocalForage(dbConfig);
				break;
			case MEM:
				this.layer = this._useMemory(dbConfig);
				break;
			default:
				throw new Error(`Cache:constructor must have a valid storageType=${storageType}`);
		}

		return this;
	}

	/**
	 *	 * An internal function. Dont use this
	 *
	 * @param {Object} dbConfig Config as per localforage. But only uses the name and storeName
	 * @return {Object} idb-keyval instance 
	 * @memberof Cache
	 */
	async _useIDB(dbConfig) {
		const kv = await import('idb-keyval');
		kv.createStore(dbConfig.name, dbConfig.storeName);
		return kv;
	}

	/**
	 * An internal function. Dont use this
	 *
	 * @param {Object} dbConfig Config as per localforage
	 * @return {Object} localforage instance
	 * @memberof Cache
	 */
	async _useLocalForage(dbConfig) {
		const base = await import('localforage');
		const lf = base.createInstance({
			driver: base.INDEXEDDB, 				// Force WebSQL; same as using setDriver()
			name: dbConfig.appName,
			//version: dbConfig.version,
			storeName: dbConfig.storeName, 		// Should be alphanumeric, with underscores.
		});

		return lf;
	}

	/**
	* An internal function. Dont use this
	* @description 'window.caching' is a custom object used for caching by this library.
	* Each property on this object is an instance of the Cache() object.
	* In this manner multiple caches can be used
	*
	* @param {Object} dbConfig Config as per localforage. But only uses the appName
	* @return {Object} in memory instance 
	* @memberof Cache
	*/
	_useMemory(dbConfig) {
		if (!window.caching) window.caching = {};

		let db = window.caching[dbConfig.appName];		// Check for this 'db instance' by name
		if (!db) db = window.caching[dbConfig.appName] = new Map();

		return db;
	}

	/**
	 * Save or update a Value
	 *
	 * @param {String} key The key for this item
	 * @param {Object} value The Value to store. NB: (Must always be a string for SessionStorage)
	 * @return {Object} The return value from the save action
	 * @memberof Cache
	 */
	async set(key, value) {
		if (this.storageType === KEY_VALUE) {
			return await this.layer.set(key, value);
		} else if (this.storageType === MEM) {
			this.layer.set(key, value);
			return this.layer.get(key);
		} else if (this.storageType === SESSION_STORAGE) {
			return this.layer.setItem(key, value);
		} else if (this.storageType === DB) {
			return await this.layer.setItem(key, value);
		}
	}

	/**
	 * Return the Value for this key
	 *
	 * @param {String} key The key of the item being sought
	 * @return {Object} The value stores. (Always a string for SessionStorage) 
	 * @memberof Cache
	 */
	async get(key) {
		if (this.storageType === KEY_VALUE) {
			return await this.layer.get(key);
		} else if (this.storageType === MEM) {
			return this.layer.get(key);
		} else if (this.storageType === SESSION_STORAGE) {
			return this.layer.getItem(key);
		} else if (this.storageType === DB) {
			return await this.layer.getItem(key);
		}
	}


	/**
	 * Delete an item in cache
	 *
	 * @param {String} key
	 * @return {Object} The result of the delete action
	 * @memberof Cache
	 */
	async delete(key) {
		if (this.storageType === KEY_VALUE) {
			return await this.layer.del(key);
		} else if (this.storageType === MEM) {
			return this.layer.delete(key);
		} else if (this.storageType === SESSION_STORAGE) {
			return this.layer.removeItem(key);
		} else if (this.storageType === DB) {
			return await this.layer.removeItem(key);
		}
	}

	/**
	 * Delete many items in the DB using an Array of keys
	 *
	 * @param {Array} keys [key,key]
	 * @return {ThisType} this
	 * @memberof Cache
	 */
	async deleteMany(keys) {
		if (this.storageType === KEY_VALUE) {
			await this.layer.delMany(keys);
		} else {
			keys.forEach(async (key) => {
				await this.delete(key);
			});
		}

		return this;
	}

	/**
	 * Set multiple items at once using an array of items
	 * @description [[key,val],[key,val]]
	 *
	 * @param {Array} items An array of arrays
	 * @return {ThisType} this
	 * @memberof Cache
	 */
	async setMany(items) {
		if (this.storageType === KEY_VALUE) {
			await this.layer.setMany(items);
		} else {
			items.forEach(async (item) => {
				await this.set(item[0], item[1]);
			});
		}

		return this;
	}

	/**
	 * Return an array of value for all the keys passed
	 *
	 * @param {Array} keys array of strings
	 * @return {Array} 
	 * @memberof Cache
	 */
	async getMany(keys) {
		if (this.storageType === KEY_VALUE) {
			return await this.layer.getMany(keys);
		} else {
			const values = [];
			for (let i = 0; i < keys.length; i++) {
				const element = await this.get(keys[i]);
				values.push(element);
			}
			return values;
		}
	}

	/**
	 * return the key for an index position
	 *
	 * @param {Number} index
	 * @return {Array} 
	 * @memberof Cache
	 */
	async key(index) {
		const keys = await this.keys();
		return keys[index];
	}

	/**
	 * return an Array of the keys
	 *
	 * @return {Array} 
	 * @memberof Cache
	 */
	async keys() {
		if (this.storageType === SESSION_STORAGE) {
			const len = this.layer.length;
			const keys = [];
			for (let i = 0; i < len; i++) {
				keys.push(this.layer.key(i));
			}
			return keys;
		} else if (this.storageType === MEM) {
			return Array.from(this.layer.keys());
		} else {
			return await this.layer.keys();
		}
	}


	/**
	 * Return an Array of keyValues for this DB
	 *
	 * @return {Array} 
	 * @memberof Cache
	 */
	async entries() {
		if (this.storageType === KEY_VALUE) {
			return await this.layer.entries();
		} else if (this.storageType === MEM) {
			return Array.from(this.layer.entries());
		} else if (this.storageType === DB || this.storageType === SESSION_STORAGE) {
			const keys = await this.keys();
			const items = [];
			const len = keys.length;
			for (let i = 0; i < len; i++) {
				const k = keys[i];
				const val = await this.get(k);
				items.push([k, val]);
			}
			return items;
		}
	}

	/**
	 * @description Iterate the values in this collection and perform a function on each set of data
	 * (key, value, iterationNumber)
	 *
	 * @param {Function} fn with params of (key, value, iterationNumber)
	 * @return {ThisType} 
	 * @memberof Cache
	 */
	async iterate(fn) {
		const items = await this.entries();
		const len = await items.length;
		for (let i = 0; i < len; i++) {
			const item = items[i];
			fn(item[0], item[1], i);
		}

		return this;
	}


	/**
	 * Delete all the items in this DB
	 *
	 * @return {Object} The result of the clear action
	 * @memberof Cache
	 */
	async clear() {
		if (this.storageType === SESSION_STORAGE || this.storageType === MEM) {
			return this.layer.clear();
		} else
			return await this.layer.clear();
	}


	/**
	 * The Amount of items in this collection
	 *
	 * @return {Number} 
	 * @memberof Cache
	 */
	async length() {
		if (this.storageType === SESSION_STORAGE) {
			return this.layer.length;
		} else if (this.storageType === MEM) {
			return this.layer.size;
		} else if (this.storageType === KEY_VALUE) {
			return (await this.layer.entries()).length;
		} else if (this.storageType === DB) {
			return this.layer.length();
		}
	}
}



