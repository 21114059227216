import { ValidationModel } from './base/ValidationModel.jsx';

const PriceAndCustomOptionsProperties = {
  hasCustomOptions: 'no', // TODO: turn this into a boolean
  cost: 0.0,
  retail: 0.0,
  price: 0.0,
  rebateDiscount: 0,
  soh: 0.0,
  surcharge: 0.0,
  surcharges: [],
  adminCost: 'ADMINCOST_17_50',
  vat: true,
  insurance: false,
  insuranceValue: 0.0,
  insuranceOverride: false,
  isDisplayRetail: true,
  isSavingsInRands: false,
};

const PriceAndCustomOptionsValidators = {
  partial: {
    price: {
      validator: x => x.price >= 0,
      error: 'Please enter a price for this deal',
    },
  },
  complete: {
    retail: {
      validator: x => x.retail > 0,
      error: 'No retail price entered',
    },
    costLessThanPrice: {
      validator: x => +x.cost < +x.price,
      error: 'WARNING: Cost is greater value than price',
    },
    cost: {
      validator: x => x.cost > 0,
      error: 'Please enter a cost for this deal',
    },
  },
};

export class DealPriceAndCustomOptions extends ValidationModel {
  constructor() {
    super(PriceAndCustomOptionsProperties, PriceAndCustomOptionsValidators);

    this.hasCustomOptions;
    this.cost;
    this.retail;
    this.price;
    this.rebateDiscount;
    this.soh;
    this.surcharge;
    this.surcharges;
    this.adminCost;
    this.vat;
    this.insurance;
    this.insuranceOverride;
    this.insuranceValue;
    this.customOptions;
  }
}

DealPriceAndCustomOptions.FIELDS = {
  HAS_CUSTOM_OPTIONS: 'hasCustomOptions',
  COST: 'cost',
  RETAIL: 'retail',
  PRICE: 'price',
  REBATE: 'rebateDiscount',
  SOH: 'soh',
  SURCHARGE: 'surcharge',
  SURCHARGES: 'surcharges',
  ADMIN: 'adminCost',
  VAT: 'vat',
  INSURANCE: 'insurance',
  INSURANCE_VALUE: 'insuranceValue',
  INSURANCE_OVERRIDE: 'insuranceOverride',
  CUSTOM_OPTIONS: 'customOptions',
  IS_DISPLAY_RETAIL: 'isDisplayRetail',
  IS_SAVINGS_IN_RANDS: 'isSavingsInRands',
};
