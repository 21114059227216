import type { GridProduct } from '@odo/components/search/types';
import LabelledGrid from '@odo/components/elements/labelled-grid';
import { zarFormatter } from '@odo/utils/currency';

const savingsPercent = (price, retail) =>
  Math.round((1 - price / retail) * 100);
const savingsRands = (price, retail) => retail - price;

const Pricing = ({ data }: { data: GridProduct }) => (
  <LabelledGrid
    rows={[
      { label: 'Cost', value: data.cost ? zarFormatter(data.cost) : '' },
      { label: 'Price', value: data.price ? zarFormatter(data.price) : '' },
      {
        label: 'Retail',
        value: data.retail ? zarFormatter(data.retail) : '',
      },
      {
        label: 'Savings',
        value: data.isSavingsInRands
          ? `${zarFormatter(savingsRands(data.price, data.retail))}`
          : `${savingsPercent(data.price, data.retail)}%`,
      },
    ]}
  />
);

export default Pricing;
