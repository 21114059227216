import { createClient } from '@odo/services/urql';
import type { ImageType } from '@odo/types/api';
import type { BaseProductImage } from '@odo/types/api-new';
import { throwGraphQLError } from '@odo/utils/graphql';
import { gql } from 'urql';

/**
 * NOTE: because each image is a data url and can be quite large, they can easily exceed post max size.
 * So although this mutation supports sending multiple images, we're going to hardcode it to only send one at a time.
 */
const CREATE_PRODUCT_IMAGE = gql`
  mutation createProductImage($productId: ID!, $image: ImageInput!) {
    createProductImages(productId: $productId, imageFiles: [$image]) {
      ... on Image {
        id
        position
        url
        imageTypes
        label
        excludeImageTypes
      }
      ... on ResponseMessage {
        status
        code
        message
      }
    }
  }
`;

interface ClientParams {
  signal?: AbortSignal;
}

export interface ImageInput {
  mimetype: string;
  image: string;
  filename?: string;
  position?: number;
  imageTypes?: ImageType[];
  label?: string;
  excludeImageTypes?: 1 | 0;
}

interface MutationCreateProductImageArgs {
  productId: string;
  image: ImageInput;
}

interface MutationCreateProductImageOutput {
  createProductImages: [BaseProductImage];
}

type MutationCreateProductImageParams = {
  id: MutationCreateProductImageArgs['productId'];
  image: MutationCreateProductImageArgs['image'];
} & ClientParams;

export const mutationCreateProductImage = async ({
  id,
  image,
  signal,
}: MutationCreateProductImageParams) => {
  const { data, error } = await createClient({ signal })
    .mutation<MutationCreateProductImageOutput, MutationCreateProductImageArgs>(
      CREATE_PRODUCT_IMAGE,
      { productId: id, image }
    )
    .toPromise();

  // only throw errors if we don't get any data back
  if (!(data && data.createProductImages) && error) {
    throwGraphQLError(error);
  }

  let createdImage: BaseProductImage | undefined;
  if (data && data.createProductImages) {
    [createdImage] = data.createProductImages;
  }

  return createdImage;
};
