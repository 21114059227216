import {
  useChangeProduct,
  useCurrentProduct,
} from '@odo/contexts/product-editor';
import { queryProductBySKU } from '@odo/graphql/product-new';
import type { GetProductInterface } from '@odo/types/api-new';
import { SkuAvailability } from '@odo/types/portal';
import { debounce } from '@odo/utils/debounce';
import { error } from '@odo/utils/toast';
import { useEffect, useRef } from 'react';

const SKU_AVAILABILITY_CHECK_TOAST_ID = 'sku-availability-check-error';

const isSkuAvailable = async ({
  sku,
  id,
  signal,
  setAvailability,
}: {
  sku: GetProductInterface['sku'];
  id: GetProductInterface['id'];
  signal: AbortSignal;
  setAvailability: (availability: SkuAvailability | undefined) => void;
}) => {
  if (!sku) return;

  let isActive = true;
  setAvailability(SkuAvailability.checking);

  signal.addEventListener('abort', () => (isActive = false));

  let availability: SkuAvailability | undefined;
  try {
    const products = await queryProductBySKU({ sku, signal });

    // just exit if we've dismounted while the API call was running
    if (!isActive) return;

    if (!products || products.length === 0) {
      availability = SkuAvailability.available;
    } else if (products.find(p => p.id === id)) {
      availability = SkuAvailability.owned;
    } else {
      availability = SkuAvailability.taken;
    }

    setAvailability(availability);
  } catch (e) {
    if (!isActive) return;

    setAvailability(SkuAvailability.validationFailed);

    console.error(e);
    error(
      e instanceof Error && typeof e.message === 'string'
        ? e.message
        : `Error checking availability of SKU: "${sku}"`,
      { id: SKU_AVAILABILITY_CHECK_TOAST_ID }
    );
  }
};

const SkuAvailable = () => {
  const currentProduct = useCurrentProduct();
  const change = useChangeProduct();

  const originalSku = useRef<string | undefined>(currentProduct.sku);
  const prevCheckedSku = useRef<string | undefined>(currentProduct.sku);

  /**
   * Listen for changes to SKU field, and check availability.
   */
  useEffect(() => {
    const sku = currentProduct.sku;
    const id = currentProduct.id;

    // we don't want to run our validation when the SKU is reset (or on initial load)
    if (id && sku === originalSku.current) return;
    // we don't want to accidentally run the validation again
    if (!sku || sku === prevCheckedSku.current) return;

    const controller = new AbortController();

    debounce(
      isSkuAvailable,
      750,
      controller.signal
    )({
      sku,
      id,
      signal: controller.signal,
      setAvailability: availability => {
        prevCheckedSku.current = sku;
        change({
          fieldId: 'skuAvailability',
          label: 'SKU Availability',
          apply: to => (to.skuAvailability = availability),
        });
      },
    });

    return () => controller.abort();
  }, [currentProduct.sku, currentProduct.id, change]);

  /**
   * Set availability back to owned if the user manually changes SKU back to original.
   * A full "reset" will do this automatically.
   */
  useEffect(() => {
    const sku = currentProduct.sku;
    const skuAvailability = currentProduct.skuAvailability;
    const id = currentProduct.id;

    if (
      id &&
      sku === originalSku.current &&
      skuAvailability !== SkuAvailability.owned
    ) {
      change({
        fieldId: 'skuAvailability',
        label: 'SKU Availability',
        apply: to => (to.skuAvailability = SkuAvailability.owned),
      });
    }
  }, [
    currentProduct.sku,
    currentProduct.skuAvailability,
    currentProduct.id,
    change,
  ]);

  return null;
};

export default SkuAvailable;
