import { cssColor } from '@odo/utils/css-color';
import type { SpaceProps, ColorProps, LayoutProps } from '@odo/lib/styled';
import styled, { compose, space, color, layout } from '@odo/lib/styled';

type StatusDotProps = SpaceProps & ColorProps & LayoutProps;

const StatusDot = styled.span<StatusDotProps>`
  border-radius: 1000px;
  border: 2px solid ${cssColor('foreground')};
  box-shadow: 0px 1px 2px hsl(0deg 0% 0% / 0.2),
    0px 2px 4px hsl(0deg 0% 0% / 0.1);

  ${compose(space, color, layout)}
`;

StatusDot.defaultProps = {
  size: '12px',
  bg: cssColor('grey-ultra-light'),
  mb: '2px',
};

export default StatusDot;
