import { gql } from '@apollo/client';

export const GET_MANAGE_DEALS = gql`
  query GetDeals($filter: ProductFilter) {
    getProducts(filter: $filter)
  }
`;

export const GET_DEALS_GRID = gql`
  query GetProducts($filter: InputProductFilter) {
    getProducts(filter: $filter) {
      id
      buyer
      salesAssistant
      supplier
      brand
      sku
      url
      name
      shortName
      price
      cost
      rebateDiscount
      adminCost
      retail
      activeFromDate
      activeToDate
      videos # FIXME: why is this needed?
      status
      pricing
      platform
      priority
      inventory {
        qty
      }
    }
  }
`;

export const GET_ODO_DEALS = gql`
  query GetProducts($filter: InputProductFilter) {
    getProducts(filter: $filter) {
      id
      buyer
      salesAssistant
      supplier
      isSupplierNew
      type
      brand
      sku
      url
      name
      shortName
      price
      cost
      rebateDiscount
      adminCost
      isDisplayRetail
      retail
      isSavingsInRands
      activeFromDate
      activeToDate
      area
      isAlcoholic
      isHygienic
      isParallelImport
      isReferable
      additionalInfo
      calloutText
      lockdownText
      condition
      isFragile
      isReturnableToSupplier
      warrantyPeriod
      warranty
      features
      videos
      leftAdditionalInfo
      moreDetails
      status
      taxClass
      pricing
      isLunchtimeProduct
      isInLunchtimeProductMailer
      isBestSeller
      platform
      isMainDeal
      campaign
      campaignMailer
      isBuyerSignedOff
      isSupplierSignedOff
      isFinalSignOff
      isSellout
      metaDescription
      metaTitle
      specialPrice
      visibility
      setNewFromDate
      setNewToDate
      countryManufactured
      applyMap
      manufacturerDisplayActualPrice
      manufacturerRetailPrice
      isGoogleCheckout
      metaKeywords
      isShippingApplied
      width
      length
      height
      weight
      isShippedIndividually
      pillOne
      pillTwo
      shippingCost
      isDeliveredBySupplier
      supplierRepacks
      originalStock
      surcharge
      discount
      priority
      isMembersOnly
      description
      shortDescription
      tabContentOne
      tabContentTwo
      categories {
        categoryName
        categoryId
      }
      preview
      buyInStockType
      isSampleReceived
      isPhotographedByStudio
      inventory {
        qty
      }
    }
  }
`;
