import { useCurrentDealSource } from 'hooks/useCurrentDealSource';
import { useSafeEffect } from 'hooks/useSafeEffect';
import { Deal } from 'models/Deal';
import styles from './css/BusySpinner.module.css';
import PropTypes from 'prop-types';
import { queryCustomOptions } from '@odo/graphql/product/custom-options.ts';
import { useSetProduct } from '@odo/contexts/product';
import { useEffect, useState } from 'react';
import { useApplyDefaultAttributes } from 'hooks/useApplyDefaultAttributes';
import { loadRpsDeal } from '@odo/helpers/rps-deals.ts';
import { error } from '@odo/utils/toast';

const DealLoader = ({ dealId, isDealReady, setIsDealReady }) => {
  const setProduct = useSetProduct();
  const [productResetRequired, setProductResetRequired] = useState(true);
  const [hasLoadedDeal, setHasLoadedDeal] = useState(false);

  const [areAttributesReady, applyDefaultAttributes] =
    useApplyDefaultAttributes();

  const { deal, update, newDealComplete } = useCurrentDealSource();

  /**
   * NOTE: product context needs to be wrapped around the entire app @see /src/App.js
   * This means that sometimes the previous product data is still in context when this is running.
   * The suspicion is that this can cause unsaved custom options to carry over to other products.
   * We can replicate this for new deals, but not existing.
   * For now we'll reset the product data before continuing and hope that resolves the issue.
   * TODO: BP-412, BP-413: remove this reset.
   */
  useEffect(() => {
    setProduct(undefined);
    setProductResetRequired(false);
  }, [setProduct]);

  useSafeEffect(() => {
    // wait for the product context to be reset
    if (productResetRequired) return;

    if (dealId && !hasLoadedDeal) {
      (async () => {
        try {
          const dealModel = new Deal();
          const { product } = await loadRpsDeal({ id: dealId, dealModel });

          const customOptions = await queryCustomOptions({
            productId: product.id,
          });

          // put the product and it's custom options into context
          setProduct({
            source: 'magento',
            id: product.id,
            product,
            customOptions,
          });

          newDealComplete(dealModel);

          setHasLoadedDeal(true);
        } catch (e) {
          console.error(e);
          error(
            e instanceof Error && typeof e.message === 'string'
              ? e.message
              : 'There was an error loading the product. Please try again and let us know if the issue persists',
            { id: 'deal-loader-error' }
          );
        }
      })();
    } else if (dealId && deal.id === dealId) {
      setHasLoadedDeal(true);
    }
  }, [productResetRequired, setProduct, dealId, setIsDealReady, hasLoadedDeal]);

  useEffect(() => {
    if (!isDealReady && hasLoadedDeal && areAttributesReady) {
      applyDefaultAttributes({ dealModel: deal, dealUpdate: update });
      setIsDealReady(true);
    }
  }, [
    isDealReady,
    deal,
    update,
    setIsDealReady,
    hasLoadedDeal,
    areAttributesReady,
    applyDefaultAttributes,
  ]);

  // render a loader while we load & prep the deal model
  if (!isDealReady) {
    return (
      <div className={styles.busySpinnerContainerNonBlocking}>
        <rps-card>
          <div className={styles.busySpinner}>
            <rps-spinner-pause />
            Loading deal from backend...
          </div>
        </rps-card>
      </div>
    );
  }

  return null;
};

DealLoader.propTypes = {
  dealId: PropTypes.string,
  isDealReady: PropTypes.bool,
  setIsDealReady: PropTypes.func,
};

export default DealLoader;
