import type {
  ApiCustomOption,
  ApiCustomOptionValue,
  ApiProduct,
  CustomOptionsInput,
  CustomOptionValuesInput,
  MutationCreateCustomOptionsArgs,
  MutationRemoveCustomOptionArgs,
  MutationUpdateCustomOptionArgs,
  ProductInventory,
  UpdateCustomOptionValuesInput,
  UpdateOptionInput,
} from '@odo/types/api';
import type { NonEmptyArray } from '@odo/types/helpers';
import type { CustomOptionTree } from '@odo/types/portal';
import type { ProductID } from '@odo/contexts/product';

export enum ActionTypeEnum {
  PasteOptions = 'pasteOptions',
  AddOption = 'addOption',
  AddValue = 'addValue',
  UpdateOption = 'updateOption',
  UpdateValue = 'updateValue',
  SwapOptions = 'swapOptions',
  SwapValues = 'swapValues',
  RemoveOption = 'removeOption',
  RemoveValue = 'removeValue',
}

interface BaseAction {
  type: ActionTypeEnum;
  excludeOnAutoSumEnabled?: boolean;
}

export type PastedOptionValue = Required<
  Pick<
    ApiCustomOptionValue,
    'title' | 'sku' | 'price' | 'cost' | 'quantity' | 'sortOrder'
  >
> & {
  tmpValueId: ApiCustomOptionValue['valueId'];
};

export type PastedOption = Required<
  Pick<ApiCustomOption, 'title' | 'sortOrder'>
> & {
  tmpOptionId: ApiCustomOption['id'];
  values: PastedOptionValue[];
  parentValueId?: ApiCustomOptionValue['valueId'];
};

interface ActionPasteOptions extends BaseAction {
  type: ActionTypeEnum.PasteOptions;
  productId: ApiCustomOption['productId'];
  options: PastedOption[];
}

interface ActionAddOption extends BaseAction {
  type: ActionTypeEnum.AddOption;
  productId: ApiCustomOption['productId'];
  tmpOptionId: ApiCustomOption['id'];
  tmpValueId: ApiCustomOptionValue['valueId'];
  parentValueId?: ApiCustomOptionValue['valueId'];
}

interface ActionAddValue extends BaseAction {
  type: ActionTypeEnum.AddValue;
  optionId: ApiCustomOption['id'];
  tmpValueId: ApiCustomOptionValue['valueId'];
  parentValueId?: ApiCustomOptionValue['valueId'];
}

interface ActionUpdateOption extends BaseAction {
  type: ActionTypeEnum.UpdateOption;
  id: ApiCustomOption['id'];
  fields: Partial<Pick<ApiCustomOption, 'title'>>;
}

interface ActionUpdateValue extends BaseAction {
  type: ActionTypeEnum.UpdateValue;
  optionId: ApiCustomOption['id'];
  valueId: ApiCustomOptionValue['valueId'];
  fields: Partial<
    Pick<ApiCustomOptionValue, 'title' | 'sku' | 'price' | 'cost' | 'quantity'>
  >;
}

interface ActionSwapOptions extends BaseAction {
  type: ActionTypeEnum.SwapOptions;
  optionAId: ApiCustomOption['id'];
  optionBId: ApiCustomOption['id'];
}

interface ActionSwapValues extends BaseAction {
  type: ActionTypeEnum.SwapValues;
  optionId: ApiCustomOption['id'];
  valueAId: ApiCustomOptionValue['valueId'];
  valueBId: ApiCustomOptionValue['valueId'];
}

interface ActionRemoveOption extends BaseAction {
  type: ActionTypeEnum.RemoveOption;
  id: ApiCustomOption['id'];
  parentValueId?: ApiCustomOptionValue['valueId'];
}

interface ActionRemoveValue extends BaseAction {
  type: ActionTypeEnum.RemoveValue;
  optionId: ApiCustomOption['id'];
  valueId: ApiCustomOptionValue['valueId'];
  parentValueId?: ApiCustomOptionValue['valueId'];
}

export type Action =
  | ActionPasteOptions
  | ActionAddOption
  | ActionAddValue
  | ActionUpdateOption
  | ActionUpdateValue
  | ActionSwapOptions
  | ActionSwapValues
  | ActionRemoveOption
  | ActionRemoveValue;

export interface ProductMeta {
  id: ProductID;
  stockId?: ProductInventory['id'];
  price?: number;
  cost?: number;
  qty?: number;
}

export interface CustomOptionsEditorContextType {
  autoSumEnabled: boolean;
  canUndo: boolean;
  canRedo: boolean;
  canSave: boolean;
  hasUnsavedActions: boolean;
  canClearActions: boolean;
  isSaving: boolean;
  showErrors: boolean;
  productMeta?: ProductMeta;
  editorCustomOptions: CustomOptionTree[];
  copyingOptionId?: ApiCustomOption['id'];
  toggleAutoSumEnabled: () => void;
  copyOption: (option: CustomOptionTree) => void;
  pasteOption: (parentValueId?: ApiCustomOptionValue['valueId']) => void;
  cancelCopy: () => void;
  validate: () => boolean;
  addAction: (action: Action) => void;
  moveActionOffset: (offset: number) => void;
  saveActions: (args: {
    productId: ApiProduct['id'];
    stockId?: ProductInventory['id'];
    latestQty?: ProductInventory['qty'];
  }) => Promise<void> | undefined;
  clearActions: () => void;
  getCumulativeQty: (
    valueQtyLatest?: Record<ApiCustomOptionValue['valueId'], number>
  ) => number | undefined;
}

/**
 * Save Custom Options Types.
 */
export type MutationID = string;

interface SaveCustomOptionsMutationMeta {
  mutationId: MutationID;
  dependentId?: MutationID;
  actions: Action[];
  customOptionIds: ApiCustomOption['id'][];
  valueIds: ApiCustomOptionValue['valueId'][];
  completed?: boolean;
  failed?: boolean;
  ready?: boolean;
  disabled?: boolean;
}

export interface SaveUtilities {
  getNewGroupId: () => number;
  getNewSortOrderForOption: () => number;
  getProductId: () => ApiProduct['id'];
}

export interface MutationBase {
  meta: SaveCustomOptionsMutationMeta;
}

/**
 * Create Custom Options Types
 */
export type CustomOptionValuesInputWithTmpId = CustomOptionValuesInput & {
  tmpValueId: string;
};

export type CustomOptionsInputWithTmpId = Omit<CustomOptionsInput, 'values'> & {
  tmpOptionId: string;
  values: CustomOptionValuesInputWithTmpId[];
};

type MutationCreateCustomOptionsArgsWithTmpId = Omit<
  MutationCreateCustomOptionsArgs,
  'customOptions'
> & {
  customOptions: NonEmptyArray<CustomOptionsInputWithTmpId>;
};

export interface CreateCustomOptionsMutation extends MutationBase {
  args: MutationCreateCustomOptionsArgsWithTmpId;
}

/**
 * Update Custom Option Types
 */
export type UpdateCustomOptionValuesInputWithTmpId =
  UpdateCustomOptionValuesInput & {
    tmpValueId?: string;
  };

export type UpdateOptionInputWithTmpId = Omit<UpdateOptionInput, 'values'> & {
  values: UpdateCustomOptionValuesInputWithTmpId[];
};

type MutationUpdateCustomOptionArgsWithTmpId = Omit<
  MutationUpdateCustomOptionArgs,
  'customOption'
> & {
  customOption: UpdateOptionInputWithTmpId;
};

export interface UpdateCustomOptionMutation extends MutationBase {
  args: MutationUpdateCustomOptionArgsWithTmpId;
}

/**
 * Remove Custom Option Types
 */
export interface RemoveCustomOptionMutation extends MutationBase {
  args: MutationRemoveCustomOptionArgs;
}

export interface SaveCustomOptionsMutations {
  // NOTE: the create mutation supports multiple options in one go, but we're not using it for now
  createCustomOptions: CreateCustomOptionsMutation[];
  updateCustomOption: UpdateCustomOptionMutation[];
  removeCustomOption: RemoveCustomOptionMutation[];
}

/**
 * Type Guards.
 */

export const isActionPasteOptions = (
  action: Action
): action is ActionPasteOptions => action.type === ActionTypeEnum.PasteOptions;

export const isActionAddOption = (action: Action): action is ActionAddOption =>
  action.type === ActionTypeEnum.AddOption;

export const isActionAddValue = (action: Action): action is ActionAddValue =>
  action.type === ActionTypeEnum.AddValue;

export const isActionUpdateOption = (
  action: Action
): action is ActionUpdateOption => action.type === ActionTypeEnum.UpdateOption;

export const isActionUpdateValue = (
  action: Action
): action is ActionUpdateValue => action.type === ActionTypeEnum.UpdateValue;

export const isActionSwapOptions = (
  action: Action
): action is ActionSwapOptions => action.type === ActionTypeEnum.SwapOptions;

export const isActionSwapValues = (
  action: Action
): action is ActionSwapValues => action.type === ActionTypeEnum.SwapValues;

export const isActionRemoveOption = (
  action: Action
): action is ActionRemoveOption => action.type === ActionTypeEnum.RemoveOption;

export const isActionRemoveValue = (
  action: Action
): action is ActionRemoveValue => action.type === ActionTypeEnum.RemoveValue;
