import SkuAvailable from './sku-available';
import SurchargeWeight from './surcharge-weight';
import SurchargeTotal from './surcharge-total';
import UniqueImagePositions from './unique-image-positions';
import SyncPriceToCustomOptions from '@odo/screens/deal/editor/side-effects/sync-price-to-custom-options';

const SideEffects = () => (
  <>
    <SkuAvailable />
    <SurchargeWeight />
    <SurchargeTotal />
    <UniqueImagePositions />
    <SyncPriceToCustomOptions />
  </>
);

export default SideEffects;
