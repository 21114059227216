import type {
  JustifyContentProps,
  AlignContentProps,
  AlignItemsProps,
  FlexDirectionProps,
  FlexWrapProps,
  GapProps,
  FontFamilyProps,
  SpaceProps,
  ColorProps,
  BorderProps,
  FontSizeProps,
  FontWeightProps,
  LineHeightProps,
  LayoutProps,
} from '@odo/lib/styled';
import styled, {
  compose,
  fontFamily,
  justifyContent,
  alignContent,
  alignItems,
  flexDirection,
  flexWrap,
  gap,
  space,
  color,
  border,
  fontSize,
  fontWeight,
  lineHeight,
  keyframes,
  layout,
} from '@odo/lib/styled';
import { cssColor } from '@odo/utils/css-color';
import { conditionalJoin } from '@odo/utils/string';
import { forwardRef, type ButtonHTMLAttributes, type ReactNode } from 'react';

type StyledButtonProps = JustifyContentProps &
  AlignContentProps &
  AlignItemsProps &
  FlexDirectionProps &
  FlexWrapProps &
  GapProps &
  FontFamilyProps &
  FontSizeProps &
  FontWeightProps &
  LineHeightProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  BorderProps & {
    colorOverride?: string;
  };

const LOADING_INDICATOR_SIZE = 24;

const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
`;

/**
 * NOTE: cannot use styled(Flex) as that brings in the HTMLDivElement attributes
 * which conflict with HTMLButtonElement attributes.
 */
const StyledButton = styled.button<StyledButtonProps>`
  cursor: pointer;
  user-select: none;
  outline-offset: 4px;

  width: fit-content;
  max-width: 100%;

  display: flex;
  text-align: center;

  &.uppercase {
    text-transform: uppercase;
  }

  transition: all 200ms ease, transform 200ms ease 100ms;

  --base: ${cssColor('white')};
  --base-light: ${cssColor('off-white')};

  &.hue-blue {
    --hue-base: ${cssColor('palette-blue')};
    --hue-light: ${cssColor('palette-blue-light')};
    --hue-muted: ${cssColor('palette-blue-muted')};
    --hue-font: var(--hue-base);
    --hue-border: var(--hue-base);
  }

  &.hue-green {
    --hue-base: ${cssColor('palette-green')};
    --hue-light: ${cssColor('palette-green-light')};
    --hue-muted: ${cssColor('palette-green-muted')};
    --hue-font: var(--hue-base);
    --hue-border: var(--hue-base);
  }

  &.hue-turquoise {
    --hue-base: ${cssColor('palette-turquoise')};
    --hue-light: ${cssColor('palette-turquoise-light')};
    --hue-muted: ${cssColor('palette-turquoise-muted')};
    --hue-font: var(--hue-base);
    --hue-border: var(--hue-base);
  }

  &.hue-violet {
    --hue-base: ${cssColor('palette-violet')};
    --hue-light: ${cssColor('palette-violet-light')};
    --hue-muted: ${cssColor('palette-violet-muted')};
    --hue-font: var(--hue-base);
    --hue-border: var(--hue-base);
  }

  &.hue-yellow {
    --hue-base: ${cssColor('palette-yellow')};
    --hue-light: ${cssColor('palette-yellow-light')};
    --hue-muted: ${cssColor('palette-yellow-muted')};
    --hue-font: var(--hue-base);
    --hue-border: var(--hue-base);
  }

  &.hue-pink {
    --hue-base: ${cssColor('palette-pink')};
    --hue-light: ${cssColor('palette-pink-light')};
    --hue-muted: ${cssColor('palette-pink-muted')};
    --hue-font: var(--hue-base);
    --hue-border: var(--hue-base);
  }

  &.hue-red {
    --hue-base: ${cssColor('palette-red')};
    --hue-light: ${cssColor('palette-red-light')};
    --hue-muted: ${cssColor('palette-red-muted')};
    --hue-font: var(--hue-base);
    --hue-border: var(--hue-base);
  }

  &.hue-grey {
    --hue-base: ${cssColor('dark-grey')};
    --hue-light: ${cssColor('grey')};
    --hue-muted: ${cssColor('off-white')};
    --hue-font: var(--hue-base);
    --hue-border: var(--hue-base);
  }

  &.hue-white {
    --hue-base: ${cssColor('white')};
    --hue-light: ${cssColor('grey-light')};
    --hue-muted: ${cssColor('grey')};
    --base: ${cssColor('black')};
    --base-light: ${cssColor('grey')};
    --hue-font: var(--hue-base);
    --hue-border: var(--hue-base);
  }

  &.plain-border {
    --hue-border: ${cssColor('grey-light')};
  }

  --loading: #fff;
  &:disabled {
    cursor: not-allowed;
  }

  &.variant-solid {
    --loading: ${cssColor('dark-grey')};
    color: var(--base);
    background: var(--hue-base);
    border-color: var(--hue-base);

    &:active,
    &:hover {
      background: var(--hue-light);
    }

    &:disabled {
      --loading: #fff;
      background: ${cssColor('grey')};
      border-color: ${cssColor('grey')};
    }
  }

  &.variant-outlined {
    --loading: ${cssColor('dark-grey')};
    color: var(--hue-font);
    background: transparent;
    border-color: var(--hue-border);

    &:active,
    &:hover {
      background: var(--base-light);
    }

    &:disabled {
      --loading: var(--hue-base);
      color: ${cssColor('grey')};
      border-color: ${cssColor('grey')};
    }
  }

  &.variant-flat {
    --loading: ${cssColor('dark-grey')};
    color: var(--hue-font);
    background: transparent;
    border-color: transparent;

    &:active,
    &:hover {
      color: var(--hue-light);
    }

    &:disabled {
      --loading: var(--hue-base);
      color: ${cssColor('grey')};
    }
  }

  &.loading {
    position: relative;
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      border: 3px solid transparent;
      border-left-color: var(--loading);
      border-radius: 50%;
      box-sizing: border-box;

      top: calc(50% - ${LOADING_INDICATOR_SIZE / 2}px);
      left: calc(50% - ${LOADING_INDICATOR_SIZE / 2}px);
      width: ${LOADING_INDICATOR_SIZE}px;
      height: ${LOADING_INDICATOR_SIZE}px;

      animation: ${rotateAnimation} 0.8s linear infinite;
    }
  }

  &.circular {
    aspect-ratio: 1 / 1;
    border-radius: 50%;
  }

  will-change: transform;
  &:active {
    transform: scale(0.95);
  }

  ${compose(
    justifyContent,
    alignContent,
    alignItems,
    flexDirection,
    flexWrap,
    gap,
    fontFamily,
    fontSize,
    fontWeight,
    lineHeight,
    space,
    layout,
    color,
    border
  )}
`;

StyledButton.defaultProps = {
  justifyContent: 'center',
  alignItems: 'center',
  gap: '10px',
  px: 3,
  py: 2,
  fontFamily: 'heading',
  fontWeight: 700,
  fontSize: '1.2rem',
  lineHeight: '1.1em',
  borderRadius: '30px',
  borderStyle: 'solid',
  borderWidth: '2px',
};

export type ButtonProps = {
  children: ReactNode;
  hue:
    | 'blue'
    | 'green'
    | 'turquoise'
    | 'violet'
    | 'yellow'
    | 'pink'
    | 'red'
    | 'grey'
    | 'white';
  variant: 'solid' | 'outlined' | 'flat';
  circular?: boolean;
  loading?: boolean;
  colorOverride?: string;
  noCasing?: boolean;
} & StyledButtonProps &
  ButtonHTMLAttributes<HTMLButtonElement>;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      hue,
      variant,
      circular,
      loading,
      className,
      colorOverride,
      noCasing,
      ...rest
    },
    ref
  ) => (
    <StyledButton
      className={conditionalJoin([
        `hue-${hue}`,
        `variant-${variant}`,
        ['circular', !!circular],
        ['loading', !!loading],
        ['uppercase', !noCasing],
        ...(className ? [className] : []),
      ])}
      colorOverride={colorOverride}
      ref={ref}
      {...rest}
    >
      {children}
    </StyledButton>
  )
);

export default Button;
