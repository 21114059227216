// Stopgap solution during bugfix phase - input conditions need to be fixed in RPSInput eventually
import React from 'react';
import PropTypes from 'prop-types';
import styles from './css/DateInputTemp.module.css';

export const DateInputTemp = ({ cbInput, value, label, ...rest }) => {
  return (
    <div className="input">
      <rps-input-label text={label} />
      <input
        type="date"
        max="9999-12-31"
        onChange={ev => {
          if (cbInput) {
            cbInput({
              ...ev,
              detail: {
                value: ev.target.value,
                checked: false,
                source: 'RPS-INPUT',
                id: '',
                inputControl: {},
                isValid: true,
              },
            });
          }
        }}
        value={value}
        className={styles.dateInput}
        {...rest}
      />
    </div>
  );
};

DateInputTemp.propTypes = {
  cbInput: PropTypes.func,
  label: PropTypes.string,
  value: PropTypes.string,
};
