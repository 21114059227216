import {
  useChangeProduct,
  useCurrentProduct,
} from '@odo/contexts/product-editor';
import { excelRounding } from '@odo/helpers/calculations/general';
import type { EditorProductInterface } from '@odo/types/portal';
import { dismiss, timer } from '@odo/utils/toast';
import { isNumeric } from '@odo/utils/validation';
import { useEffect, useRef } from 'react';

const TOAST_ID = 'auto-total-surcharge';

const calcSurchargeTotal = (
  allSurcharges: EditorProductInterface['surcharges']
) =>
  (allSurcharges || []).reduce((total, surcharge) => {
    if (isNumeric(surcharge.value.number)) {
      return total + (surcharge.value.number || 0);
    } else {
      return total;
    }
  }, 0);

const SurchargeTotal = () => {
  const { surcharge, surcharges } = useCurrentProduct();
  const change = useChangeProduct();

  const prevSurchargesRef = useRef({ surcharge, surcharges: surcharges || [] });

  useEffect(() => {
    const nextSurcharges = surcharges || [];

    const hasChanges = nextSurcharges.some(nextSurcharge => {
      const prevSurcharge = prevSurchargesRef.current.surcharges.find(
        prev => prev.id === nextSurcharge.id
      );
      if (!prevSurcharge || prevSurcharge.value !== nextSurcharge.value) {
        return true;
      }
      return false;
    });

    if (!hasChanges) return;
    prevSurchargesRef.current.surcharges = nextSurcharges;

    const updateSurchargeTotal = () => {
      const total = excelRounding(calcSurchargeTotal(nextSurcharges));

      if (total === prevSurchargesRef.current.surcharge) return;
      prevSurchargesRef.current.surcharge = total;

      const { undo } = change({
        fieldId: 'surcharge',
        label: 'Surcharge (TOTAL)',
        apply: to => {
          to.surcharge = total;
          return to;
        },
      });

      const toastId = timer(
        <span>
          Total surcharge automatically updated to{' '}
          <b style={{ wordBreak: 'keep-all' }}>
            {total < 0 ? `-R${Math.abs(total)}` : `R${total}`}
          </b>
        </span>,
        {
          id: TOAST_ID,
          timeInMs: 5000,
          messageOptions: {
            action: {
              label: 'Undo',
              callback: () => {
                undo();
                dismiss(toastId);
              },
            },
          },
        }
      );
    };

    // wait a little bit so we don't spam them while they're typing
    const timeoutId = setTimeout(() => updateSurchargeTotal(), 1250);

    return () => clearTimeout(timeoutId);
  }, [change, surcharges]);

  return null;
};

export default SurchargeTotal;
