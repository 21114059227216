import type { CanvasHTMLAttributes } from 'react';
import { useEffect, useRef } from 'react';

const draw = ({
  ctx,
  width,
  height,
  length,
  setBase,
}: {
  ctx: CanvasRenderingContext2D;
  width: number;
  height: number;
  length: number;
  setBase?: (base: { base: number; widthInPx: number }) => void;
}) => {
  ctx.fillStyle = '#A97835';
  ctx.strokeStyle = '#CD9F61';
  const canvasWidth = ctx.canvas.getBoundingClientRect().width;
  const canvasHeight = ctx.canvas.getBoundingClientRect().height;
  const factor = Math.max(width, height, length);
  const setWidth = (width / factor) * (canvasWidth / 2);
  const setHeight = (height / factor) * (canvasHeight / 2);
  const setLength = (length / factor) * (canvasHeight / 4);

  const frontMidPoint = {
    x: canvasWidth / 2 + setLength / 2,
    y: canvasHeight / 2 + setLength / 2,
  };

  const offset = {
    x: setWidth / 2,
    y: setHeight / 2,
  };

  ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);

  if (setBase) {
    // find the smallest dimension
    const smallest = Math.min(width, height, length);
    // get it's base 10 logarithm rounded down, and then get 10 to the power of said base
    const base = Math.pow(10, Math.floor(Math.log10(smallest)));
    // the width in px is going to be the half the canvas width, divided by the drawing factor
    const widthInPx = base * (canvasWidth / 2 / factor);

    setBase({ base, widthInPx });
  }

  // Front face
  ctx.beginPath();
  ctx.moveTo(frontMidPoint.x - offset.x, frontMidPoint.y - offset.y);
  ctx.lineTo(frontMidPoint.x + offset.x, frontMidPoint.y - offset.y);
  ctx.lineTo(frontMidPoint.x + offset.x, frontMidPoint.y + offset.y);
  ctx.lineTo(frontMidPoint.x - offset.x, frontMidPoint.y + offset.y);
  ctx.lineTo(frontMidPoint.x - offset.x, frontMidPoint.y - offset.y);
  ctx.closePath();
  ctx.fill();

  // Top face
  ctx.fillStyle = '#CD9F61';
  const x = frontMidPoint.x - offset.x;
  const y = frontMidPoint.y - offset.y;

  const angle = -90;
  ctx.beginPath();
  ctx.lineTo(x + setLength * Math.cos(angle), y + setLength * Math.sin(angle));

  const xx = x + setLength * Math.cos(angle);
  const yy = y + setLength * Math.sin(angle);

  ctx.lineTo(xx + setWidth * 0.9, yy);
  ctx.lineTo(frontMidPoint.x + offset.x, frontMidPoint.y - offset.y);
  ctx.lineTo(frontMidPoint.x - offset.x, frontMidPoint.y - offset.y);
  ctx.closePath();
  ctx.stroke();
  ctx.fill();

  // Left face / length
  ctx.fillStyle = '#c08f4f';
  ctx.beginPath();

  const xl = frontMidPoint.x - offset.x;
  const yl = frontMidPoint.y - offset.y;
  ctx.lineTo(
    xl + setLength * Math.cos(angle),
    yl + setLength * Math.sin(angle)
  );

  const xxl = x + setLength * Math.cos(angle);
  const yyl = y + setLength * Math.sin(angle);

  ctx.lineTo(xxl, yyl + setHeight);
  ctx.lineTo(frontMidPoint.x - offset.x, frontMidPoint.y + offset.y);
  ctx.lineTo(frontMidPoint.x - offset.x, frontMidPoint.y - offset.y);
  ctx.closePath();
  ctx.stroke();
  ctx.fill();
};

type CanvasProps = React.DetailedHTMLProps<
  React.CanvasHTMLAttributes<HTMLCanvasElement>,
  HTMLCanvasElement
> &
  CanvasHTMLAttributes<HTMLCanvasElement> & {
    /**
     * NOTE: the drawing logic above cannot work if the width/height are different
     * so we're only going to allow one prop to avoid causing issues until we can fix the draw
     */
    canvasSize?: number;
    height: number;
    width: number;
    length: number;
    setBase?: (base: { base: number; widthInPx: number }) => void;
  };

const PackageDimensions = ({
  canvasSize = 400,
  width,
  height,
  length,
  setBase,
  ...restProps
}: CanvasProps) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);

  useEffect(() => {
    const ctx = canvasRef.current?.getContext('2d');
    if (!ctx) return;
    draw({ ctx, width, height, length, setBase });
  }, [height, length, width, setBase, canvasSize]);

  return (
    <canvas
      ref={canvasRef}
      width={canvasSize}
      height={canvasSize}
      {...restProps}
    />
  );
};

export default PackageDimensions;
