import Card from '@odo/components/elements/card';
import { Flex, Grid, GridItem } from '@odo/components/elements/layout';
import { cssColor } from '@odo/utils/css-color';
import { TbTrash as IconTrash } from 'react-icons/tb';
import { VideoPlatform, type EditorProductVideo } from '@odo/types/portal';
import Tooltip from '@odo/components/widgets/tooltip';
import styled from '@odo/lib/styled';
import { Text } from '@odo/components/elements/typography';

const Square = styled(Flex)`
  position: relative;
  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }
`;

Square.defaultProps = {
  alignItems: 'center',
  justifyContent: 'center',
};

const IndicatorWrapper = styled(Flex)`
  box-shadow: 1px 2px 4px -2px hsl(240deg 33.33% 80% / 50%),
    1px 2px 8px -2px hsl(240deg 33.33% 80% / 25%);
`;

IndicatorWrapper.defaultProps = {
  bg: cssColor('foreground'),
  borderRadius: '50%',
  p: 1,
};

const DeletedVideoIndicator = () => (
  <Tooltip showDelay={500} content={() => 'Video will be deleted on save'}>
    <IndicatorWrapper p="5px">
      <IconTrash color={cssColor('palette-pink')} size={16} />
    </IndicatorWrapper>
  </Tooltip>
);

/**
 * NOTE: this preview and the iframe props almost 100% match website for best accuracy.
 * The biggest difference is the size of the preview, which seems to affect youtube video previews as well.
 */
const VideoPreview = ({
  video,
  dimensions,
}: {
  video: EditorProductVideo;
  dimensions: number;
}) => (
  <Grid>
    {!!video.url && (
      <GridItem gridColumn={1} gridRow={1}>
        <Card
          overflow="hidden"
          maxWidth="min-content"
          mainProps={{ px: 0, py: 0 }}
        >
          <Square
            width={`${dimensions}px`}
            style={{
              backgroundImage:
                'linear-gradient(45deg, hsl(206deg 14% 10% / 10%) 25%, transparent 25%), linear-gradient(135deg, hsl(206deg 14% 10% / 10%) 25%, transparent 25%), linear-gradient(45deg, transparent 75%, hsl(206deg 14% 10% / 10%) 75%), linear-gradient(135deg, transparent 75%, hsl(206deg 14% 10% / 10%) 75%)',
              backgroundPosition: '0 0, 10px 0, 10px -10px, 0px 10px',
              backgroundSize: '20px 20px',
            }}
          >
            {!!video.platform &&
            [VideoPlatform.youtube, VideoPlatform.vimeo].includes(
              video.platform
            ) ? (
              <iframe
                src={`${video.url}${
                  video.platform === VideoPlatform.youtube
                    ? '?enablejsapi=1'
                    : ''
                }`}
                title="Video"
                frameBorder="0"
                referrerPolicy="origin"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                }}
              />
            ) : (
              <IndicatorWrapper borderRadius="24px" p={[1, 2]}>
                <Text fontWeight={600}>Unsupported Platform</Text>
              </IndicatorWrapper>
            )}
          </Square>
        </Card>
      </GridItem>
    )}

    {!!video.shouldDelete && (
      <GridItem gridColumn={1} gridRow={1} style={{ zIndex: 1 }}>
        <Flex justifyContent="flex-end" p={[1, 2]} gap={[1, 2]}>
          <DeletedVideoIndicator />
        </Flex>
      </GridItem>
    )}
  </Grid>
);

export default VideoPreview;
