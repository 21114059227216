import styled from '@odo/lib/styled';
import { cssColor } from '@odo/utils/css-color';
import type { InputHTMLAttributes } from 'react';
import { useId } from 'react';

const SwitchLabel = styled.label`
  display: grid;
  justify-content: flex-start;
  align-items: center;
  grid-template-columns: auto auto;
  gap: 12px;
  width: min-content;
  cursor: pointer;
  user-select: none;

  color: ${cssColor('text')};

  & span {
    white-space: nowrap;
    font-weight: 500;
  }
`;

interface SwitchInputProps {
  width?: string;
  color?: string;
}

/**
 * NOTE: while some of the concepts are shared with the Checkbox component,
 * they have too many style differences to make it worth giving them a shared ancestor.
 *
 * NOTE: after a certain threshold increasing the width starts to look iffy.
 * I tried to play with having things like the border-width, border-radius, and padding
 * all using calculations based on the width. But that didn't lead to good results at the normal size.
 * For now lets leave those hardcoded and expect the width to generally stay rather low.
 * If we do find that we NEED a really big switch, we can reconsider our options then.
 */
const SwitchInput = styled.input<SwitchInputProps>`
  /* our css variables */
  --active-color: ${p => p.color};
  --inactive-color: ${cssColor('grey-light')};
  --width: ${p => p.width};
  --height: calc(var(--width) / 2);
  --border-width: 2px;

  /* input visual reset */
  appearance: none;
  margin: 0;

  cursor: pointer;
  outline-offset: 4px;
  position: relative;

  /* from old switches tgl-flat styles */
  width: var(--width);
  height: var(--height);
  border: var(--border-width) solid var(--inactive-color);
  padding: 2px;
  transition: border-color 200ms ease;
  background: ${cssColor('foreground')};
  border-radius: 2em;

  &::after {
    content: '';
    position: relative;
    display: block;
    width: 50%;
    height: 100%;
  }

  &::after {
    left: 0;
    background: var(--inactive-color);
    transition: background 200ms ease, left 200ms ease;
    border-radius: 12em;
  }

  /* checked */
  &:checked {
    border-color: var(--active-color);
    &::after {
      left: 50%;
      background: var(--active-color);
    }
  }
`;

SwitchInput.defaultProps = {
  type: 'checkbox',
  width: '2.7em',
  color: cssColor('palette-blue'),
};

type SwitchProps = SwitchInputProps &
  InputHTMLAttributes<HTMLInputElement> & {
    label: string;
  };

/**
 * NOTE: we extract the `id` from the list of props
 * because we don't want to allow changing it
 * as that would break the labels `htmlFor`
 * and compromise the guaranteed uniqueness of `React.useId`.
 *
 * If we ever find that we NEED to be able to override the `id`,
 * then I'd recommend adding a custom `overrideId` prop.
 */
const Switch = ({ label, id: _, ...rest }: SwitchProps) => {
  const id = useId();

  return (
    <SwitchLabel htmlFor={id}>
      <SwitchInput id={id} {...rest} />
      <span>{label}</span>
    </SwitchLabel>
  );
};

export default Switch;
