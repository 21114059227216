import React, { useRef, useEffect, useState, useCallback } from 'react';
import { SpreadsheetLoader } from '../../utils/SpreadsheetLoader';
import {
  RPSInput,
  RPSButton,
  RPSCard,
  RPSModal,
} from '@rps/web-components/build/react-wrappers';
import { WaitForChanges } from '../../utils/WatchForChanges';
import { useCurrentDealSource } from '../../hooks/useCurrentDealSource';
import { useNavigate } from 'react-router-dom';
import styles from './css/BusySpinner.module.css';
import { iconNames } from '@rps/web-components/build/web-components';

const Upload = () => {
  const input = useRef();
  const currentDeal = useCurrentDealSource();
  const navigate = useNavigate();
  const [busy, setBusy] = useState(false);
  const [errors, setErrors] = useState([]);

  const readFile = useCallback(
    async (droppedFile = undefined) => {
      const file = droppedFile || input.current.inputControl.files[0];
      const myData = new SpreadsheetLoader(file);
      setBusy(true);
      await WaitForChanges(myData, 'isReady');

      // Add deals to current Deal list
      const deals = await myData.deals;
      if (myData.errors.length > 0) {
        // Show error popup
        setBusy(false);
        setErrors(myData.errors);
      } else {
        await Promise.all(
          deals.reverse().map(deal => currentDeal.newDeal(deal, true))
        );
        setBusy(false);
        navigate('/deals/list');
      }
    },
    [currentDeal, navigate]
  );

  const handleTemplateDownload = () => {
    const anchor = document.createElement('a');
    anchor.download = 'Buyers Portal Upload Template.xlsx';
    anchor.href = '/assets/upload_template_v2.xlsx';
    anchor.click();
  };

  useEffect(() => {
    const handleFileDrop = ev => {
      ev.preventDefault();
      if (ev.dataTransfer.items) {
        readFile(ev.dataTransfer.items[0].getAsFile());
      }
    };

    const handleDragOver = ev => {
      ev.preventDefault();
    };
    const handleDragLeave = ev => {
      ev.preventDefault();
    };

    window.addEventListener('dragover', handleDragOver);
    window.addEventListener('dragleave', handleDragLeave);
    window.addEventListener('drop', handleFileDrop);

    return () => {
      window.removeEventListener('dragover', handleDragOver);
      window.removeEventListener('dragleave', handleDragLeave);
      window.removeEventListener('drop', handleFileDrop);
    };
  }, [readFile]);

  return (
    <div
      className="-view"
      style={{ display: 'grid', gap: '2rem', placeItems: 'center' }}
    >
      <RPSCard>
        <div slot="header">
          <h5>Upload Deals</h5>
        </div>
        <p>
          Click below to choose a file or drop an XLSX file into this window to
          upload.
        </p>
        <RPSInput
          name="born"
          label="Select file to upload"
          required
          cbInput={() => readFile()}
          errormessage="enter a valid date"
          type="file"
          innerRef={input}
        />
      </RPSCard>
      <RPSCard>
        <RPSButton className="outline" cbClick={handleTemplateDownload}>
          <rps-svg slot="slot-left" svg={iconNames.dealUpload} />
          Download Template
        </RPSButton>
      </RPSCard>
      {busy && (
        <div className={styles.busySpinnerContainer}>
          <rps-card>
            <div className={styles.busySpinner}>
              <rps-spinner-pause />
              Uploading Spreadsheet...
            </div>
          </rps-card>
        </div>
      )}
      <RPSModal
        opened={errors.length > 0}
        popup
        header="Spreadsheet Errors:"
        cbCancel={() => setErrors([])}
        cbConfirm={() => setErrors([])}
      >
        <ul>
          {errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      </RPSModal>
    </div>
  );
};

export default Upload;
