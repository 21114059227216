import type { SetStateAction } from 'react';
import { createContext } from 'react';
import type { ProductContextType, Product } from '.';

const defaultContext: ProductContextType = {
  activeProduct: undefined,
  setActiveProduct: (_product: SetStateAction<Product | undefined>) => void 0,
};

const ProductContext = createContext<ProductContextType>(defaultContext);

export default ProductContext;
