import type * as CSS from 'csstype';
import { system } from '@styled-system/core';

// eslint-disable-next-line no-restricted-imports
import type {
  ResponsiveValue,
  RequiredTheme,
  Theme,
  TLengthStyledSystem,
} from 'styled-system';

// copy of the built-in defaults
const defaults = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
};

export interface TextIndentProps<
  ThemeType extends Theme = RequiredTheme,
  TVal = CSS.Property.GridGap<TLengthStyledSystem>
> {
  /**
   * The text-indent CSS property sets the length of empty space (indentation) that is put before lines
   * of text in a block.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/gap)
   */
  textIndent?: ResponsiveValue<TVal, ThemeType> | undefined;
}

export const textIndent = system({
  textIndent: {
    property: 'textIndent',
    scale: 'space',
    defaultScale: defaults.space,
  },
});
