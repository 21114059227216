import type { ApiCustomOption } from '@odo/types/api';
import uuid from '@odo/utils/uuid';
import { useCallback, useContext } from 'react';
import type { Product } from '.';
import { ProductContext, ProductSource } from '.';

export const useProductContext = () => useContext(ProductContext);

export const useProduct = () => {
  const { activeProduct } = useProductContext();
  return activeProduct;
};

export const useSetProduct = () => {
  const { setActiveProduct } = useProductContext();

  const setProduct = useCallback(
    (product: Product | undefined) => setActiveProduct(product),
    [setActiveProduct]
  );

  return setProduct;
};

export const useSetCustomOptions = () => {
  const { setActiveProduct } = useProductContext();

  const setCustomOptions = useCallback(
    (customOptions: ApiCustomOption[]) =>
      setActiveProduct(product => ({
        source: product?.source || ProductSource.portal,
        id: product?.id || uuid(),
        product: product?.product ? { ...product.product } : undefined,
        customOptions,
      })),
    [setActiveProduct]
  );

  return setCustomOptions;
};

export const useCustomOptions = () => {
  const customOptions = useProduct()?.customOptions;
  const setCustomOptions = useSetCustomOptions();

  return { customOptions, setCustomOptions };
};
