import ProductEditorContext from './context';
import { useContext } from 'react';

export const useProductEditor = () => {
  const productEditor = useContext(ProductEditorContext);
  return productEditor;
};

export const useCurrentProduct = () => {
  const { currentProduct } = useProductEditor();
  return currentProduct;
};

export const useChangeProduct = () => {
  const { change } = useProductEditor();
  return change;
};
