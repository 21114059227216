/**
 * Create a range of numbers
 *
 * @param {number} start - number to start at
 * @param {number} end - number to end at
 * @returns - A range of numbers 
 */
function range(start, end) {
	return Array(end - start + 1).fill().map((_, idx) => start + idx)
};

/**
 * Convert a number to decimal
 * @example toDecimal(1) = 1.00
 * @description take number value and adds zeros to it
 *
 * @param {number} val
 * @returns
 */
function toDecimal(val) {
	return Number.parseFloat(val).toFixed(2);
}

/**
 * Convert a number to thousand separator
 * @example toDecimal(1000) = 1 000
 * @description take number value and splits it into thousand separtor
 *
 * @param {number} val
 * @returns
 */
function toThousandFormat(val) {
	return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
}



/**
 * Format a number to a currency / culture
 * @example toCurrency(123456.789, 'EUR'); // €123,456.79  | currency: Euro | currencyLangFormat: Local
 * toCurrency(123456.789, 'USD', 'en-us'); // $123,456.79  | currency: US Dollar | currencyLangFormat: English (United States)
 * toCurrency(123456.789, 'USD', 'fa'); // ۱۲۳٬۴۵۶٫۷۹ ؜$ | currency: US Dollar | currencyLangFormat: Farsi
 * toCurrency(322342436423.2435, 'JPY'); // ¥322,342,436,423 | currency: Japanese Yen | currencyLangFormat: Local
 * toCurrency(322342436423.2435, 'JPY', 'fi'); // 322 342 436 423 ¥ | currency: Japanese Yen | currencyLangFormat: Finnish
 *
 * @param {Number} num The number to format
 * @param {String} curr The desired currency
 * @param {String} [languageFormat=undefined] The optional Language format
 * @return {String} The formatted currency value
 */
function toCurrency(num, curr, languageFormat = undefined) {
	if (typeof num !== "number") {
		console.warn('numbers:toCurrency', `[num]=${num} must be a Number`);
		return undefined;
	} else if (typeof curr !== "string") {
		console.warn('numbers:toCurrency', `[curr]=${curr} must be a String`);
		return undefined;
	} else if (languageFormat !== undefined && typeof languageFormat !== "string") {
		console.warn('numbers:toCurrency', `[languageFormat]=${languageFormat} must be a String or undefined`);
		return undefined;
	}
	return Intl.NumberFormat(languageFormat, { style: 'currency', currency: curr }).format(num);
}


/**
 * Uses the toLocaleString()function to convert float-point arithmetic to the decimal mark form by using a number to make a comma-separated string.
 * @example toDecimalMark(12305030388.9087, 'en-ZA'); // "12,305,030,388.90" 
 *
 * @param {Number} num The number to convert
 * @param {String} [culture='en-ZA'] The optional Culture settings to use
 * @param {Number} [decimals = 2] The decimals tpo use
 */
function toDecimalMark(num, culture = 'en-ZA', decimals = 2) {
	if (typeof num !== "number") {
		console.warn('numbers:toDecimalMark', `[num]=${num} must be a Number`);
		return undefined;
	} else if (typeof culture !== "string") {
		console.warn('numbers:toDecimalMark', `[culture]=${culture} must be a String`);
		return undefined;
	} else if (typeof decimals !== "number") {
		console.warn('numbers:toDecimalMark', `[decimals]=${decimals} must be a Number`);
		return undefined;
	}

	return new Intl.NumberFormat(culture, { minimumFractionDigits: decimals, maximumFractionDigits: decimals, }).format(num);
}

/**
 * Round a number to [x] decimals and return a number
 * @example round(1.005, 2); // 1.01
 *
 * @param {Number} toRound The number to round
 * @param {number} [decimals=0] The decimals to round off to
 * @return {Number} The rounded number with [x] decimals as a number (not string) 
 */
function round(toRound, decimals = 0) {
	if (typeof toRound !== 'number') {
		console.warn('numbers:round', `[toRound]=${toRound} must be a number`);
		return undefined;
	}

	const num = Number(`${Math.round(`${toRound}e${decimals}`)}e-${decimals}`);
	return num;
}

/**
 * Convert a cardinal number to an ordinal number
 * @example toOrdinalNumber(1); // 1st
 * 
 * @param {number} [cardinalNumber=0] The cardinal number to be converted to an ordinal number
 * @param {boolean} [includeNumber=true] indicates whether to return ordinal number or just the suffix
 * @param {string} [separator=""] seperator to use between number and suffix
 * @return {String} The formatted ordinal number as a string (or just the suffix)
 */
function toOrdinalNumber(cardinalNumber = 0, includeNumber = true, separator = "") {

	if (!Number.isInteger(cardinalNumber) || cardinalNumber === 0) {
		console.warn('numbers:toOrdinalNumber', 'cardinalNumber not valid. It is of type ' + typeof (cardinalNumber));
		return '';
	}

	if (typeof includeNumber !== "boolean") {
		console.warn('numbers:toOrdinalNumber', `[includeNumber]=${includeNumber} must be a boolean`);
		return '';
	}

	if (typeof separator !== "string") {
		console.warn('numbers:toOrdinalNumber', `[separator]=${separator} must be a string`);
		return '';
	}

	let suffix = "th";
	const cardinalNumberAbs = Math.abs(cardinalNumber);

	const j = cardinalNumberAbs % 10,
		k = cardinalNumberAbs % 100;
	if (j == 1 && k != 11) {
		suffix = "st";
	}
	if (j == 2 && k != 12) {
		suffix = "nd";
	}
	if (j == 3 && k != 13) {
		suffix = "rd";
	}

	if (includeNumber) {
		return cardinalNumber + separator + suffix;
	}
	else {
		return suffix;
	}
}

export {
	range,
	toDecimal,
	toThousandFormat,
	toCurrency,
	toDecimalMark,
	round,
	toOrdinalNumber,
}