import styled from '@odo/lib/styled';
import { cssColor } from '@odo/utils/css-color';

export const Label = styled.label`
  font-weight: 600;
  padding: 0 0 0 2px;
  font-size: 14px;
  color: ${cssColor('text')};
  letter-spacing: 0.02em;
  line-height: 1.4;
  transition: color 0.2s;
  span {
    margin-left: 0.2em;
    color: var(--brand-alt-pink-dark);
  }
  &.focused {
    color: var(--brand-primary);
  }
  &.error {
    color: var(--brand-alt-pink-dark);
  }
`;

export const InputField = styled.input`
  padding: 10px;
  text-indent: 5px;
  border: 1px solid #c2c2c2;
  color: ${cssColor('text')};
  background: ${cssColor('background')};
  font-size: 14px;
  border-radius: 4px;
  outline: none;
  display: flex;
  width: 100%;
  align-items: center;
  overflow: hidden;
  letter-spacing: 0.02em;
  transition: all 0.2s;
  &:focus,
  &:focus-visible {
    border-color: var(--brand-primary);
  }
  &::placeholder {
    color: #8c8c8c;
  }
  &:disabled {
    background: #eee;
    cursor: not-allowed;
  }
  &.error {
    border-color: var(--brand-alt-pink-dark);
  }
  &.tabular-nums {
    font-variant-numeric: tabular-nums;
  }

  &.match-rp-styles {
    padding: 10px 5px;
    background: #f5f5f5;

    &:disabled {
      background: white;
    }
  }
`;

export const PrefixInputField = styled(InputField)`
  border-left: none;
  border-radius: 0px 4px 4px 0px;
  display: table-cell;
`;

export const InputGroup = styled.div`
  display: block;

  &.with-addon {
    display: table;
    border-collapse: separate;
  }

  &.disabled {
    cursor: not-allowed;
  }

  .input-group-addon {
    padding: 4px 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: var(--text-secondary);
    text-align: center;
    background-color: #eee;
    border: 1px solid #c2c2c2;
    border-right: none;
    border-radius: 4px 0px 0px 4px;
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
    display: table-cell;
    transition: border-color 0.2s;

    &.focused {
      border-color: var(--brand-primary);
    }

    &.match-rp-styles {
      background: #f5f5f5;

      &.disabled {
        background: white;
      }
    }
  }
`;

export const SelectLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

export const SelectFieldWrapper = styled.div`
  display: grid;
  grid-template-areas: 'select';
  align-items: center;

  &:after {
    content: '';
    grid-area: select;
    justify-self: flex-end;
    width: 10px;
    height: 10px;
    background: ${cssColor('text')};
    clip-path: polygon(0 30%, 50% 80%, 100% 30%, 90% 20%, 50% 60%, 10% 20%);
    margin-right: 8px;
    pointer-events: none;
    cursor: pointer;
  }
`;

export const SelectField = styled.select`
  padding: 10px 30px 10px 12px;
  border: 1px solid #c2c2c2;
  color: ${cssColor('text')};
  background: ${cssColor('background')};
  font-size: 14px;
  border-radius: 4px;
  outline-offset: 4px;
  width: 100%;
  letter-spacing: 0.02em;
  transition: all 0.2s;

  grid-area: select;
  appearance: none;
  cursor: pointer;

  &:focus,
  &:focus-visible {
    border-color: var(--brand-primary);
  }
  &:disabled {
    background: #f5f5f5;
    cursor: not-allowed;
  }
  &.error {
    border-color: var(--brand-alt-pink-dark);
  }

  &.match-rp-styles {
    padding: 7px 20px 7px 5px;
    background: #f5f5f5;

    &:disabled {
      background: white;
    }
  }
`;

export const ErrorMessage = styled.span`
  color: var(--brand-alt-pink-dark);
  font: var(--text-s);
`;
