import type * as CSS from 'csstype';
import { system } from '@styled-system/core';

// eslint-disable-next-line no-restricted-imports
import type {
  ResponsiveValue,
  RequiredTheme,
  Theme,
  TLengthStyledSystem,
} from 'styled-system';

// copy of the built-in defaults
const defaults = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
};

export interface GapProps<
  ThemeType extends Theme = RequiredTheme,
  TVal = CSS.Property.GridGap<TLengthStyledSystem>
> {
  /**
   * The gap CSS property sets the gaps (gutters) between rows and columns. It is a shorthand for row-gap
   * and column-gap.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/gap)
   */
  gap?: ResponsiveValue<TVal, ThemeType> | undefined;
  /**
   * The column-gap CSS property sets the size of the gap (gutter) between an element's columns.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/column-gap)
   */
  columnGap?: ResponsiveValue<TVal, ThemeType> | undefined;
  /**
   * The row-gap CSS property sets the size of the gap (gutter) between an element's rows.
   *
   * [MDN reference](https://developer.mozilla.org/en-US/docs/Web/CSS/row-gap)
   */
  rowGap?: ResponsiveValue<TVal, ThemeType> | undefined;
}

export const gap = system({
  gap: {
    property: 'gap',
    scale: 'space',
    defaultScale: defaults.space,
  },
  columnGap: {
    property: 'columnGap',
    scale: 'space',
    defaultScale: defaults.space,
  },
  rowGap: {
    property: 'rowGap',
    scale: 'space',
    defaultScale: defaults.space,
  },
});
