import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentDealSource } from 'hooks/useCurrentDealSource';
import PropTypes from 'prop-types';
import { generatePath } from 'react-router-dom';
import { DuplicateDeal as Duplicate } from '../../deals/Duplicate';
import Dialog from '@odo/components/widgets/dialog';
import { useCustomOptionsEditorContext } from '@odo/contexts/custom-options-editor';

/**
 * For a split-second after the temp deal is set
 * the current deal source and the below props all become undefined.
 * I've worked past this by doing some safety checks,
 * but it would be best to figure out why and resolve it.
 */
const DuplicateDeal = ({ isReady, close }) => {
  const navigate = useNavigate();

  const { clearActions } = useCustomOptionsEditorContext();

  const dealSource = useCurrentDealSource();
  const deal = dealSource?.tempDeal;
  const newDeal = dealSource?.newDeal;

  const [isDone, setIsDone] = useState(false);

  const isOpen = useMemo(() => {
    return !!isReady && !isDone && !!deal && !!newDeal;
  }, [deal, isDone, isReady, newDeal]);

  return (
    <Dialog
      title="Duplicate Deal"
      isOpen={isOpen}
      close={close}
      minWidth="650px"
    >
      <Duplicate
        deal={deal}
        newDeal={newDeal}
        onComplete={() => {
          /**
           * NOTE: we're resetting the custom option action list before duplication
           * this will prevent any unsaved changes from interfering with the "paste" action.
           *
           * technically this is a race condition, in that we're racing to clear the previous actions
           * before any duplication actions are loaded from the custom options cache
           *
           * but in my testing so far, this always seems to win as long as it's not done within a useEffect
           *
           * TODO: implement a better solution once our contexts are less complicated
           */
          clearActions();

          // navigate to deal editor page for the new product and flag duplication as complete
          setIsDone(true);
          navigate(generatePath('/deals/editor/buyer-and-supplier'));
        }}
      />
    </Dialog>
  );
};

DuplicateDeal.propTypes = {
  isLoading: PropTypes.bool,
  isReady: PropTypes.bool,
  close: PropTypes.func,
};

export default DuplicateDeal;
