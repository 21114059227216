/**
 * NOTE: this is a JS based ellipsis.
 * Most use-cases would be better suited to have a CSS based version instead.
 */
export const ellipsis = (str: string, len: number) =>
  str.length > len ? `${str.slice(0, len - 1)}...` : str;

type ConditionalString =
  | string
  | [condString: string]
  | [condString: string, cond?: boolean | null];

export const conditionalJoin = (
  condStrings: ConditionalString[],
  join = ' ',
  fallback: string | undefined = undefined
) =>
  condStrings
    .filter(
      cl => typeof cl === 'string' || typeof cl[1] === 'undefined' || cl[1]
    )
    .map(cl => (typeof cl === 'string' ? cl : cl[0]))
    .join(join) || fallback;
