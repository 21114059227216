import type { Attribute, AttributeID } from '@odo/contexts/attributes';
import AttributeContext from '@odo/contexts/attributes';
import type { ReactNode } from 'react';
import { useEffect, useMemo } from 'react';
import { useCallback, useState } from 'react';
import { createClient } from '@odo/services/urql';
import { gql } from 'urql';
import type { ApiAttribute, QueryAttributesOutput } from '@odo/types/api';
import toast from 'react-hot-toast';

const ERROR_TOAST_ID = 'attribute-provider-error';

const GET_ATTRIBUTES = gql`
  query getAttributes {
    getAttributes {
      name
      data {
        key
        value
        metadata {
          key
          value
        }
      }
    }
  }
`;

const findAttribute = (attributes: Attribute[], attr: AttributeID) =>
  attributes.find(({ id }) => id === attr);

const keyLabelMap = {
  ADMINCOST_0_00: 'R0.00',
  ADMINCOST_10_00: 'R10.00',
  ADMINCOST_17_50: 'R17.50',
  ADMINCOST_34_00: 'R34.00',
  WORKING_DAYS_3_5: 'Working Days 3-5',
  WORKING_DAYS_5_10: 'Working Days 5-10',
  WORKING_DAYS_10_15: 'Working Days 10-15',
  WORKING_DAYS_10_20: 'Working Days 10-20',
  DTD_FOR_OUR_EYES_ONLY: 'DTD For Our Eyes Only',
  PDP_ONLY: 'PDP Only',
  NOT_APPLICABLE_NOT_PACKS: 'Not Applicable - Not Packs',
  RJ: 'RJ',
  CPO_CERTIFIED_PREOWNED: 'CPO - Certified Pre-Owned',
  HOW_TO_MEASURE_YOUR_FACE_SUNNIES: 'How to Measure Your Face (Sunnies)',
  HOW_TO_MEASURE_YOUR_FOOT_SHOES: 'How to Measure Your Foot (Shoes)',
  HOW_TO_MEASURE_YOUR_HEAD_HATS: 'How to Measure Your Head (Hats)',
  HOW_TO_MEASURE_YOUR_FINGER_RINGS: 'How to Measure Your Finger (Rings)',
};

const keyToLabel = (key: string) =>
  key
    .split('_')
    .map(([firstChar, ...rest]) =>
      firstChar
        ? `${firstChar.toUpperCase()}${rest.join('').toLowerCase()}`
        : ''
    )
    .join(' ');

const convertAttribute = (attr: ApiAttribute): Attribute => ({
  ...attr,
  id: attr.name,
  options: attr.data.map(data => ({
    key: data.key,
    value: data.key,
    label: keyLabelMap[data.key] || keyToLabel(data.key),
    metadata: data.metadata,
    originalData: data,
  })),
});

const AttributeProvider = ({ children }: { children: ReactNode }) => {
  const [isReady, setIsReady] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [attributes, setAttributes] = useState<Attribute[]>([]);

  const getAttribute = useCallback(
    (attr: AttributeID) => findAttribute(attributes, attr),
    [attributes]
  );

  const getAttributeOptions = useCallback(
    (attr: AttributeID) => getAttribute(attr)?.options || [],
    [getAttribute]
  );

  useEffect(() => {
    if (isReady) return;

    const controller = new AbortController();

    const fetchAttributes = async (signal: AbortSignal) => {
      let isActive = true;
      setIsLoading(true);

      signal.addEventListener('abort', () => (isActive = false));

      try {
        const { data } = await createClient({ signal })
          .query<QueryAttributesOutput>(GET_ATTRIBUTES, undefined, {
            requestPolicy: 'network-only',
          })
          .toPromise();

        if (isActive && data?.getAttributes) {
          setAttributes(data.getAttributes.map(convertAttribute));
          setIsLoading(false);
          setIsReady(true);
        }
      } catch (e) {
        if (!isActive) return;

        console.error(e);
        toast.error(
          e instanceof Error && typeof e.message === 'string'
            ? e.message
            : 'Error loading attributes',
          { id: ERROR_TOAST_ID, duration: 15000 }
        );
        isActive && setIsLoading(false);
      }
    };

    fetchAttributes(controller.signal);

    return () => controller.abort();
  }, [isReady]);

  const value = useMemo(
    () => ({ attributes, getAttribute, getAttributeOptions, loading, isReady }),
    [attributes, getAttribute, getAttributeOptions, isReady, loading]
  );

  return (
    <AttributeContext.Provider value={value}>
      {children}
    </AttributeContext.Provider>
  );
};

export default AttributeProvider;
