import React, { useState } from 'react';
import propTypes from 'prop-types';
import styles from './css/VideoCard.module.css';
import {
  RPSButtonIcon,
  RPSCard,
  RPSToolbar,
  RPSModal,
  RPSVideo,
} from '@rps/web-components/build/react-wrappers';
import { iconNames } from '@rps/web-components/build/web-components';

const VideoCard = ({
  index,
  video,
  selected,
  onSelect,
  onDelete,
  onSwap,
  videos,
}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const toolbarButtons = [{ id: 'delete', svg: iconNames.del }];

  const handleToolbarClick = ev => {
    switch (ev.detail.buttonId) {
      case 'delete':
        setShowDeleteModal(true);
        break;
    }
  };

  const selectCard = () => {
    if (onSelect) {
      onSelect(index);
    }
  };

  const extractURLFromIFrame = iframe => {
    const matcher = /src=["'](.+?)["']/;
    const match = iframe.match(matcher);
    if (match && match.length > 1) {
      return match[1];
    }
  };

  return (
    <RPSCard>
      <div slot="header">
        <span className={styles.header}>
          <rps-svg
            svg={selected ? iconNames.checkedBox : iconNames.checkBox}
            onClick={selectCard}
          />
          <RPSToolbar buttons={toolbarButtons} cbClick={handleToolbarClick} />
        </span>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '1.2rem' }}>
        <RPSButtonIcon
          disabled={index === 0}
          svg={iconNames.angleLeft}
          className="small outline secondary"
          cbClick={() => onSwap(index, index - 1)}
        />
        <RPSVideo src={extractURLFromIFrame(video)} needsIframe />
        <RPSButtonIcon
          disabled={index === videos.length - 1}
          svg={iconNames.angleRight}
          className="small outline secondary"
          cbClick={() => onSwap(index, index + 1)}
        />
      </div>
      <RPSModal
        popup
        opened={showDeleteModal}
        cbCancel={() => {
          setShowDeleteModal(false);
        }}
        cbConfirm={() => {
          if (onDelete) {
            onDelete(index);
          }
          setShowDeleteModal(false);
        }}
      >
        Delete this video?
      </RPSModal>
    </RPSCard>
  );
};

VideoCard.propTypes = {
  title: propTypes.string,
  video: propTypes.object,
  onUpdate: propTypes.func,
  selected: propTypes.bool,
  onSelect: propTypes.func,
  index: propTypes.number,
  onDelete: propTypes.func,
  onSwap: propTypes.func,
  videos: propTypes.array,
};

export default VideoCard;
