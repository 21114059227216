import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useCurrentDealSource } from '../../hooks/useCurrentDealSource';
import {
  RPSButton,
  RPSButtonIcon,
  RPSCheckbox,
  RPSListContainer,
} from '@rps/web-components/build/react-wrappers';
import { Deal } from '../../models/Deal.jsx';
import PropTypes from 'prop-types';
import { iconNames } from '@rps/web-components/build/web-components';
import { PlatformEnum } from 'constants/ODOEnums';
import { CheckboxWrapper } from './../uiComponents/CheckboxWrapper.jsx';
import { checkIfLiveDeal } from './../../utils/checkIfLiveDeal';
import odoUuid from '@odo/utils/uuid.ts';
import { queryCustomOptions } from '@odo/graphql/product/custom-options.ts';
import { useSetProduct } from '@odo/contexts/product/index.ts';
import { duplicateCustomOptions } from '@odo/data/custom-options/utils.ts';
import { isCategory } from '@odo/types/guards.ts';
import { removedItems, excludedBuyers } from '@odo/constants/buyers';

const processNewDeal = async ({ cloneDeal, includeSku = true }) => {
  if (!includeSku) {
    cloneDeal.product.sku = '';
  } else {
    const splitSku = cloneDeal.product.sku.split('|');
    if (splitSku.length > 1) {
      const currentNum = splitSku[1].match(/\d+$/g) || 0;

      splitSku[1] = splitSku[1].replace(/\d+$/g, (+currentNum + 1).toString());
      cloneDeal.product.sku = splitSku.join('|');
    } else {
      cloneDeal.product.sku = `${cloneDeal.product.sku}|1`;
    }
  }

  // remove url
  cloneDeal.product.url = '';

  // Remove dates
  cloneDeal.product.activeFromDate = '';
  cloneDeal.product.activeToDate = '';
  cloneDeal.product.activeFromTime = '00:00';
  cloneDeal.product.activeToTime = '23:59';
  cloneDeal.product.isTimedDeal = false;
  cloneDeal.product.status = false;

  // reset preview only flag
  cloneDeal.product.isPreviewOnly = false;

  // if the buyer is excluded remove them
  if (
    [...removedItems, ...excludedBuyers].includes(
      cloneDeal.buyerAndSupplier.buyer
    )
  ) {
    cloneDeal.buyerAndSupplier.buyer = '';
  }

  // Set other defaults
  cloneDeal.buyerAndSupplier.isSupplierNew = false;
  cloneDeal.buyerAndSupplier.salesAssistant = '';
  cloneDeal.buyerAndSupplier.isLunchtimeProduct = false;
  cloneDeal.buyerAndSupplier.isBestSeller = false;
  cloneDeal.buyerAndSupplier.isMainDeal = false;
  cloneDeal.buyerAndSupplier.campaign = null;
  cloneDeal.buyerAndSupplier.campaignMailer = [];
  cloneDeal.buyerAndSupplier.platform = [
    PlatformEnum[1],
    PlatformEnum[2],
    PlatformEnum[3],
  ];

  //size guide image
  const sizeGuideImageIndex = cloneDeal.imagesAndVideos.images.findIndex(
    ({ label }) => label.toUpperCase().trim() === 'SIZING GUIDE'
  );
  if (sizeGuideImageIndex !== -1) {
    cloneDeal.imagesAndVideos.images[sizeGuideImageIndex] = {
      ...cloneDeal.imagesAndVideos.images[sizeGuideImageIndex],
      imageTypes: [],
      changed: true,
      isSizeGuide: true,
    };
    cloneDeal.sizeChart.desktop.url =
      cloneDeal.imagesAndVideos.images[sizeGuideImageIndex].url;
  }
  cloneDeal.sizeChart.id = null;
  cloneDeal.shipping.supplierRepacks = null;

  cloneDeal.buyerAndSupplier.priority = '219';

  // Clear stock/inventory
  cloneDeal.shipping.qty = 0;
  cloneDeal.shipping.isInStock = false;
  cloneDeal.shipping.minSaleQuantity = 0;

  // filter out the shop(s)
  cloneDeal.conditionsAndCategory.categories =
    cloneDeal.conditionsAndCategory.categories.filter(cat =>
      isCategory(cat)
        ? !cat?.breadcrumb || cat?.breadcrumb?.type !== 'daily_shop'
        : cat?.type !== 'daily_shop'
    );

  // remove MAIN DEAL or EXCLUDE SHOP deal type on duplication
  const removedDealType = ['MAIN_DEAL', 'EXCLUDE_SHOP']; // If this list get longer, maybe add it to @odo/constants folder
  if (
    Array.isArray(cloneDeal.buyerAndSupplier.dealType) &&
    cloneDeal.buyerAndSupplier.dealType.length > 0
  ) {
    cloneDeal.buyerAndSupplier.dealType =
      cloneDeal.buyerAndSupplier.dealType.filter(
        type => !removedDealType.includes(type)
      );
  }

  // reset buy-in stock type
  if (cloneDeal.product.buyInStockType !== null) {
    cloneDeal.product.buyInStockType = null;
  }

  /**
   * NOTE: sending an empty array on create product throws an error on the API.
   * We handle this if a user removes all deal types manually,
   * but we need to also do so if we have an empty array from duplication.
   *
   * @see /src/components/deals/buyerAndSupplier/CampaignSection.js -> handleDealTypeChange
   */
  if (
    cloneDeal.buyerAndSupplier.dealType !== null &&
    cloneDeal.buyerAndSupplier.dealType.length === 0
  ) {
    cloneDeal.buyerAndSupplier.dealType = null;
  }
};

function objectMap(obj, handler, skipUnsetNodes = false) {
  if (typeof obj !== 'object') {
    console.warn('js-utils: objects:objectMap, [obj] must be an Object', obj);
    return undefined;
  }

  const results = [];

  Object.keys(obj).forEach((key, index) => {
    const node = obj[key];

    if (skipUnsetNodes && !node) {
      return;
    }

    const result = handler(node, key, index, obj);
    if (result !== undefined) {
      results.push(result);
    }
  });

  return results;
}

const findChildFields = (parentName, fields) => {
  return fields.filter(x => x.key.match(new RegExp(`^${parentName}\.`)));
};

const isSetPartiallyEnabledOrDisabled = fields => {
  let enabledCount = 0;
  let disabledCount = 0;

  for (const field of fields) {
    if (field.enabled) {
      enabledCount++;
    } else {
      disabledCount++;
    }
  }

  return [enabledCount > 0 && disabledCount > 0, enabledCount];
};

const FieldSet = ({ fieldSet, children, onChange, allFields }) => {
  const [expand, setExpand] = useState(false);
  const childFields = findChildFields(fieldSet.key, allFields);
  const [partiallyActive, numEnabled] =
    isSetPartiallyEnabledOrDisabled(childFields);

  return (
    <div
      key={fieldSet.key}
      style={{
        userSelect: 'none',
        boxShadow: '1px 1px 3px rgba(0,0,0,0.4)',
        margin: '0.5rem',
        padding: '0.8rem',
      }}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr',
          gap: '1rem',
          alignItems: 'center',
        }}
      >
        <RPSButtonIcon
          className="small outline"
          svg={expand ? iconNames.angleLeft : iconNames.add}
          cbClick={() => setExpand(!expand)}
        />
        <CheckboxWrapper
          indeterminate={partiallyActive}
          name={fieldSet.key}
          checked={fieldSet.enabled}
          style={{ height: '1.5rem' }}
          cbInput={onChange(fieldSet.key)}
          label={`${fieldSet.name}${
            partiallyActive ? ` (${numEnabled}/${childFields.length})` : ''
          }`}
        />
      </div>
      <div style={{ padding: expand ? '1rem' : 0, userSelect: 'none' }}>
        {expand && children}
      </div>
    </div>
  );
};

FieldSet.propTypes = {
  fieldSet: PropTypes.any,
  children: PropTypes.any,
  onChange: PropTypes.func,
  allFields: PropTypes.array,
};

const Fields = ({ fields, fieldSet, handleChange }) => {
  const matchingFields = fields.filter(f => {
    const m = f.key.match(/(.+)\..+/)[1];
    return fieldSet.key === m;
  });

  return (
    <ul style={{ paddingLeft: '24px' }}>
      {matchingFields.map((field, index) => (
        <div key={`${field.key}-${index}`} style={{ width: '100%' }}>
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr auto',
              gap: '1rem',
              borderBottom: '1px solid #ddd',
              padding: '8px',
            }}
          >
            <CheckboxWrapper
              label={field.name}
              style={{ height: 0, padding: '8px', margin: 0 }}
              className="small"
              defaultValue={field.enabled}
              checked={field.enabled}
              cbInput={handleChange(field.key)}
            />
            <div>
              {Array.isArray(field.value)
                ? `${field.value.length} ${field.name}(s)`
                : field.value?.toString() || 'Empty'}
            </div>
          </div>
        </div>
      ))}
    </ul>
  );
};

Fields.propTypes = {
  fields: PropTypes.array,
  fieldSet: PropTypes.any,
  handleChange: PropTypes.func,
};

const FieldSets = ({ fields, fieldSets, handleChange }) => (
  <div>
    <h3>Fields</h3>
    <ul style={{ paddingLeft: 0 }}>
      {fieldSets.map((fieldSet, index) => (
        <FieldSet
          key={index}
          fieldSet={fieldSet}
          onChange={handleChange}
          allFields={fields}
        >
          <Fields
            fields={fields}
            fieldSet={fieldSet}
            handleChange={handleChange}
          />
        </FieldSet>
      ))}
    </ul>
  </div>
);

FieldSets.propTypes = {
  fields: PropTypes.array,
  fieldSets: PropTypes.array,
  handleChange: PropTypes.func,
};

const initialFieldSetState = [
  {
    key: 'buyerAndSupplier',
    name: 'Buyer & Supplier',
    enabled: true,
    selector: obj => Object.getOwnPropertyNames(obj),
  },
  { key: 'product', name: 'Product Info', enabled: true },
  { key: 'sizeChart', name: 'Size Chart', enabled: true },
  {
    key: 'conditionsAndCategory',
    name: 'Conditions & Category',
    enabled: true,
  },
  {
    key: 'priceAndCustomOptions',
    name: 'Price',
    enabled: true,
  },
  { key: 'imagesAndVideos', name: 'Images & Videos', enabled: true },
  { key: 'shipping', name: 'Shipping', enabled: true },
];

export const DuplicateDeal = ({
  onComplete,
  deal: dealProp,
  newDeal: newDealProp,
}) => {
  const setProduct = useSetProduct();

  const dealSource = useCurrentDealSource();

  /**
   * NOTE: the deal source is a bit wonky from search.
   * So we allow a prop to be passed in for these values.
   */
  const deal = dealProp || dealSource?.tempDeal;
  const newDeal = newDealProp || dealSource?.newDeal;

  const [hideEmpty, setHideEmpty] = useState(false);
  const [select, setSelect] = useState(false);
  const [busy, setBusy] = useState(false);

  const [fields, setFields] = useState([]);
  const [fieldSets, setFieldSets] = useState(initialFieldSetState);

  const isDealLive = useMemo(() => {
    return deal && checkIfLiveDeal(deal);
  }, [deal]);

  /**
   * Generate a fields list with every property on every model.
   */
  useEffect(() => {
    if (deal) {
      const generateFields = (defaultsTo = true) => {
        let fields = [];
        const fieldsToFilter = [
          'sizeChart.id',
          'sizeChart.delete',
          'sizeChart.smDesktopImage',
          'sizeChart.smTabletImage',
          'sizeChart.smMobileImage',
          'sizeChart.desktopDelete',
          'sizeChart.mobileDelete',
          'sizeChart.tabletDelete',
        ];
        for (const fieldSet of initialFieldSetState) {
          const results = objectMap(
            deal[fieldSet.key].properties,
            (_, name) => ({
              key: `${fieldSet.key}.${name}`,
              name,
              enabled: defaultsTo,
              value:
                deal[fieldSet.key]?.properties[name]?.filePath ||
                deal[fieldSet.key]?.properties[name],
            }),
            hideEmpty
          );
          fields = [...fields, ...results];
          fields = fields.filter(
            dealField => !fieldsToFilter.includes(dealField.key)
          );
        }
        return fields;
      };

      setFields(generateFields());
    }
  }, [deal, hideEmpty]);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      if (isDealLive) {
        return;
      }

      setBusy(true);

      const duplicate = async () => {
        if (select) {
          // Copy specific fields from original product
          const clonedDeal = new Deal();
          objectMap(fields, field => {
            const parentKey = field.key.split('.')[0];
            if (field.enabled && clonedDeal.hasOwnProperty(parentKey)) {
              clonedDeal[parentKey].set(
                field.name,
                deal[parentKey].properties[field.name]
              );
            }
          });

          await processNewDeal({
            cloneDeal: clonedDeal,
            includeSku: fields.find(f => f.name === 'sku')?.enabled,
          });

          clonedDeal.meta = {};
          clonedDeal.meta.tmpId = odoUuid();

          // get @odo custom options
          const customOptions = await queryCustomOptions({
            productId: deal.id,
          });

          duplicateCustomOptions({
            customOptions,
            productId: clonedDeal.meta.tmpId,
          });

          setProduct({ source: 'portal', id: clonedDeal.meta.tmpId });

          newDeal(new Deal(clonedDeal), true);
        } else {
          // Copy full deal, modify sku, remove dates, remove quantity
          const clonedDeal = new Deal(deal);

          await processNewDeal({ cloneDeal: clonedDeal });

          clonedDeal.meta = {};
          clonedDeal.meta.tmpId = odoUuid();

          // get @odo custom options
          const customOptions = await queryCustomOptions({
            productId: deal.id,
          });

          duplicateCustomOptions({
            customOptions,
            productId: clonedDeal.meta.tmpId,
          });

          setProduct({ source: 'portal', id: clonedDeal.meta.tmpId });

          newDeal(new Deal(clonedDeal), true);
        }

        if (onComplete) {
          onComplete();
        }
      };

      duplicate();
    },
    [deal, fields, isDealLive, newDeal, select, setProduct, onComplete]
  );

  const handleChange = useCallback(
    field => () => {
      if (field.match(/.+\..+/)) {
        // Matches on "name.name" (ie. nested field aka field state)
        const index = fields.findIndex(p => p.key === field);
        if (index >= 0) {
          fields[index].enabled = !fields[index].enabled;
          setFields([...fields]);
        }
      } else {
        // No '.' in name, look in fieldSets for property (ie. root field aka fieldSets state)
        const index = fieldSets.findIndex(p => p.key === field);

        if (index >= 0) {
          fieldSets[index].enabled = !fieldSets[index].enabled;

          // Also unset/set by default all child fields
          fields.forEach((f, i) => {
            if (f.key.match(new RegExp(`^${field}\.`))) {
              fields[i].enabled = fieldSets[index].enabled;
            }
          });

          setFields([...fields]);
          setFieldSets([...fieldSets]);
        }
      }
    },
    [fieldSets, fields]
  );

  return (
    <>
      <div slot="heading">
        <h3>Duplicate Deal</h3>
      </div>
      {busy && <div>Duplicating...</div>}
      <div
        style={{
          transition: '0.3s ease-in',
          opacity: busy ? '0' : '1.0',
          pointerEvents: busy ? 'none' : 'unset',
        }}
      >
        <RPSListContainer className="vertical">
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <RPSCheckbox
              name="deal-duplicate-hide-empty"
              checked={hideEmpty}
              cbInput={() => setHideEmpty(hideEmpty => !hideEmpty)}
              label="Hide unset/empty fields?"
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
            <RPSCheckbox
              name="deal-duplicate-select"
              checked={select}
              cbInput={() => setSelect(select => !select)}
              label="Select Fields to Duplicate?"
            />
          </div>

          {select && (
            <FieldSets
              fields={fields}
              fieldSets={fieldSets}
              handleChange={handleChange}
            />
          )}

          {isDealLive && (
            <rps-input-label class="error">
              Deal is currently live and cannot be duplicated.
            </rps-input-label>
          )}
          {!isDealLive && (
            <RPSButton cbClick={handleSubmit}>Duplicate</RPSButton>
          )}
        </RPSListContainer>
      </div>
    </>
  );
};

DuplicateDeal.propTypes = {
  onComplete: PropTypes.func,
  deal: PropTypes.any,
  newDeal: PropTypes.func,
};
