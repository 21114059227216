import { uuid } from '@rps/js-utils/utils';
import { iconNames } from '@rps/web-components/build/web-components';

const tileList = [
  {
    text: 'Create New Deal',
    id: uuid(),
    url: '/deals/create',
    action: 'create',
    color: '#0093D0',
    icon: iconNames.dealAdd,
  },
  {
    text: 'Search Deals',
    id: uuid(),
    url: '/deals/search',
    color: '#ba1c8b',
    icon: iconNames.dealSearch,
  },
  // NOTE: keeping these just in case we want them back before DERP
  // {
  //   text: 'Drafts (Unsaved)',
  //   id: uuid(),
  //   url: '/deals/list',
  //   color: '#FFC222',
  //   icon: iconNames.dealEdit,
  // },
  // {
  //   text: 'Upload Deals',
  //   id: uuid(),
  //   url: '/deals/upload',
  //   color: '#B8D72F',
  //   icon: iconNames.dealUpload,
  // },
  {
    text: 'Calculator',
    id: uuid(),
    url: `${process.env.REACT_APP_ODO_BASE_URL}/price_calculator`,
    color: '#E50E62',
    icon: iconNames.calculator,
  },
];

export { tileList };
