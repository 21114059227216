import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import 'regenerator-runtime/runtime';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { ApolloClients } from 'services/apolloClients';
import '@rps/web-components/build/web-components';
import { LogBuffer } from './utils/LogBuffer';
// import { datadogRum } from '@datadog/browser-rum';

import {
  enableLogDumps,
  enableLogTrace,
  // semVer,
  // datadogApplicationId,
  // datadogClientToken,
  // datadogService,
  // datadogEnv,
} from 'config';

/**
 * NOTE: We're disabling datadog RUM for now due to costing.
 * Instead of removing it entirely, I'm commenting it out for now.
 *
 * TODO: Once final decisions have been made we can either remove all DD code or re-enable it.
 */
// if (
//   datadogApplicationId &&
//   datadogClientToken &&
//   datadogService &&
//   datadogEnv
// ) {
//   datadogRum.init({
//     applicationId: datadogApplicationId,
//     clientToken: datadogClientToken,
//     site: 'datadoghq.eu',
//     service: datadogService,
//     env: datadogEnv,
//     version: semVer || undefined,
//     sampleRate: 100,
//     premiumSampleRate: 0,
//     replaySampleRate: 0,
//     trackInteractions: true,
//   });
// }

const env = process.env.NODE_ENV || 'development';

if (enableLogDumps) {
  const logBuffer = new LogBuffer();
  logBuffer.registerLoggers();
  logBuffer.registerKeyboardShortcut();
}

const clients = new ApolloClients();

if (env === 'development') {
  // Supress some noisy react-beautiful-dnd warnings caused by expected state re-mount in Dev.
  // They're naturally suppressed in production.
  window['__react-beautiful-dnd-disable-dev-warnings'] = true;
  console.info('[Buyers Portal] Running in Development Mode');
  const consoleClear = console.clear;
  console.clear = () => {
    window.location.reload();
    consoleClear();
  };
} else {
  console.info('[Buyers Portal] Running in Production Mode');
}

if (!enableLogTrace) {
  console.log('[Buyers Portal] Debug level logs disabled.');
  console.debug = () => {};
}

createRoot(document.getElementById('root')).render(
  <ApolloProvider client={clients.odo}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>
);
