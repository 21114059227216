import type {
  FontFamilyProps,
  FontSizeProps,
  FontStyleProps,
  FontWeightProps,
  LetterSpacingProps,
  LineHeightProps,
  TextAlignProps,
  SpaceProps,
  TextIndentProps,
  ColorProps,
} from '@odo/lib/styled';
import styled, {
  compose,
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  letterSpacing,
  textAlign,
  fontStyle,
  space,
  styledConfig,
  textIndent,
  color,
} from '@odo/lib/styled';

const textStyles = compose(
  fontFamily,
  fontSize,
  fontWeight,
  lineHeight,
  letterSpacing,
  textAlign,
  fontStyle,
  textIndent,
  space,
  color
);

export type TextProps = FontFamilyProps &
  FontSizeProps &
  FontWeightProps &
  LineHeightProps &
  LetterSpacingProps &
  TextAlignProps &
  FontStyleProps &
  TextIndentProps &
  SpaceProps &
  ColorProps;

export const Text = styled.span.withConfig<TextProps>(styledConfig)`
  display: inline-block;
  ${textStyles}
`;

Text.defaultProps = {
  fontFamily: 'body',
  lineHeight: 'body',
  fontSize: 1,
  fontWeight: 500,
};

export type HeadingProps = TextProps & {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

export const Heading = styled.h2.withConfig<HeadingProps>(styledConfig)`
  ${textStyles}
`;

Heading.defaultProps = {
  fontFamily: 'heading',
  lineHeight: 'heading',
  fontSize: [3, 4, 5, 6],
  fontWeight: 600,
};
