import styled from '@odo/lib/styled';

interface DividerProps {
  text?: string;
  rounded?: boolean;
  background?: string;
}

// NOTE: only works on a white background due to the text/after implementation
const Divider = styled.hr<DividerProps>`
  line-height: 11px;
  position: relative;
  outline: 0;
  border: 0;
  color: #d4dce2;
  text-align: center;
  height: ${p => (p.rounded ? '18px' : p.text ? '12px' : '2px')};
  margin-bottom: 0;
  margin-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:before {
    content: '';
    background-color: #d4dce2;
    border: 0;
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }

  &:after {
    content: '${p => p.text || ''}';
    position: relative;
    display: inline-block;
    padding: ${p => (p.rounded ? '2px 1em' : '0 1em')};
    line-height: 12px;
    color: #747e86;
    font-size: 11px;
    background-color: ${p => p.background || 'white'};
    ${p =>
      p.rounded &&
      `
        border: 1px solid #d4dce2;
        border-radius: 30px;
        font-weight: 600;
      `};
  }
`;

export default Divider;
