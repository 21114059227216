/**
 * RP has their own input handler thunk. But it only works with their inputs.
 * The major difference between the inputs is the emitted event.
 * Theirs is `e.detail.value` and ours is `e.target.value` (the browser default).
 *
 * Instead of having to write a new handler for every input of theirs we replace, we can just use this.
 * Also for simplicity we have a single thunk that can be used for all inputs.
 * Instead of creating a new thunk for each screen/model.
 * But the trade-off is that you need to pass the currentDeal and the model as well as the field.
 */
export const handleODOInput =
  ({ currentDeal, model, field }) =>
  e => {
    currentDeal.deal.set(model, field, e.target.value);
    currentDeal.update();
  };
