import { Grid } from '@odo/components/elements/layout';
import { useChangeProduct } from '@odo/contexts/product-editor';
import { useCallback } from 'react';
import { ImageGridItem } from '.';
import { swapItem } from '@odo/utils/array';
import type { EditorProductImage } from '@odo/types/portal';

const ImageGrid = ({
  sortedImages,
  selected,
  toggleSelected,
}: {
  sortedImages: EditorProductImage[];
  selected: EditorProductImage['id'][];
  toggleSelected: (id: EditorProductImage['id']) => (select: boolean) => void;
}) => {
  const change = useChangeProduct();

  // only used on the grid, else we'd hoist this up the root
  const swapPositions = useCallback(
    (indexA: number, indexB: number) => {
      swapItem({
        list: sortedImages,
        from: indexA,
        to: indexB,
        getPosition: image => image.position || 0,
        setPosition: (image, position) => {
          change({
            fieldId: `images.${image.id}.position`,
            label: 'Image Position',
            apply: to => {
              to.images = to.images
                ? to.images.map(i =>
                    i.id === image.id ? { ...i, position } : i
                  )
                : to.images;
            },
          });
        },
      });
    },
    [sortedImages, change]
  );

  return (
    <Grid
      gridTemplateColumns="repeat(auto-fill, minmax(225px, 1fr))"
      gap={[2, 3]}
    >
      {sortedImages.map((image, idx) => (
        <ImageGridItem
          key={image.id}
          image={image}
          isSelected={selected.includes(image.id)}
          toggleSelected={toggleSelected(image.id)}
          canMoveRight={idx < sortedImages.length - 1}
          canMoveLeft={idx > 0}
          moveRight={() => swapPositions(idx, idx + 1)}
          moveLeft={() => swapPositions(idx, idx - 1)}
        />
      ))}
    </Grid>
  );
};

export default ImageGrid;
