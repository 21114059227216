import { Status, type Validator } from '@odo/screens/deal/editor/types';
import { SkuAvailability } from '@odo/types/portal';

export const informationSectionValidators: Validator[] = [
  {
    validate: product => !!product?.name,
    message: 'You must enter a product name',
    status: Status.error,
  },
  {
    validate: product => !!product.activeFromDate && !!product.activeToDate,
    message: 'You must choose a from/to date',
    status: Status.error,
  },
  {
    validate: product => product.skuAvailability === SkuAvailability.taken,
    invertValidation: true,
    message: 'SKU already in use, please select another.',
    status: Status.error,
  },
  {
    validate: product => product.skuAvailability === SkuAvailability.checking,
    invertValidation: true,
    message: "SKU isn't validated yet, please wait.",
    status: Status.warning,
  },
  {
    validate: product =>
      product.skuAvailability === SkuAvailability.validationFailed,
    invertValidation: true,
    message: 'Could not validate SKU, please try again.',
    status: Status.warning,
  },
];

export const descriptionSectionValidators: Validator[] = [];

export const sizeInfoSectionValidators: Validator[] = [];

const validators: Validator[] = [
  ...informationSectionValidators,
  ...descriptionSectionValidators,
  ...sizeInfoSectionValidators,
];

export default validators;
