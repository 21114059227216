import { Grid } from '@odo/components/elements/layout';
import { Heading, Text } from '@odo/components/elements/typography';
import {
  useChangeProduct,
  useCurrentProduct,
} from '@odo/contexts/product-editor';
import { cssColor } from '@odo/utils/css-color';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FaRegImages as IconImages } from 'react-icons/fa';
import uuid from '@odo/utils/uuid';
import type { EditorProductImage } from '@odo/types/portal';
import styled from '@odo/lib/styled';
import { combine } from '@atlaskit/pragmatic-drag-and-drop/combine';
import {
  dropTargetForExternal,
  monitorForExternal,
} from '@atlaskit/pragmatic-drag-and-drop/external/adapter';
import {
  containsFiles,
  getFiles,
} from '@atlaskit/pragmatic-drag-and-drop/external/file';
import { preventUnhandled } from '@atlaskit/pragmatic-drag-and-drop/prevent-unhandled';
import { autoScrollWindowForExternal } from '@atlaskit/pragmatic-drag-and-drop-auto-scroll/external';

const ACCEPTED_FILE_TYPES = [
  'image/gif',
  'image/png',
  'image/jpeg',
  'image/webp',
];

const UnstyledButton = styled.button`
  color: ${cssColor('palette-blue')};
  font: inherit;
  font-weight: 600;
  appearance: none;
  border: none;
  outline: none;
  background: none;
  padding: 0;
  cursor: pointer;
`;

const ImageUpload = () => {
  const currentProduct = useCurrentProduct();
  const change = useChangeProduct();

  const dropTargetRef = useRef<HTMLDivElement | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [uploadDragActive, setUploadDragActive] = useState(false);

  const highestPosition = useMemo(() => {
    let highest = 0;
    (currentProduct.images || []).forEach(({ position }) => {
      if (position && position > highest) {
        highest = position;
      }
    });
    return highest;
  }, [currentProduct.images]);

  const onFileUpload = useCallback(
    (files: File[]) => {
      let nextPosition = highestPosition;
      for (const file of files) {
        const newId = uuid();
        const newImage: EditorProductImage = {
          id: newId,
          file,
          position: ++nextPosition,
          // NOTE: we could put the file name in here as the label, but I feel that'll be an inconvenience
          // label: file.name,
        };

        change({
          fieldId: `images.upload.${newId}`,
          label: `Image upload: "${file.name}"`,
          apply: to => {
            to.images = to.images ? [...to.images, newImage] : [newImage];
          },
        });
      }
    },
    [change, highestPosition]
  );

  /**
   * The built-in window auto-scrolling works, but it requires too much precision from users.
   * Using this custom function gives much more user friendly results by scrolling when you get close to the edge.
   */
  useEffect(() => {
    return autoScrollWindowForExternal();
  }, []);

  /**
   * Monitor drag and drop events for uploading images.
   */
  useEffect(() => {
    if (!dropTargetRef.current) return;

    return combine(
      dropTargetForExternal({
        element: dropTargetRef.current,
        canDrop: containsFiles,
        onDragEnter: () => setUploadDragActive(true),
        onDragLeave: () => setUploadDragActive(false),
        onDrop: ({ source }) => {
          const files = getFiles({ source });
          if (files) onFileUpload(files);
          setUploadDragActive(false);
        },
      }),
      monitorForExternal({
        canMonitor: containsFiles,
        onDragStart: () => preventUnhandled.start(),
        onDrop: () => {
          setUploadDragActive(false);
          preventUnhandled.stop();
        },
      })
    );
  }, [onFileUpload]);

  return (
    <Grid gap={2}>
      <Grid
        ref={dropTargetRef}
        p="2px"
        borderRadius="12px"
        bg={
          uploadDragActive ? cssColor('palette-blue-muted') : cssColor('white')
        }
        style={{ transition: 'background 75ms ease' }}
      >
        <Grid
          justifyContent="center"
          justifyItems="center"
          px={3}
          py={4}
          borderRadius="12px"
          borderWidth="2px"
          borderStyle="dashed"
          borderColor={
            uploadDragActive ? cssColor('palette-blue') : cssColor('grey-light')
          }
          style={{ transition: 'border-color 75ms ease' }}
        >
          <input
            ref={fileInputRef}
            type="file"
            style={{ display: 'none' }}
            multiple
            accept={ACCEPTED_FILE_TYPES.join(',')}
            onChange={e => {
              const files = e.target.files;
              if (files) onFileUpload(Array.from(files));
            }}
          />

          <IconImages size={60} />

          <Heading fontSize={2}>
            Drag images here or{' '}
            <UnstyledButton onClick={() => fileInputRef.current?.click()}>
              click to upload.
            </UnstyledButton>
          </Heading>
        </Grid>
      </Grid>

      <Text
        color={cssColor('grey')}
        textAlign={['left', 'right']}
        fontStyle="italic"
      >
        Accepted file types: {ACCEPTED_FILE_TYPES.join(', ')}
      </Text>
    </Grid>
  );
};

export default ImageUpload;
