export const calculatorConfiguration = {
  vat_rate: 15,

  vendors: [
    'eft',
    'visamastercard',
    'ebucks',
    'discoverymiles',
    'snapscan',
    'ucount',
    'zapper',
  ],

  vendorConfigurations: {
    eft: {
      percentage: 0,
      surcharge: 0,
      weightedAverage: 0.17,
    },
    visamastercard: {
      percentage: 0.0211,
      surcharge: 1.8,
      weightedAverage: 0.61,
    },
    ebucks: {
      percentage: 0.04,
      surcharge: 0,
      weightedAverage: 0.07,
    },
    discoverymiles: {
      percentage: 0.05,
      surcharge: 0,
      weightedAverage: 0.05,
    },
    snapscan: {
      percentage: 0.03,
      surcharge: 0,
      weightedAverage: 0.08,
    },
    ucount: {
      percentage: 0.0354,
      surcharge: 1.8,
      weightedAverage: 0.01,
    },
    zapper: {
      percentage: 0.029,
      surcharge: 0,
      weightedAverage: 0.01,
    },
  },
};
