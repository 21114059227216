import { gql } from '@apollo/client';

export const LOGIN = gql`
  mutation LoginUser($username: String!, $password: String!) {
    loginUser(username: $username, password: $password) {
      username
      token
      refreshToken
      refreshDuration
      id
    }
  }
`;

export const ODO_LOGIN = gql`
  mutation Login($username: String!, $password: String!) {
    login(user: { username: $username, password: $password }) {
      ... on User {
        firstname
        lastname
        username
        email
      }
    }
  }
`;
