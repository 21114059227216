/**
 * NOTE: this is a typescript (and editable) version of /src/@rps/js-utils/src/caching/dataSource.js
 *
 * Initially this is being added to allow support for localStorage caching,
 * without having to rewrite the entire RP DataSourceContext system.
 *
 * But if we end up wanting a proper dedicated data source,
 * then I recommend we implement a new one from scratch.
 */

enum StorageType {
  localStorage = 'localStorage',
}

interface CacheType {
  configure: (storageType: StorageType) => void;
  get: (key: string) => Promise<unknown>;
  set: (key: string, value: unknown) => Promise<void>;
}

/**
 * For ease of use I'm going to build this to ONLY work with localStorage.
 */
export class Cache implements CacheType {
  storageType: StorageType | undefined;

  /**
   * NOTE: although I've set up the class to accept a storage type when configuring,
   * this property is a bit of a pain to make dynamic right now and I don't feel it's worth the effort.
   */
  layer = window.localStorage;

  configure(storageType: StorageType) {
    this.storageType = storageType;

    switch (storageType) {
      case StorageType.localStorage:
        this.layer = window.localStorage;
        break;
      default:
        throw new Error(
          'ODOCache::configure received invalid storage type: ' +
            JSON.stringify(storageType)
        );
    }

    return this;
  }

  async get(key: string) {
    let result: unknown | undefined;

    switch (this.storageType) {
      case StorageType.localStorage:
        result = this.layer.getItem(key);
        break;
    }

    return result;
  }

  async set(key: string, value: unknown) {
    switch (this.storageType) {
      case StorageType.localStorage:
        if (typeof value === 'string') {
          this.layer.setItem(key, value);
        }
        break;
    }

    return;
  }
}

/**
 * Class that provides a pubsub capable source of data (i.e.: Observer pattern).
 * Caching compatible, and provides scheduling functionality.
 *
 * @class DataSource
 * @property {Object} state - state object
 */
export class DataSource {
  _listeners: ((args: unknown) => void)[] = [];
  _cacheKey: string | undefined;
  _cache: CacheType | undefined;

  state: Record<string, unknown> = {};

  /**
   * Construct an instance of {@link DataSource}.
   *
   * Note that you'll want to ensure that instances of your data source should be initialized _once_ across
   * the scope you're intending to use it in (i.e.: Provide it via singleton, React Context, etc).
   *
   * @param {Object} initialState - Optional default state
   * @param {Object} caching - Optional caching provider options
   * @param {String} caching.key - Key name to use for cached data (Default: datasource-timestamp)
   * @param {String} caching.provider - Provider type to use (Default: memory). See {@link Cache} for details
   */
  constructor(
    initialState: Record<string, unknown> = {},
    caching = {
      key: `data-source-${Date.now}`,
      provider: StorageType.localStorage,
    }
  ) {
    // Array of listener callbacks
    this._listeners = [];
    this.state = initialState;

    if (caching.key && caching.provider) {
      this._cacheKey = caching.key;
      this._cache = new Cache();
      this._cache.configure(caching.provider);

      // Initialize with cached data if found
      const getCachedData = async () => {
        const cachedData = this._cache && (await this._cache.get(caching.key));

        console.debug('DataSource.constructor', 'getCachedData', cachedData);
        if (cachedData) {
          this.state =
            typeof cachedData === 'string'
              ? JSON.parse(cachedData)
              : initialState;
        }
      };

      getCachedData();
    }
  }

  // JSDoc definitions for callbacks:
  /**
   * @callback scheduleCallback
   * @param {DataSource} dataSource - returns the instance of DataSource the scheduler was called from.
   */

  /**
   * Callback to be provided to the subscribe method.
   * @callback subscribeCallback
   * @param {Object} newState - the updated state object
   */

  /**
   * Subscribe to changes from this DataSource instance.
   *
   * @param {subscribeCallback} callback - callback to call when the state changes
   */
  subscribe(callback) {
    if (!this._listeners.find(listener => listener === callback)) {
      this._listeners.push(callback);
      callback(this.state);
    }
  }

  /**
   * Removes callback from the array of subscribed listeners.
   * Please ensure that the callback ref is the same as the one passed in to subscribe.
   *
   * @param {subscribeCallback} callback - callback to remove from listeners
   */
  unsubscribe(callback) {
    this._listeners = this._listeners.filter(listener => listener !== callback);
  }

  /**
   * Schedules a callback to fire after a specified number of milliseconds. Can be used to
   * perform re-fetches of asynchronous data (i.e.: To refresh tokens or a feed).
   *
   * @param {scheduleCallback} callback - Callback that fires when the scheduler times out
   * @param {number} timeInMilliseconds - Time in milliseconds to wait before calling the provided callback
   *
   * @example
   * const refreshUserToken = (source) => {
   *  const updatedData = await fetchSomeData();
   *  source.publish(updatedData);
   *
   * // If we need this to keep happening every few seconds:
   *  if(source.state.logged_in) {
   *      source.schedule(refreshUserToken, 5000)
   *  }
   * }
   *
   * // Can either schedule the first run of this callback
   * dataSource.schedule(refreshUserToken, 5000);
   *
   * // Or just run it immediately:
   * refreshUserToken(dataSource);
   *
   */
  schedule(callback, timeInMilliseconds) {
    setTimeout(async () => {
      await callback(this);
    }, timeInMilliseconds);
  }

  /**
   * Publish a state update, notifying all listeners and updating the cache if necessary.
   *
   * @param {Object} stateUpdate - Updated state object
   * @param {Boolean} mergeState - If true, merges the incoming state with existing state, else overwrites it entirely. (Default: true)
   */
  publish(stateUpdate, mergeState = true) {
    if (mergeState) {
      this.state = { ...this.state, ...stateUpdate };
    } else {
      this.state = stateUpdate;
    }

    this._updateCache();
    this._notifyListeners();
  }

  // Private Methods

  /**
   *  Notify all listeners of potential changes to the state.
   *  @private
   */
  _notifyListeners() {
    console.debug(
      'DataSource._notifyListeners:',
      'notifying listeners',
      this.state
    );
    this._listeners.forEach(listener => {
      if (listener) {
        listener(this.state);
      }
    });
  }

  /**
   * Update cache if cache is being used.
   * @private
   */
  _updateCache() {
    if (this._cache && this._cacheKey) {
      console.debug('DataSource._updateCache:', 'Updating Cache', this.state);
      this._cache.set(this._cacheKey, JSON.stringify(this.state));
    }
  }
}
