/**
 * Port from PHP of the ODO Price Calculator
 */

import { calculatorConfiguration } from './calculatorConfiguration';

export function calculateProfit({
  price = 0,
  profit = 0,
  retail = 0,
  soh = 0,
  cost = 0,
  rebate = 0,
  admin = 17.5,
  surcharge = 0,
  vat = 0,
  commission = 0,
  calc_price = 0,
  insurance = null,
  local = 1,
}) {
  let result = 0;
  let exvat = 0;

  const VAT_RATE = calculatorConfiguration.vat_rate;
  const VendorConfigurations = calculatorConfiguration.vendorConfigurations;
  const Vendors = calculatorConfiguration.vendors;

  // Calculate Price
  if (!commission) {
    if (calc_price) {
      price =
        (profit +
          cost * (1 - rebate / 100) +
          admin -
          surcharge / (1 + VAT_RATE / 100) +
          1.8 / 5) /
        (1 / (1 + (VAT_RATE / 100) * vat) -
          (1 / 5) * (0.05 + 0.04 + 0.0211 + 0.03));
    }
    result =
      price / (1 + (VAT_RATE / 100) * vat) +
      surcharge / (1 + VAT_RATE / 100) -
      cost * (1 - rebate / 100) -
      admin;
  } else {
    if (local) {
      exvat = price / (1 + VAT_RATE / 100);
      result = exvat * (commission / 100);
      cost = (exvat * (1 - commission / 100)).toFixed(2);
    } else {
      result = price * (commission / 100);
      cost = (price * (1 - commission / 100)).toFixed(2);
    }
  }

  const vendorPriceMap = {};
  for (const vendor of Vendors) {
    const vendorConfig = VendorConfigurations[vendor];

    vendorPriceMap[vendor] =
      (price / (1 + (VAT_RATE / 100) * vat)) * vendorConfig.percentage +
      vendorConfig.surcharge;
  }

  let average = 0;
  let weightedAverage = 0;
  let margins = {};
  let profits = {};
  for (const vendor of Vendors) {
    const vendorPrice = vendorPriceMap[vendor];
    average += result - vendorPrice;
    weightedAverage +=
      (result - vendorPrice) * VendorConfigurations[vendor].weightedAverage;

    if (price > 0) {
      margins[vendor] = (((result - vendorPrice) / price) * 100).toFixed(2);

      profits[vendor] = (result - vendorPrice).toFixed(2);
      profits['avg'] = (average / 7).toFixed(2);
      profits['wavg'] = weightedAverage.toFixed(2);
      margins['avg'] = ((average / 7 / price) * 100).toFixed(2);
      margins['wavg'] = ((weightedAverage / price) * 100).toFixed(2);
      //let margin = (result / price * 100).toFixed(2);

      let savings_percent = 0;
      if (retail > 0) {
        savings_percent = (1 - price / retail) * 100;
      }

      savings_percent = Math.round(savings_percent);
      let savings_price = (retail - price).toFixed(2);

      margins['csave'] = savings_percent;
      profits['csave'] = savings_price;

      profits['potential'] = (soh * profits['wavg']).toFixed(2);
    }
  }

  insurance = insurance ? (cost * -0.016).toFixed(2) : 0;
  price = price.toFixed(2);
  admin = admin.toFixed(2);
  surcharge = surcharge.toFixed(2);

  return {
    margins,
    profits,
    insurance,
    cost,
    price,
    admin,
    surcharge,
    average: average / 7,
    weightedAverage,
    result,
    exvat,
  };
}

export const createResultSet = rawResults => {
  const percent = input => `(${input}%)`;
  return {
    profit: rawResults.profits.potential || 0,
    weighted: (+rawResults.weightedAverage).toFixed(2),
    weighted_margin: percent(rawResults.margins.wavg || 0),
    average: (+rawResults.average).toFixed(2),
    average_margin: percent(rawResults.margins.avg || 0),
    EFT: rawResults.profits.eft || 0,
    EFT_margin: percent(rawResults.margins.eft || 0),
    CreditCard: rawResults.profits.visamastercard || 0,
    CreditCard_margin: percent(rawResults.margins.visamastercard || 0),
    eBucks: rawResults.profits.ebucks || 0,
    eBucks_margin: percent(rawResults.margins.ebucks || 0),
    discovery: rawResults.profits.discoverymiles || 0,
    discovery_margin: percent(rawResults.margins.discoverymiles || 0),
    snapScan: rawResults.profits.snapscan || 0,
    snapScan_margin: percent(rawResults.margins.snapscan || 0),
    uCount: rawResults.profits.ucount || 0,
    uCount_margin: percent(rawResults.margins.ucount || 0),
    zapper: rawResults.profits.zapper || 0,
    zapper_margin: percent(rawResults.margins.zapper || 0),
    savings: rawResults.profits.csave || 0,
    savings_margin: percent(rawResults.margins.csave || 0),
  };
};
