/**
 * Represents the current in-memory deal being edited
 */

import { useContext } from 'react';
import { CurrentDealContext } from '../contexts/CurrentDealContext';
/**
 * @hook
 * Hook that provides the current editor state, including the currently focused deal,
 * a list of all deals and methods to update them.
 *
 * The currently edited deal is always the first in the list of deals (i.e.: dealList[0])
 * When switching to a new deal, the deal to be edited is popped out and shifted to the front
 * of the array.
 *
 * This lets us preserve a "most recently edited" order without needing to deal
 * with dates.
 *
 * Uses {@link Cache} to store deals in progress.
 *
 * @return {CurrentDealContextInstance}
 */
export const useCurrentDealSource = () => {
  /** @type {ContextStateInstance} */
  const currentDealContext = useContext(CurrentDealContext);

  return currentDealContext;
};

/**
 * @typedef {Object} CurrentDealContextInstance
 * @property {Deal} deal - Current deal being edited
 * @property {Deal[]} dealList
 * @property {removeDealFn} removeDeal
 * @property {Function} update - Save current deal to cache and re-render components as needed.
 *	@property {createInputHandler} createInputHandler
 *
 * @callback createInputHandler
 *	@param {String} modelKey - Model key name (i.e.: "buyerAndSupplier") - see Deal.MODELS
 * @returns {InputHandler}
 *
 * @callback InputHandler
 * @param {String} fieldKey - Field name in targeted model (i.e.: "isDeliveredBySupplier") - see Deal.<model>.FIELDS
 * @returns {InputEventHandler}
 *
 * @callback InputEventHandler
 * @param {Event} event - Event passed to input handler
 */
